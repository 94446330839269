import React, { Component } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Stack,
  HStack,
  Spacer,
  Flex,
  Icon,
  Text
} from "@chakra-ui/react";
import {BiChevronDown} from "react-icons/bi"
import SelectButton from './selectButton';

export default class NestedDropdown extends Component{
  constructor(props){
    super(props);
    this.state = {
      selected : ""
    };
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(filter){

    this.setState({
      selected : filter.name,
      selectedKey : filter.id
    });
    this.props.onUpdate(filter.id);
  }

  componentDidUpdate(prevProps){
    if(this.props.isFilterSelected != prevProps.isFilterSelected && !this.props.isFilterSelected){
      this.setState({ selected : "", })
      //this.props.onUpdate(false);
    }
  }

  render(){
    const { selected } = this.state;
    const { data, title } = this.props;

    if(data.length == 1 && selected == "") this.handleItemClick(data[0]);
    return (
      <>
      {(data != []) ? (
        <>
        <Text mb = {"0px"}>Select a {title}</Text>
        <Menu>
          <MenuButton
            fontSize={"md"} 
            px = {"5px"} 
            py = {"5px"} 
            backgroundColor = {"gray.200"}
            style = {{ width : "100%",}}
          > 
            {selected || "Select a filter"} 
          </MenuButton>
          <MenuList>
              <Stack alignItems="flex-start">
                {data.map((filter) => (
                  <MenuItem onClick={() => this.handleItemClick(filter)}>
                   {filter.name}
                  </MenuItem>
                ))}
              </Stack>
          </MenuList>
        </Menu>
        </>
    ) : ( 
      null
    )}
      </>
    );
  }
}
