import React, { Component } from 'react';
import {
  Stack,
  Flex,
  Box,
  Heading,
  Image,
  Center,
  Hide
} from '@chakra-ui/react';
import './demo.css'
import LineDemo from '../lineDemo/lineDemo'
import Fade from '../productFeatures/fade'
import { CareDashboard, GrowthDashboard, SalvationDashboard } from './dashboards';

export default class Banner extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      isModalOpen: false
    };
  }

  componentDidMount(){
    this.setState({
      isMounted : true
    });
  }


  render(){
    return (
      <Box 
        pos="relative"
      >
        <Box
          pos="relative"
          background="linear-gradient(to left, blue, #3399ff)"
          w="100%"
          h="800px"
          overflow="hidden"
          style={{
            clipPath: 'polygon(0% 0%, 100% 20%, 100% 100%, 0 100%)',
            WebkitClipPath: 'polygon(0% 0%, 100% 20%, 100% 100%, 0 80%)',
          }}
        >
          <Hide below='md'>
            <div className="circle xxlarge shade1"></div>
            <div className="circle xlarge shade2"></div>
            <div className="circle large shade3"></div>
            <div className="circle medium shade4"></div>
            <div className="circle small shade5"></div>
          </Hide>
        </Box>
        <Box zIndex={10} pos="absolute" top="0" left="0" w="100%" h="100%" backgroundColor="">
        <Flex w="100%" h="100%" alignItems="center">
        <Fade>
          <CareDashboard />
          <GrowthDashboard />
          <SalvationDashboard />
        </Fade>
        </Flex>
        </Box>
      </Box>
    );
  } 
}