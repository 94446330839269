import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Handle the error (e.g., log it, send it to a server, etc.)
    console.error(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Render a custom error message or redirect to an error page
      return <div>Something went wrong on this route.</div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
