import React, { Component } from 'react';
import {
  Stack,
  Show,
  Flex,
  Box,
  Heading,
  Image,
  Center
} from '@chakra-ui/react';
import '../demo.css'
import LineDemo from '../../lineDemo/lineDemo'

export class CareDashboard extends Component {

  render() {
    return (
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Box>
          <Box maxH = {'200px'}>
            <Image
              src="./screenshot-care.png"
              objectFit='contain'
              height='100%'
              borderRadius={'15px'}
              boxShadow="-20px 30px 20px rgba(0, 0, 0, 0.24)"
            />
          </Box>
          <Box >
            <LineDemo data={[{ age: 20 }]} />
          </Box>
        </Box>
        <Box height={'100%'}>
          <Center h='100%'>
            <Heading color="white">Visualize your church like never before!</Heading>
          </Center>
        </Box>
      </Stack>
    );
  }
}