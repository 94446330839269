import { Box, Button, IconButton } from "@chakra-ui/react";
import { BiFilterAlt, BiPlusCircle } from "react-icons/bi";
import React, { Component } from 'react';

export default class NewFilterButton extends Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <IconButton 
        variant={'link'}
        color = {"#666"}
        width = {'50px'}
        fontSize = {'50px'}
        icon= {<BiPlusCircle boxSize={6}/>}
        onClick = {this.props.onClick}
      />
    );
  }
}