import React, { Component } from 'react';
import {
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Center
} from '@chakra-ui/react';
import './test.css'

export default class Banner extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      isModalOpen: false
    };
  }

  componentDidMount(){
    this.setState({
      isMounted : true
    });
  }


  render(){
    return (
      <Box mb = '-150px' style = {{minHeight: '900px', width: '100vw', position: 'relative', overflow: 'hidden'}}>
        <Center zIndex = {9} pos='absolute' w= '100%' h = '100%'>
        <Center mt = '-150px' w = '80%' h = '70%' style = {{    background: 'rgba(255, 255, 255, 0.4)',
          borderRadius: '16px',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(5px)',
          WebkitBackdropFilter: 'blur(5px)',
          border: '1px solid rgba(255, 255, 255, 0.3)',}}
          textAlign="center">
          
          <Heading size={{ base: 'md', md: '2xl' }}>Welcome To Flock Analytics!</Heading>
        </Center>
        </Center>
      <Box>
                  <ul className="circles">
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                          <li style = {{background : 'brand.200'}}></li>
                  </ul>
          </Box >
      </Box>
    );
  } 
}