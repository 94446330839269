export default {
  "hook" : "Flexibile pricing that fits your needs",
  "hook_description" : "Start with 7-day free trial. Cancel at anytime.",
  "slider_description" : "Flock Analytics pricing is determined by the number of accounts in your church management software. Please select the number of accounts above to view your estimated pricing.",
  "feature_bullets" : [
    "Unlimited user accounts",
    "Monthly data snapshots",
    "Cloud servers for faster rendering",
    "Interactive map of all members",
    "Full access to prebuilt Ministry Insight Dashboards"
  ]
}