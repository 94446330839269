import React, { Component } from 'react';
import Counter from '../counter';
import { Box, Fade, Center, HStack, Button, Card, List, ListIcon, ListItem, Stack, Text, Heading, Spacer, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody} from '@chakra-ui/react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BiInfoCircle } from "react-icons/bi";
import { Navigate } from 'react-router-dom';
import { LargeLogo } from '../assets/largeLogo';
import Footer from '../components/footer';
import { bouncy } from 'ldrs'
import Auth from '../components/auth';
import NavBar from '../navBar'
bouncy.register()

const verses = ["Thank you for your patience as we work on establishing the connection. Please hold tight, as this process may take up to a few minutes to complete.", "Rejoice in hope, be patient in tribulation, be constant in prayer.", "May the God of hope fill you with all joy and peace as you trust in him, so that you may overflow with hope by the power of the Holy Spirit.", "The steadfast love of the Lord never ceases; his mercies never come to an end; they are new every morning; great is your faithfulness.", "Jesus looked at them and said, 'With man it is impossible, but not with God. For all things are possible with God.'", "Taste and see that the Lord is good; blessed is the one who takes refuge in him.", "Trust in the Lord with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.", ];

const references = ["", "Romans 12:12", "Romans 15:13", "Lamentations 3:22-23", "Mark 10:27", "Psalm 34:8", "Proverbs 3:5-6"];


export default class Registration extends Component {
  constructor(props){
    super(props);
    this.state = {
      isMounted : false,
      isAuthenticated : false,
      verse : 0,
      showText : true,
      monthly : true,
      price : 0,
      individuals : 0,
      groups : 0,
      loading : false
    };
  }

  async componentDidMount(){
    setTimeout(this.loadingText, 10000);
    let res = await fetch('/api/church/pricing',{
      credentials: "include", 
      headers: {
        "Content-Type": "application/json",
      },
    })
    if(!res.ok){
      this.setState({
        isMounted : true,
        isAuthenticated : true
      });
    } else {
      // This is for exemptions!
      if(res.status === 201) {
        return window.location.href = '/review-registration';
      }

      res = await res.json();
      this.setState({
        isMounted : true,
        isAuthenticated : true,
        individuals : res.individuals,
        groups : res.groups,
        price : res.price
      });
    }
  }
  loadingText = () =>{
    this.setState({showText : false}, () => {setTimeout(() =>{
      const i = (this.state.verse + 1) % verses.length;
      this.setState({
        verse : i,
        showText : true
      }, () => setTimeout(this.loadingText, 8000))
    }, 1500)});
  }

  createCheckout = async (e) =>{
    e.preventDefault();
    try{
      this.setState({loading : true})
      let res = await fetch('/api/payment/checkout',
        {
          method : "GET",
          credentials: "include", 
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if(res.ok){
        res = await res.json();
        window.location.href = res.url;
      }
    } catch(err){
      this.setState({loading : false})
    }
  }

  render(){
    const { monthly, isAuthenticated, isMounted, individuals, groups, price, verse, reference, showText } = this.state;
    return(
      <Auth allowedStatus = {[407]} blockedStatus = {{'/login' : [0], '/registration' : [406]}} defaultRedirect = {'/home'}>
      <>
      <NavBar />
      <Box minH = {"100vh"}>
      {isMounted ? (
        isAuthenticated ? (
        <>
        <Center mt = "30">
          <LargeLogo height={200} />
        </Center>
        <Center px = {"8vw"}>
          <Center>
            <Stack align={"center"}>
              
            <Heading textAlign="center">Your Church has...</Heading>
              <HStack>
                <Counter label = "Individuals" number={individuals.toString()} duration={"1"}/>
                <Counter label = "Groups" number={groups.toString()} duration={"1"}/>
              </HStack>

            </Stack>
          </Center>
          <Spacer />
          <Stack align={"center"} pt={"50px"}>
            <Card width = "35vw" align={"center"} pb = {"15px"}>
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}
              
            >
              <Text
                textTransform="uppercase"
                bg={'brand.200'}
                px={3}
                py={1}
                color={'white'}
                fontSize="sm"
                fontWeight="600"
                rounded="xl"
              >
                1 Week Free Trial
              </Text>
            </Box>
            <HStack flexWrap={"wrap"} justify="center" mt = {8}>
                <>
                  <Heading>${parseFloat(price).toFixed(2)}</Heading>
                  <Text>Monthly</Text>
                </>
              <Popover>
                <PopoverTrigger>
                <IconButton
                  aria-label="Info"
                  color = 'gray.500'
                  icon={<BiInfoCircle size = "sm" color='gray.500'/>}
                  variant="ghost"
                  size="sm"
                  _hover={{ color: 'brand.500' }}
                />
                </PopoverTrigger>
                <PopoverContent maxW="xl">  
                  <PopoverBody>
                    <b>Pricing</b>($50 monthly minimum)<br />
                    $.10 <b>x</b> per active accounts 1-1000<br/>
                    $.05 <b>x</b> per active accounts 1001-3000<br/>
                    $.02½ <b>x</b> per active accounts 3001+<br/>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
            <List spacing={3} px = {"15px"}>
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Unlimited user accounts
              </ListItem>
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Cloud servers for faster rendering
              </ListItem>
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Full access to prebuilt Ministry Insight Dashboards
              </ListItem>
              {/* You can also use custom icons from react-icons */}
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Track historical data to see growth over time
              </ListItem>
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Interactive map of all members
              </ListItem>
            </List>
            <Button 
              p = "30" 
              onClick={this.createCheckout} 
              isLoading = {this.state.loading}
            >
              <Heading fontSize={"2xl"}>Start Trial</Heading>
            </Button>
            </Card>
          </Stack>
         </Center>
         </>
        ) : (
        <Navigate to = '/register'/>
        )
      ) : (
        <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Stack justifyContent="center" align="center" minH = {"100%"} width ="80%">
            <Fade in={showText}>
              <Box minH = {"40vh"}>
                <Heading align = "center" fontSize={{sm: "5xl", md : "5xl", lg : "5xl" }} color = {"brand.500"}>{verses[verse]}</Heading>
                <Text align = "center" fontSize = {{ base: '4xl', md: '4xl', lg: '4xl' }} color = {"rgb(135, 222, 205)"}>{references[verse]}</Text>
              </Box>
            </Fade>
            <Box pt = "50px">
            <l-bouncy
              size="150"
              speed="1.2" 
              color="blue" 
            ></l-bouncy>
            </Box>
          </Stack>
          </div>
      )}
      </Box>
      <Footer />
      </>
      </Auth>
    );
  }
}