import React, { Component } from 'react';
import { BiArrowBack } from "react-icons/bi";
import {IconButton, 
        Box, 
        Center, 
        Spinner,
        Heading, 
        Button,
        FormControl,
        FormLabel,
        Input,
        FormHelperText,
        AspectRatio,
        Text,
        Stack,
        Card,
        OrderedList, 
        ListItem,
        Alert,
        AlertIcon,
        AlertTitle,
        AlertDescription,
        HStack} from '@chakra-ui/react';

export default class CcbRegistration extends Component {
  constructor(props){
    super(props);
    this.state = {
      apiUsername : "",
      apiPassword : "",
      apiUrl : "",
      apiUsernameValid : true,
      apiPasswordValid : true,
      apiUrlValid : true,
      error : false,
      errorMessage : "",
    };
  }
  register = async (e) => {
    this.setState({error : false});
    const {apiUsername, apiUrl, apiPassword, apiUsernameValid, apiPasswordValid, apiUrlValid} = this.state;
    e.preventDefault();

    if(apiUsername.length == 0) this.setState({
      apiUsernameValid :false, 
      error : true,
      errorMessage : "Please Enter an Username"
    });
    if(apiPassword.length == 0) this.setState({
      apiPasswordValid : false, 
      error : true,
      errorMessage : "Please Enter a Password"
    });
    if(apiUrl == 0) this.setState({
      apiUrlValid : false, 
      error: true,
      errorMessage : "Please Enter a URL"
    });
    if(apiUrl.length != 0 && apiPassword.length != 0 && apiUsername.length != 0)  {
      let errorMessage = await this.registerData({
        api_type : "ccb",
        ccb_api_username : apiUsername,
        ccb_api_password : apiPassword,
        ccb_api_url : apiUrl
      });
      if(errorMessage != undefined){
        this.setState({error : true, errorMessage : errorMessage});
      }
    }
  }

  async registerData(data) {
    this.setState({loading : true});
    try {
      const response = await fetch(`/api/church/test-n-set`, {
        credentials: "include", 
        method: "POST",   
        headers: {
          'Content-Type': 'application/json'
        }, 
        body: JSON.stringify(data)
      });
      if(response.ok){
        window.location.href = '/review-registration';
      } else if(response.status == 401){
        this.setState({loading : false});
        return "Invalid Credentials please check your username, password, and URL";
      } else {
        this.setState({loading : false});
        return "Something went wrong please try again"
      }
    } catch(err) {
      console.log(err);
    }
  }

  render(){
    const {apiUsername, apiUrl, apiPassword, apiUsernameValid, apiPasswordValid, apiUrlValid, error, errorMessage, loading} = this.state;
    return(
      <Box textAlign={"center"}>
        <Heading>Connect to Church Community Builder</Heading> 
      <HStack pr = "50" pt = "100" align="flex-start">
        <Stack maxW = "65vw" pr = {"100"} pl = {"25"} textAlign={"left"} pos={'relative'}>
        <IconButton
          size="xl" // Increase the size to make it bigger
          icon={<BiArrowBack />}
          pos="absolute"
          top="-50px" // Adjust the top position as needed
          left="25px" // Adjust the left position as needed
          onClick={this.props.removeApi}
          bg="white" // Set background color to white
          boxShadow="xl" // Add shadow for depth
        />
          <Heading fontSize = {"5xl"}  color = {"#666"}>Create New API User on CCB</Heading>
          <Text fontSize={"xl"}>You must first create a new API user on CCB to use for integration with Flock Analytics.</Text>

          <OrderedList fontSize={"xl"} pb={"50px"}>
          <ListItem>Open a new browser tab and navigate to CCB (Church Community Builder).</ListItem>
          <ListItem>Log in to your CCB account. The account must have adminstrative privileges.</ListItem>
          <ListItem>In the lower left portion of the screen, select the Settings button gear icon and choose API from the drop-down menu.</ListItem>
          <ListItem> Select Add a new API user from the following screen. </ListItem>
          <ListItem>
            Complete the information for the new API user. <br />
            API User Information
            <OrderedList>
              <ListItem>
                <b><span style={{color : "blue"}} >Name:</span> </b>You may enter any name you like, this field is for your management purposes only. Please ensure that you use a strong password!
              </ListItem>
              <ListItem>
                <b><span style={{color : "blue"}} >Username:</span> </b>This will be the username used to integrate with Flock Analytics.
              </ListItem>
              <ListItem>
                <b>Password: </b>This will be the password used to integrate with Flock Analytics. Make sure to use a strong password!
              </ListItem>
            </OrderedList>
            Primary Organization Contact Information<br />
            This is to help you manage your APIs. 
            <OrderedList>
              <ListItem>
                <b>Organization Name: </b>Flock Analytics
              </ListItem>
              <ListItem>
                <b>Contact Name: </b> Cody Blankenship
              </ListItem>
              <ListItem>
                <b>Contact Phone: </b> 865-696-9837
              </ListItem>
              <ListItem>
                <b>Contact Email: </b> support@flockanalytics.com
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem>
            Select the Services tab and check <b>select all</b>. This will allow us to smoothly roll out the newest updates.
          </ListItem>
          <ListItem> Save the new API user.</ListItem>
          <ListItem>Select the Summary screen in the API section to find and copy the <span style={{color : "blue"}} >CCB API URL</span>.</ListItem>
          <ListItem>
            Please input the following information into the form on this page. The items to be entered are highlighted in blue for your convenience.
            <OrderedList>
              <ListItem><span style={{color : "blue"}} >Username</span></ListItem>
              <ListItem><span style={{color : "blue"}} >Password</span></ListItem>
              <ListItem><span style={{color : "blue"}} >CCB API URL</span></ListItem>
            </OrderedList>
          </ListItem>

          </OrderedList>
        </Stack>
        {loading ? (
          <Card p = {"25"}
            as="div"
            css={{
              position: "-webkit-sticky", /* Safari */
              position: "sticky",
              top: 200,
            }}
            alignItems = {"center"}
            justifyContent = {'center'}
          > 
            <Text fontSize={'xl'}>Hold on while we connect!</Text>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='brand.500'
              size='xl'
            />
          </Card>
        ) : (
          <Card p = {"25"}
            as="div"
            css={{
              position: "-webkit-sticky", /* Safari */
              position: "sticky",
              top: 100,
            }}
          >
            <form onSubmit={this.register}>
              <FormControl mt={"10px"}>
                <FormLabel>CCB API Username</FormLabel>
                <Input 
                  value = {apiUsername} 
                  onChange = {(e) => this.setState({apiUsername : e.target.value, apiUsernameValid : true})}
                  isInvalid = {!apiUsernameValid}
                  errorBorderColor='crimson'
                />
              </FormControl>
              <FormControl>
                <FormLabel mt={"20px"}>CCB API Password</FormLabel>
                <Input 
                  value = {apiPassword} 
                  onChange = {(e) => this.setState({apiPassword : e.target.value, apiPasswordValid : true})}
                  isInvalid = {!apiPasswordValid}
                />
              </FormControl>
              <FormControl>
                <FormLabel mt={"20px"}>CCB API URL</FormLabel>
                <Input 
                  value = {apiUrl} 
                  onChange = {(e) => this.setState({apiUrl : e.target.value, apiUrlValid : true})}
                  isInvalid = {!apiUrlValid}
                />
                <FormHelperText mb = {"25"}>Example: <i>https://&lt;your-church&gt;.ccbchurch.com/api.php</i></FormHelperText>
              </FormControl>
              {error ? (
                <Alert status='error' mb = {"10px"}>
                  <AlertIcon />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              ) : (
                <></>
              )}
              <Button type = {"submit"} colorScheme={"brand"}>submit</Button>
            </form>
          </Card>
        )}
      </HStack>
      </Box>
    );
  }
}