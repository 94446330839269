import React, {Component} from "react";
import { Flex, Container, Heading, Text, Input, Box, Spacer, Button, HStack, Stack, Divider, Alert, AlertIcon, AlertTitle, AlertDescription, useToast } from "@chakra-ui/react";
import NavBar from "../navBar";
import { Navigate } from "react-router-dom";
import ResetPassword from "./resetPassword";
import AccountInfo from "./accountInfo";

const API_URL = process.env.REACT_APP_API_URL;

export default class AccountDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      firstName : "",
      lastName : "",
      email : "",
      loading : false,
      success : false,
      isMounted : false,
      isAuthenticated : false
    };
  }
  async componentDidMount(){
    var authenticated = await this.authenticate();
    
    if(authenticated){
      fetch(`/api/auth/account`, {
        method : "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
      }).then((res) => res.json())
      .then((res) => {
        //alert(JSON.stringify(res));
        let role = (res.role == "User") ? 3 : (res.role == "Admin") ? 6 : (res.role == "Owner") ? 9 : undefined;
        this.setState({
          firstName : res.first_name,
          lastName : res.last_name,
          email : res.email,
          role : role,
          roleName : res.role,
          isMounted : true,
          isAuthenticated : true
        })
      });
    } else {
      this.setState({
        isMounted: true,
        isAuthenticated: false,
      });
    }
  }

  async authenticate(){
    var x = await fetch(`/api/auth/authentication`, {method: "POST", credentials: "include",})
    .then((res) =>{
        if(res.status == 200) {
            return true;
        }
        return false;
    });
    return x;
  }

  render(){
    const {isAuthenticated, isMounted, isNewPasswordOneValid, isNewPasswordTwoValid, newPasswordOneErrorMsg, loading, oldPasswordErrorMsg, isOldPasswordValid, success, firstName, lastName, email, role} = this.state;
    if(!isAuthenticated && isMounted) return <Navigate to="/login" />;
    if(!isMounted) return <></>;
    return(
      <>
        <Stack  spacing={5} maxW = "500px">
          <Heading fontSize={"4xl"} color = "#666">Account Information</Heading>
          <AccountInfo />
          
          <Divider />
          <Heading fontSize={"4xl"} color = "#666">Reset Password</Heading>
          <ResetPassword />
          <Divider />
          <Heading fontSize={"4xl"} color = "#666">Payment Details</Heading>
          <Text>For any questions, subscription updates, or cancellation requests, please feel free to reach out to us at <Text as="span" color={"blue"}>support@flockanalytics.com</Text>.</Text>
        </Stack>
      </>
    );
  }
}