import React, {Component} from "react";
import { Flex, Container, Heading, Text, Progress, Box, Button, HStack, Badge} from "@chakra-ui/react";
import FilterBar from "./filters/filterBar";
import DashboardBodyWrapper from "./dashboardBodyWrapper copy";
import { lowCollisionHash } from "../../util/util";
import DashboardSettings from './dashboardSettings';
import { messages, messagesBlocked } from "./messages";
import { Navigate } from "react-router-dom";
import AddressSelector from './addressSelector'

/*data = [
  {
    dataName : "name",
    formName : "name",
    validFilters : [{
      componentName : "ComponentName",
      formName : "Form name"
    }]
  }
]*/

const API_URL = process.env.REACT_APP_API_URL;

export default class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      isMounted : false,
      isAuthenticated: false,
      updateMessage : "",
      blockedMessage : "",
      blocked : false,
      dashboardStatus : 200,
      lastUpdated : "",

      filters : [],
      groupData : [],
      individualData : [],
      filteredIndividualData : [],
      filteredIndividualDataHash : "",
      filteredMetaData : {},
      snapshots : [],
      campuses : [],

      showUnknown : true,
      showColorBlind : false,
      largeFont : false,

      additionalComponent : ""
    };

    this.authenticate = this.authenticate.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.filterData = this.filterData.bind(this);
    this.computeMetaDataCCB = this.computeMetaDataCCB.bind(this);
    this.computeMetaDataPC = this.computeMetaDataPC.bind(this);
    this.setUnknown = this.setUnknown.bind(this);
    this.setColorBlind = this.setColorBlind.bind(this);
    this.setLargeFont = this.setLargeFont.bind(this);
  }

  async componentDidMount(){
    var authenticated = await this.authenticate();
    
    if(authenticated){
      const params = new URLSearchParams(window.location.search);
      const paramObj = {};
      for (const [key, value] of params) paramObj[key] = value;

      let res = await fetch('/api/church/meta',{
        credentials: "include", 
        headers: {
          "Content-Type": "application/json",
        },
      })
      res = await res.json();
      
      const demo = (paramObj.hasOwnProperty('demo') && paramObj['demo'] == 'true') || res.demo;

      let blocked = res.blocked;
      let message = (!blocked) ? messages[res.status] : messagesBlocked[res.status];
      this.setState({
        blocked: blocked,
        message : message,
        dashboardStatus : res.status,
        }, () =>{
          if(this.state.dashboardStatus == 100) {
            this.updateDashboard();
          }
        })
      ;
      if(!blocked){
        res = await fetch(`/api/church/load`, 
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({includeArchiveData : this.props.includeArchiveData, demo : demo})
          }
        )
        res = await res.json();
        const metaData = (res.api_type == "ccb") ? this.computeMetaDataCCB(res) : this.computeMetaDataPC(res);
        const hash = lowCollisionHash(JSON.stringify(res.individuals));
        const snapshots = this.calculate_snapshots(res.individuals);
        if(res.individuals.length == 0) var lastUpdated = '';
        else {
          var lastUpdated = new Date(res.individuals[0].snapshot_date);
          const options = {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZoneName: 'short'
          };
          var formattedDateString = lastUpdated.toLocaleString('en-US', options);
        }
        if(this.props.additionalComponent == "addressSelector"){
          this.setState({addressType : "home_address"});
        }
        this.setState({
          demo : demo,
          apiType : res.api_type,
          groupData : res.groups,
          individualData: res.individuals,
          filteredIndividualData : res.individuals,
          campuses : res.campuses,
          filteredIndividualDataHash : hash,
          filteredMetaData : metaData,
          isMounted : true,
          isAuthenticated : true,
          lastUpdated : formattedDateString,
          snapshotDates : snapshots
        });
      } else {
        this.setState({
          dashboardStatus : res.status,
          blocked : res.blocked,
          message : res.blocked ? messagesBlocked[res.status] : messages[res.status],
          isMounted : true,
          isAuthenticated : true,
        })
      }
    } else {
        this.setState({
            isMounted: true,
            isAuthenticated: false,
        });
    }
  }

  async authenticate(){
    var x = await fetch(`/api/auth/authentication`, {method: "POST", credentials: "include",})
    .then((res) =>{
        if(res.status == 200) {
            return true;
        }
        return false;
    });
    return x;
  }

  calculate_snapshots = (individuals)=> {
    let snapshots = new Set();
    individuals.forEach(individual => {
      snapshots.add(individual.snapshot_date)
    });
    return Array.from(snapshots);
  }

/*shouldComponentUpdate(nextProps, nextState) {
  //console.log("old hash: " + this.state.filteredIndividualDataHash);
  //console.log("new hash: " + nextState.filteredIndividualDataHash);
  const keys1 = Object.keys(state);
  const keys2 = Object.keys(nextState);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys in obj1 exist in obj2 and have the same values
  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
  if (this.state.filteredIndividualDataHash == nextState.filteredIndividualDataHash && 
      this.state.showUnknown == nextState.showUnknown &&
      this.state.showColorBlind == nextState.showColorBlind &&
      this.state.isAuthenticated == nextState.isAuthenticated &&
      this.state.isMounted == nextState.isMounted &&
      this.state.largeFont == nextState.largeFont &&
      this.state.update) 
    return false;
  else
    return true;
}*/

  updateFilter(newFilter){
    if(newFilter === null) return;
    const updatedFilters = this.state.filters;
    //console.log("filter test: "+ JSON.stringify(updatedFilters));
    for (let i = updatedFilters.length - 1; i >= 0; i--) {
      if (updatedFilters[i].tag === newFilter.tag) {
        updatedFilters[i] = newFilter;
        this.setState({ filters: updatedFilters });
        this.filterData();
        return;
      }
    }
    updatedFilters.push(newFilter);
    this.setState({ filters: updatedFilters });
    this.filterData();
  }

  deleteFilter(tag){
    const updatedFilters = this.state.filters;
    for (let i = updatedFilters.length - 1; i >= 0; i--) {
      if (updatedFilters[i].tag === tag) {
        updatedFilters.splice(i, 1);
        this.setState({ filters: updatedFilters }, () =>  this.filterData());
        //this.filterData();
        return;
      }
    }
  }

  filterData(){
    const {individualData, filters, showUnknown} = this.state;
    //filters.forEach(filter => console.log(filter.filterCond.toString()));
    const filteredIndividualData = individualData.filter(individual =>
      filters.every(filter => filter.filterCond(individual, showUnknown))
    );
    //console.log("filteredIndividualData: " + filteredIndividualData);
    const hash = lowCollisionHash(JSON.stringify(filteredIndividualData));
    this.setState({
      filteredIndividualData : filteredIndividualData,
      filteredIndividualDataHash : hash,
    });
  }

  updateDashboard = async () => {
    var fetchData = () =>{
      // Replace 'your-api-url' with the actual URL of your API
      fetch('/api/church/meta',{
        credentials: "include", 
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((responseMeta) => responseMeta.json())
      .then(responseMeta=>{
        console.log(JSON.stringify(responseMeta));
        if(responseMeta.status != 100){ 
          window.location.reload();
        }
      })
      .catch(error => {
        console.error("here?")
        console.error('Fetch error:', error);
      });
    }
    if(this.state.dashboardStatus != 100) return;
    const intervalId = setInterval(fetchData, 10000);
  }

  setColorBlind(value){
    this.setState({showColorBlind : value});
  }

  setLargeFont(value){
    this.setState({largeFont : value});
  }

  setUnknown(value){
    this.setState({showUnknown : value}, () => this.filterData());
  }

  computeMetaDataCCB(newData){
    var newMetaData = {
      fields : {},
      filters : {},
      individualData : {},
      groupData : {},
    };
    var filters = {
      age : [{ id : 0, name : "Range Slider"}],
      gender : [{ id : 1, name : "Multi Select"}],
      marital_status : [{ id : 1, name : "Multi Select"}],
      baptized : [{ id : 2, name : "Checkbox"}],
      active : [{id : 2, name : "Checkbox"}],
      membership_type : [{id : 1, name : "Multi Select"}],

      mailing_address : {
        state : [{ id : 1, name : "Multi Select"}],
        city : [{ id : 1, name : "Multi Select"}],
        zip : [{ id : 1, name : "Multi Select"}],
      },
      home_address : {
        state : [{ id : 1, name : "Multi Select"}],
        city : [{ id : 1, name : "Multi Select"}],
        zip : [{ id : 1, name : "Multi Select"}],
      },
      work_address : {
        state : [{ id : 1, name : "Multi Select"}],
        city : [{ id : 1, name : "Multi Select"}],
        zip : [{ id : 1, name : "Multi Select"}],
      },
      groups : [{ id : 1, name : "Multi Select"}],
      campus_id : [{ id : 1, name : "Multi Select"}],
      
    };
    newMetaData.filters = filters;

    var fields = {
      age : "Age",
      gender : "Gender",
      marital_status : "Marital status",
      baptized : "Baptized",
      active : "Active",
      membership_type : "Membership Type",
      mailing_address : {
        _id : "Mailing Address",
        state : "State",
        city : "City",
        zip : "Zip code",
      },
      home_address : {
        _id : "Home address",
        state : "State",
        city : "City",
        zip : "Zip code",
      },
      work_address : {
        _id : "Work address",
        state : "State",
        city : "City",
        zip : "Zip code",
      },
      groups : "Groups",
      campus_id : "Campus"
    };
    newMetaData.fields = fields;

    var data = {
      minAge : 10000000,
      maxAge : 0,
      gender : [],
      marital_status : [],
      baptized : [],
      membership_type : [],
      mailing_address : {
        state : [],
        city : [],
        zip : []
      },
      home_address : {
        state : [],
        city : [],
        zip : []
      },
      work_address : {
        state : [],
        city : [],
        zip : []
      },
      groups : [],
      campus_id : []
    }
    newData.individuals.forEach((i, j) => {
      data.minAge = (i.age == null) ? data.minAge : (i.age < data.minAge) ? i.age : data.minAge;
      data.maxAge = (i.age == null) ? data.maxAge : (i.age > data.maxAge) ? i.age : data.maxAge;
      //console.log("max age: " + data.maxAge);
      if(i.gender != null && !data.gender.includes(i.gender)) data.gender.push(i.gender);
      if(i.marital_status != null && !data.marital_status.includes(i.marital_status)) data.marital_status.push(i.marital_status);
      if(i.membership_type != null && !data.membership_type.includes(i.membership_type)) data.membership_type.push(i.membership_type);
      if(i.baptized != null && !data.baptized.includes(i.baptized)) data.baptized.push(i.baptized);

      if(i.mailing_address.state != null && !data.mailing_address.state.includes(i.mailing_address.state)) data.mailing_address.state.push(i.mailing_address.state);
      if(i.mailing_address.city != null && !data.mailing_address.city.includes(i.mailing_address.city)) data.mailing_address.city.push(i.mailing_address.city);
      if(i.mailing_address.zip != null && !data.mailing_address.zip.includes(i.mailing_address.zip)) data.mailing_address.zip.push(i.mailing_address.zip);

      if(i.home_address.state != null && !data.home_address.state.includes(i.home_address.state)) data.home_address.state.push(i.home_address.state);
      if(i.home_address.city != null && !data.home_address.city.includes(i.home_address.city)) data.home_address.city.push(i.home_address.city);
      if(i.home_address.zip != null && !data.home_address.zip.includes(i.home_address.zip)) data.home_address.zip.push(i.home_address.zip);

      if(i.work_address.state != null && !data.work_address.state.includes(i.work_address.state)) data.work_address.state.push(i.work_address.state);
      if(i.work_address.city != null && !data.work_address.city.includes(i.work_address.city)) data.work_address.city.push(i.work_address.city);
      if(i.work_address.zip != null && !data.work_address.zip.includes(i.work_address.zip)) data.work_address.zip.push(i.work_address.zip);
    });
    newData.groups.forEach(g => {
      if(!data.groups.some(group => group.ccb_id == g.ccb_id)) data.groups.push({ccb_id : g.ccb_id, name : g.name});
      //if(!data.inactive.includes(g.inactive)) data.inactive.push(g.inactive);
      //if(!data.type.includes(g.type)) data.type.push(g.type);
      //if(!data.meeting_day.includes(g.meeting_day)) data.meeting_day.push(g.meeting_day);
    });
    data.campus_id = newData.campuses.map(campus => ({ccb_id : campus.ccb_id, name : campus.name}));
    //console.log(newData.groups);
    data.maxAge = (data.maxAge > 100) ? 100 : data.maxAge;
    newMetaData.data = data;

    return newMetaData;
  }

  computeMetaDataPC(newData){
    var newMetaData = {
      fields : {},
      filters : {},
      individualData : {},
      groupData : {},
    };
    var filters = {
      age : [{ id : 0, name : "Range Slider"}],
      gender : [{ id : 1, name : "Multi Select"}],
      marital_status : [{ id : 1, name : "Multi Select"}],
      //baptized : [{ id : 2, name : "Checkbox"}],
      active : [{id : 2, name : "Checkbox"}],
      membership_type : [{id : 1, name : "Multi Select"}],
      home_address : {
        state : [{ id : 1, name : "Multi Select"}],
        city : [{ id : 1, name : "Multi Select"}],
        zip : [{ id : 1, name : "Multi Select"}],
      },
      work_address : {
        state : [{ id : 1, name : "Multi Select"}],
        city : [{ id : 1, name : "Multi Select"}],
        zip : [{ id : 1, name : "Multi Select"}],
      },
      other_address : {
        state : [{ id : 1, name : "Multi Select"}],
        city : [{ id : 1, name : "Multi Select"}],
        zip : [{ id : 1, name : "Multi Select"}],
      },
      groups : [{ id : 1, name : "Multi Select"}],
      campus_id : [{ id : 1, name : "Multi Select"}],
    };
    newMetaData.filters = filters;

    var fields = {
      age : "Age",
      gender : "Gender",
      marital_status : "Marital status",
      //baptized : "Baptized",
      active : "Active",
      membership_type : "Membership Type",
      home_address : {
        _id : "Home address",
        state : "State",
        city : "City",
        zip : "Zip code",
      },
      work_address : {
        _id : "Work address",
        state : "State",
        city : "City",
        zip : "Zip code",
      },
      other_address : {
        _id : "Other Address",
        state : "State",
        city : "City",
        zip : "Zip code",
      },
      groups : "Groups",
      campus_id : "Campus"
    };
    newMetaData.fields = fields;

    var data = {
      minAge : 10000000,
      maxAge : 0,
      gender : [],
      marital_status : [],
      //baptized : [],
      membership_type : [],
      home_address : {
        state : [],
        city : [],
        zip : []
      },
      work_address : {
        state : [],
        city : [],
        zip : []
      },
      other_address : {
        state : [],
        city : [],
        zip : []
      },
      groups : [],
      campus_id : []
    }
    newData.individuals.forEach((i, j) => {
      data.minAge = (i.age == null) ? data.minAge : (i.age < data.minAge) ? i.age : data.minAge;
      data.maxAge = (i.age == null) ? data.maxAge : (i.age > data.maxAge) ? i.age : data.maxAge;
      //console.log("max age: " + data.maxAge);
      if(i.gender != null && !data.gender.includes(i.gender)) data.gender.push(i.gender);
      if(i.marital_status != null && !data.marital_status.includes(i.marital_status)) data.marital_status.push(i.marital_status);
      if(i.membership_type != null && !data.membership_type.includes(i.membership_type)) data.membership_type.push(i.membership_type);
      //if(i.baptized != null && !data.baptized.includes(i.baptized)) data.baptized.push(i.baptized);

      if(i.home_address.state != null && !data.home_address.state.includes(i.home_address.state.trim().toLowerCase())) data.home_address.state.push(i.home_address.state.trim().toLowerCase());
      if(i.home_address.city != null && !data.home_address.city.includes(i.home_address.city.trimStart().trimEnd().toLowerCase().replace(/(?:^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase()))) 
        data.home_address.city.push(i.home_address.city.trimStart().trimEnd().toLowerCase().replace(/(?:^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase()));
      if(i.home_address.zip != null && !data.home_address.zip.includes(i.home_address.zip)) data.home_address.zip.push(i.home_address.zip);

      if(i.work_address.state != null && !data.work_address.state.includes(i.work_address.state)) data.work_address.state.push(i.work_address.state);
      if(i.work_address.city != null && !data.work_address.city.includes(i.work_address.city)) data.work_address.city.push(i.work_address.city);
      if(i.work_address.zip != null && !data.work_address.zip.includes(i.work_address.zip)) data.work_address.zip.push(i.work_address.zip);

      if(i.other_address.state != null && !data.other_address.state.includes(i.other_address.state)) data.other_address.state.push(i.other_address.state);
      if(i.other_address.city != null && !data.other_address.city.includes(i.other_address.city)) data.other_address.city.push(i.other_address.city);
      if(i.other_address.zip != null && !data.other_address.zip.includes(i.other_address.zip)) data.other_address.zip.push(i.other_address.zip);

    });
    newData.groups.forEach(g => {
      if(!data.groups.some(group => group.planningCenter_id == g.planningCenter_id)) data.groups.push({planningCenter_id : g.planningCenter_id, name : g.name});
      //if(!data.inactive.includes(g.inactive)) data.inactive.push(g.inactive);
      //if(!data.type.includes(g.type)) data.type.push(g.type);
      //if(!data.meeting_day.includes(g.meeting_day)) data.meeting_day.push(g.meeting_day);
    });
    //});
    data.campus_id = newData.campuses.map(campus => ({ccb_id : campus.planningCenter_id, name : campus.name}));
    data.maxAge = (data.maxAge > 100) ? 100 : data.maxAge;
    newMetaData.data = data;

    return newMetaData;
  }

  render(){
    const BodyComponent = this.props.bodyComponent;
    const {title, includeArchiveData} = this.props;
    const {demo, blocked, message, dashboardStatus, lastUpdated, filteredIndividualData, groupData, isMounted, isAuthenticated, filteredMetaData, showUnknown, showColorBlind, filteredIndividualDataHash, largeFont, snapshotDates, campuses } = this.state;
    let bodyWrapperProps = {
      filteredIndividualData : filteredIndividualData,
      filteredIndividualDataHash : filteredIndividualDataHash,
      groupData : groupData, 
      campuses : campuses,
      isMounted : isMounted, 
      showUnknown :showUnknown,
      showColorBlind : showColorBlind,
      largeFont : largeFont,
      setColorBlind : this.setColorBlind,
      setLargeFont : this.setLargeFont,
      bodyComponent : BodyComponent,
      snapshotDates : snapshotDates,
      additionalComponent : this.props.additionalComponent,
      includeSettings : this.props.includeSettings
    };
    if(this.props.additionalComponent == "addressSelector") bodyWrapperProps.addressType = this.state.addressType;
    return (
      <>
        {isMounted ? (
          isAuthenticated ? (
            !blocked ? 
            ( 
              <Box position={"relative"} minH={"125vh"} pb = "25">
                <Flex justifyContent="center" alignItems="center">
                  <Heading textAlign={"center"} fontSize = {{sm: "4xl", md : "5xl", lg : "6xl" }} px = {"25px"} color = "#666">{title}
                  </Heading>{demo ? (<Badge ml='1' fontSize='1.25rem' colorScheme='blue' variant='solid'>Demo</Badge>) : <></>}  
                </Flex>
                <HStack pl = {"1vw"} align={"center"}>
                  <Text color={"gray"} m = {0}>{message}</Text>
                </HStack>
                {this.props.additionalComponent == "addressSelector" ? (
                  <AddressSelector 
                    setAddressType = {
                      (addressType) =>{ 
                        console.log(addressType);
                        this.setState({addressType : addressType})
                      }
                    }  
                    addressType = {this.state.addressType}
                    addressTypeMetaData = {(this.state.apiType == 'ccb') ? [
                      {
                        name: 'Home Address',
                        value: 'home_address'
                      },
                      {
                        name: 'Work Address',
                        value: 'work_address'
                      },
                      {
                        name: 'Mailing Address',
                        value: 'mailing_address'
                      },
                    ] :
                    [
                      {
                        name: 'Home Address',
                        value: 'home_address'
                      },
                      {
                        name: 'Work Address',
                        value: 'work_address'
                      },
                      {
                        name: 'Other Address',
                        value: 'other_address'
                      }
                    ]}
                  /> 
                ) : (
                  <></>
                )}
                <Flex p={5} maxW = {"100%"} paddingLeft = {"0px"}>
                  <FilterBar 
                    updateFilter = {this.updateFilter} 
                    deleteFilter = {this.deleteFilter} 
                    data = {filteredMetaData} 
                    groupData = {groupData} 
                    setUnknown={this.setUnknown}
                    showUnknown={this.state.showUnknown}
                  />
                  <DashboardBodyWrapper 
                    {...bodyWrapperProps}
                  />
                </Flex>
                {demo ? 
                    <></> 
                :(
                  <HStack pl = {"1vw"} align={"center"}>
                    <Text color={"gray"} m = {0}>Last Updated: {lastUpdated}</Text>
                    <Button 
                      colorScheme={"brand"} 
                      variant= {'link'} 
                      onClick = {async () => {
                        let res = await fetch('/api/church/refresh',{
                          credentials: "include", 
                          headers: {
                            "Content-Type": "application/json",
                          },
                        })
                        res = await res.json();
                        await this.setState({dashboardStatus : 100, message : messages[100]}, () => this.updateDashboard());
                      }} 
                      isDisabled={dashboardStatus == 100}
                    >
                      {dashboardStatus != 100 ? 'Refresh' : 'Refreshing...'}
                    </Button>
                </HStack>
                )}
              </Box>
            ) : (
              <Box height = {"100vh"}>
                <Container borderRadius={"lg"} bg = {"gray.50"} width={"85%"}>
                  <Heading fontSize={{sm: "4xl", md : "5xl", lg : "6xl" }} color = {"brand.500"}>{message}</Heading>
                </Container>
              </Box>
            )
          ) : (
          <Navigate to="/login" />
          )
        ) : (
          <Box height = {"100vh"}>
            <Container borderRadius={"lg"} bg = {"gray.50"} width={"85%"}>
              <Heading fontSize={{sm: "4xl", md : "5xl", lg : "6xl" }} color = {"brand.500"}>Look to the LORD and his strength; seek his face always</Heading>
              <Text fontSize = {{ base: '3xl', md: '5xl', lg: '5xl' }} color = {"rgb(135, 222, 205)"}>I Chronicles 16:11</Text>
              <Progress bg = "gray.50" size='md' colorScheme = "brand" isIndeterminate/>
            </Container>
          </Box>
        )}
      </>
    );
  }
}