import React, { Component } from "react";
import { Menu, MenuButton, MenuList, MenuItem, IconButton, Switch, Spacer, Tooltip, Box } from "@chakra-ui/react";
import { BiGlasses } from "react-icons/bi";

class MyMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.handleColorBlind = this.handleColorBlind.bind(this);
    this.handleLargeFont = this.handleLargeFont.bind(this);
  }

  handleColorBlind = (event) => {
    this.props.setColorBlind(event.target.checked);
  };
  handleLargeFont = (event) =>{
    this.props.setLargeFont(event.target.checked);
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Box
      bg="white"
      p={2}
      borderBottomRightRadius="5px"
      borderTopRightRadius="5px"
      shadow={"rgba(0, 0, 0, 0.24) 3px 3px 3px"}
    >
      <Menu 
      closeOnSelect = {false}
      onOpen = {() => this.setState({isOpen : true})}
      onClose = {() => this.setState({isOpen : false})}
      >
        <Tooltip label = {isOpen ? "" : "Accessibility"}>
        <span>
        <MenuButton 
          as={IconButton} 
          variant='link' 
          icon={<BiGlasses size={25} />} 
        />
        </span>
        </Tooltip>
        <MenuList >
          <MenuItem >
            Color Blind Mode
            <Spacer />
            <Switch size="lg" onChange={this.handleColorBlind}/>
          </MenuItem>
          <MenuItem >
            Large Font
            <Spacer />
            <Switch size="lg" onChange={this.handleLargeFont}/>
          </MenuItem>
        </MenuList>
      </Menu>
      </Box>
    );
  }
}

export default MyMenu;
