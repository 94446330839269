import { Box, Button, useDisclosure, IconButton, Tooltip } from "@chakra-ui/react";
import { BiFilterAlt, BiLeftArrow } from "react-icons/bi";
import { DashboardContext } from "./DashboardContext";
import { useContext } from "react";
import '../../../App.css';

const FilterButton = () => {
  const [modalState, setModalState] = useContext(DashboardContext);
  const { onOpen, onClose } = useDisclosure();

  const onToggleSidebarHandler = () => {
    if (modalState.sidebarIsOpen) {
      onClose();
      setModalState({
        ...modalState,
        sidebarIsOpen: false
      });
    } else {
      onOpen();
      setModalState({
        ...modalState,
        sidebarIsOpen: true
      });
    }
  };

  return (
    <Tooltip label = {modalState.sidebarIsOpen ? "" : ""}>
    <Box
      bg="white"
      p={2}
      borderBottomRightRadius="5px"
      borderTopRightRadius="5px"
      shadow={"rgba(0, 0, 0, 0.24) 3px 3px 3px"}
    >
      <IconButton 
        p={0}
        onClick={onToggleSidebarHandler}
        bg = "white"
        color = "gray.600"
        _hover={{bg : "white", color : "gray.800"}}
        icon = {modalState.sidebarIsOpen ? <BiLeftArrow size={25}/> : <BiFilterAlt size={25}/>}
      />
    </Box>
    </Tooltip>
  );
};

export default FilterButton;
