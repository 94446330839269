import React, {Component} from 'react';
import './triangleSvg.css';
import { Box, Image, Center, Heading, Spacer } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';

class EllipseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
  }

  link = (path) =>{
    const a = document.createElement('a');
    a.setAttribute('href', path);
    a.click();
  }
  
  render(){
    const { isHovered } = this.state;
    ///const ellipseScale = isHoveredS ? 1.2 : 1;
    return (
      <Center backgroundColor={"brand.500"}>
        <Center py = "100px" px = "100px">
          <Image  
            onMouseEnter={() => this.setState({isHovered: true})}
            onMouseLeave={() => this.setState({isHovered: false})}
            transform={isHovered ? "scale(1.2)" : "scale(1)"}
            transition="transform 0.3s"
            transformOrigin="center"
            maxH = "450"
            borderRadius = "15%" 
            src = "./map.png" 
            alt = 'Map Dashboard' 
            cursor={isHovered ? "pointer" : "default"}
            onClick = {() => this.link('/map-dashboard')}
          />
        </Center>
        <Heading fontSize = {{sm: "4xl", md : "5xl", lg : "6xl" }} px = {"25px"} color = "white">Explore the Map</Heading>
      </Center>
    );
  }
};

export default EllipseComponent;
