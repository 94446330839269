import React, { Component } from 'react';
import { faker } from '@faker-js/faker';
import { birthdayToAge, downloadCSV } from '../../util/util.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import createUtilityClassName from 'react-bootstrap/esm/createUtilityClasses.js';
import { Button, ButtonGroup } from '@chakra-ui/react'
import GraphWrapper from '../graphWrapper.js';
import pattern from 'patternomaly';
import { compareObjects } from '../../util/util.js';

ChartJS.register(ArcElement, Tooltip, Legend);



export const largeFontOptions = {
    responsive: true,
    maintainAspectRatio : false,
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 18
              }
          }
      }
    },
    layout: {
      padding: {
        bottom: 15,
        right : 10,
        left : 10
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          font : {
            size : 18
          }
        },
        stacked: true,
      },
      y: {
        ticks: {
          font : {
            size : 18
          }
        },
        stacked: true,
      },
    },
  };
  export const defaultOptions = {
    responsive: true,
    maintainAspectRatio : false,
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 13
              }
          }
      }
    },
    layout: {
      padding: {
        bottom: 15,
        right : 10,
        left : 10
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };


const backgroundColor = [
  'rgb(255,177,194)',
  'rgb(155,209,245)',
  'rgb(192,192,192)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(128,128,128, 1)',
];
const colorBlindBackgroundColor = [
  pattern.draw('cross', '#d55e00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('plus','rgba(128,128,128, 0.3)')
];
const colorBlindBorderColor = [
  pattern.draw('cross', '#d55e00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('plus','rgba(128,128,128, 0.3)')
];
const borderWidth = 1;

export default class GenderBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
    };
    this.calculateData = this.calculateData.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.downloadPng = this.downloadPng.bind(this);

    this.myRef = React.createRef();
  }

  componentDidMount(){
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    } 
  }

  calculateData(){
    var ageRanges = [[0,17], [18,24], [25,34], [35,44], [45,54], [54,65], [66,100000]];

    var data = {
      female : Array(ageRanges.length+1).fill(0),
      male : Array(ageRanges.length+1).fill(0),
      unknown : Array(ageRanges.length+1).fill(0),
    }
    this.props.data.forEach(individual => {  
      for(var i = 0; i < ageRanges.length; i++){
        if(individual.age == null){
          if(individual.gender == null) data.unknown[ageRanges.length] += 1;
          else if(individual.gender == "F") data.female[ageRanges.length] += 1;
          else if(individual.gender == "M") data.male[ageRanges.length] += 1;
          break;
        } else if(individual.age <= ageRanges[i][1]){
          if(individual.gender == null) data.unknown[i] += 1;
          else if(individual.gender == "F") data.female[i] += 1;
          else if(individual.gender == "M") data.male[i] += 1;
          break;
        }
      }
    });

    var returnData = {
      labels: ['0-17','18-24', '25-34', '35-44', '45-54', '54-65', '66+', "Unknown"],
      datasets: [
        {
          label: 'Female',
          data: data.female,
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[0] : backgroundColor[0],
          borderColor: this.props.showColorBlind ? colorBlindBorderColor[0] : borderColor[0],
          borderWidth: borderWidth,
          stack: 'Stack 0',
        },
        {
          label: 'Male',
          data: data.male,
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[1] : backgroundColor[1],
          borderColor: this.props.showColorBlind ? colorBlindBorderColor[1] : borderColor[1],
          borderWidth: borderWidth,
          stack: 'Stack 1',
          },
        {
          label: 'Unknown',
          data: data.unknown,
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[2] : backgroundColor[2],
          borderColor: this.props.showColorBlind ? colorBlindBorderColor[2] : borderColor[2],
          borderWidth: borderWidth,
          stack: 'Stack 2',
        },
      ],
    };
    if(!this.props.showUnknown) { 
      returnData.datasets.pop();
      returnData.labels.pop();
      returnData.datasets.forEach((element) => element.data.pop() );
    }
    return returnData;
  }

  downloadPng = () => {
    const canvas = this.myRef.current.canvas;
    const ctx = canvas.getContext('2d');
  
    // Save the current canvas state
    ctx.save();
  
    // Set white background
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Restore the canvas state
    ctx.restore();  
  
    // Convert chart to base64 image with white background
    const chartBase64 = canvas.toDataURL('image/png');
  
    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = chartBase64;
    link.click();
  }

  downloadCSV(){
    var ageRanges = [[0,17], [18,24], [25,34], [35,44], [45,54], [54,65], [66,100000]];

    var data = {
      female : Array(ageRanges.length+1).fill(0),
      male : Array(ageRanges.length+1).fill(0),
      unknown : Array(ageRanges.length+1).fill(0),
    }

    this.props.data.forEach(individual => {  
      for(var i = 0; i < ageRanges.length; i++){
        if(individual.age == null){
          if(individual.gender == null) data.unknown[ageRanges.length] += 1;
          else if(individual.gender == "F") data.female[ageRanges.length] += 1;
          else if(individual.gender == "M") data.male[ageRanges.length] += 1;
          break;
        } else if(individual.age <= ageRanges[i][1]){
          if(individual.gender == null) data.unknown[i] += 1;
          else if(individual.gender == "F") data.female[i] += 1;
          else if(individual.gender == "M") data.male[i] += 1;
          break;
        }
      }
    });

    var returnData = {
      Age_Range: ['0-17','18-24', '25-34', '35-44', '45-54', '54-65', '66+', 'Unknown'],
      Female: data.female,
      Male: data.male,
      Unknown: data.unknown
    };

    if(!this.props.showUnknown){
      delete returnData.Unknown;
      Object.keys(returnData).forEach(key => returnData[key].pop());
    }

    downloadCSV(returnData);
  }

  render(){
    const{data, isMounted} = this.state;
    const {largeFont} = this.props;
    console.log("font size: " + largeFont)
    if(isMounted)
      return (
        <GraphWrapper 
          title = "Gender by Age" 
          downloadCSV = {this.downloadCSV} 
          downloadPng={this.downloadPng} 
          isMounted = {this.props.isMounted}
        >
            <Bar ref = {this.myRef} data={this.state.data} options = {largeFont ? largeFontOptions : defaultOptions} onClick={this.props.onClick}/>
        </GraphWrapper>
      );
    else 
      return null;
  } 
}