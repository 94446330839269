import React, { Component } from 'react';
import { BsFullscreen, BsFiletypeCsv, BsFiletypePng } from "react-icons/bs";
import {Heading, Text, Container, HStack, IconButton, Spacer,  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal, 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Button,
  Tooltip,
  Box
} from '@chakra-ui/react'
import { BiDotsVerticalRounded } from "react-icons/bi";

export default class GraphWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      isModalOpen: false,
      isMenuOpen : false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleOpen(){
    this.setState({
      isModalOpen: true
    })
  }
  handleClose(){
    this.setState({
      isModalOpen : false
    });
  }
  render(){
    if(this.props.isMounted == true) {
      return (
            <>
            <Box>
            <Container>
              <Text fontSize="2xl" mb="0" color = "#666" align='center'>{this.props.title}</Text>
            </Container>
            </Box>
            <Menu 
              onOpen={() => this.setState({isMenuOpen : true})}
              onClose={() => this.setState({isMenuOpen : false})}
            >
            <Tooltip label = {this.state.isMenuOpen ? "" : "Options"}>
              <MenuButton as={IconButton} variant='link' icon={<BiDotsVerticalRounded />} position="absolute" top="2.5" right="0" /> 
              </Tooltip>
              <Portal>
              <MenuList>
                <MenuItem icon = {<BsFiletypeCsv />} onClick={this.props.downloadCSV}>Download as .csv</MenuItem>
                <MenuItem icon = {<BsFiletypePng />} onClick= {this.props.downloadPng}>Download as .png</MenuItem>
                <MenuItem icon = {<BsFullscreen />} onClick={this.handleOpen}>View FullScreen</MenuItem>
              </MenuList>
              </Portal>
            </Menu>
            <Box flex = {"1"} h="1px">
            {this.props.children}
            </Box>
            <Modal onClick={this.handleClose} size="full" isOpen={this.state.isModalOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader><Center>{this.props.title}</Center></ModalHeader>
                <ModalBody>
                  {this.props.children}
                </ModalBody>
                <ModalFooter bg={'gray.50'}>
                  <Button onClick={this.handleClose}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            </>
      );
    } else {
      return <h1>filler</h1>;
    }
  } 
}