import {
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Link
} from '@chakra-ui/react';
import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import  Login  from "../components/login/login.js"
import SSO from '../components/login/sso.js';
import Footer from "../components/footer.js";
import { LargeLogo } from '../assets/largeLogo.jsx';
import Auth from '../components/auth.js';

export default class LoginPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      page : "login"
    };
  }

  setPage = (component) => {
    this.setState({page : component});
  }
  
  render(){
    const {page} = this.state;
    return (
      <Auth allowedStatus = {[0]} blockedStatus = {{'/registration' : [406], '/review-registration': [407]}} defaultRedirect = {'/home'}>
        <>
          <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={'gray.50'}
            direction = {'column'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} align = {'center'}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center" w = {{ base: '300px', sm: "425px", md : "600px" }}
            >
              <LargeLogo height = {150}/>
              </Box>
              <Heading fontSize={{ base: '3xl', sm: "4xl" }}>Sign in to your account</Heading>
              <Text fontSize={'lg'} color={'gray.600'}>
                New to Flock? Click <Link color={'blue.400'} href={"/"}>here</Link> to learn more!
              </Text>
              <Box
                rounded={'lg'}
                bg={'white'}
                boxShadow={'lg'}
                width = {{ base: '250px', sm: "300px", md : "500px" }}
                p={8}
                minH = {"50vh"}
              >  
                {(page == "login") ? <Login setPage = {this.setPage} /> : <SSO setPage = {this.setPage} />}
              </Box>
            </Stack>
          </Flex>
          <Footer />
        </>
      </Auth>
    );
  }
};
