import { Container, Box, Stack, Heading, Text, Flex, Image, Show } from '@chakra-ui/react';

const Hero = (props) => {
  return (
    <Container px = {{base : 0}} maxW={'9xl'} position="relative" justifyContent={'center'} alignContent={'center'}>
      <Box zIndex={555}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          px={{ base : 0, md: 25 }}
          direction={{ base: 'column', md: 'row' }}
          maxH={'900px'}
          h={'100%'}
        >
          <Stack 
            flex={1} 
            spacing={{ base: 5, md: 10 }} 
            p={5}
            maxW={{ base: '100%', md: '40%' }} // Limit the width of the text stack
          >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
            >
              <Text
                as={'span'}
                position={'relative'}
                color='white'
              >
                {props.name}
              </Text>
            </Heading>
            <Text color={'white'} fontSize={{base : 'md', md: 'lg'}}>
              {props.description}
            </Text>
          </Stack>
          <Flex
            flex={2} // Allocate more space to the image container
            justify={'center'}
            align={'center'}
            position={'relative'}
            h={'100%'}
            w = {'100%'}
          > <Show above = 'sm' >
            <Box
              w={'100%'}
              h={'0'}
              paddingBottom={'79.25%'} // Use aspect ratio to control the height (16:9 aspect ratio)
              position={'relative'}
              overflow={'hidden'}
            >
              <Image
                alt={'Hero Image'}
                src={props.imageSrc}
                fit={'contain'}
                align={'center'}
                position={'absolute'}
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
              />
            </Box>
            </Show>
            <Show below='sm' >
            <Image
              alt={'Hero Image'}
              fit={'cover'}
              align={'center'}
              h = {'100%'}
              src={props.imageSrc}
            />
            </Show>
          </Flex>
        </Stack>
      </Box>
    </Container>
  );
};

export default Hero;
