  import React, { Component } from "react";
  import { IconButton, Checkbox, Stack, Text, Box, Heading } from "@chakra-ui/react";
  import { BiX } from "react-icons/bi";

  class CheckboxFilter extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isTrueChecked: true,
        isFalseChecked : false
      };
      this.updateFilter = this.updateFilter.bind(this);
      this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount(){
      this.updateFilter();
    }
    handleCheckboxChange = (checkbox) => {
      const newState = {
        isTrueChecked: false,
        isFalseChecked: false,
      };
      newState[checkbox] = true;
      this.setState(newState, () => {
        this.updateFilter()
      }); 
    };
    updateFilter(){
      const path = JSON.stringify(this.props.keyPath);
      console.log("is: " + this.state.isTrueChecked);
      const filter = eval(`( () => {
        return function(x, showUnknown) {
          var keyPath = ${path};
          var selectedItem = ${this.state.isTrueChecked};
          var value = x;
          for(var i = 0; i < keyPath.length; i++) value = value[keyPath[i]];
          if(selectedItem == value || showUnknown && value == null) return true;
          return false;
        };
      })()`);
      this.props.updateFilter({
        tag : this.props.tag,
        filterCond : filter
      })
    }
    handleClose(){
      this.props.deleteFilter(this.props.tag);
    } 

    render() {
      return (
        <>
        <Box pos = {"relative"}  borderColor={"black"}>
        <IconButton 
          isRound = {true} 
          icon={<BiX size={20}/>} 
          position="absolute" 
          top="1.5" 
          right="0" 
          onClick={this.handleClose}
          variant = "link"
        />
        <Heading 
          fontSize={"2xl"} 
          color = "#666" 
          pb = {"20px"}
          pt = {"20px"}
          pl = {"5px"}
          mb = {"-10px"}
          lineHeight = {"1.25"}
        >
          {this.props.name}
        </Heading>
        <Stack direction="row" px = {"10px"}>
        <Checkbox
            isChecked={this.state.isTrueChecked}
            onChange={() => this.handleCheckboxChange("isTrueChecked")}
            size='lg'
          >
            True
          </Checkbox>
          <Checkbox
            isChecked={this.state.isFalseChecked}
            onChange={() => this.handleCheckboxChange("isFalseChecked")}
            size='lg'
          >
            False
          </Checkbox>
        </Stack>
        </Box>
        </>
      );
    }
  }

  export default CheckboxFilter;
