import React, { Component } from 'react';
import { IconButton, Tooltip, Box} from "@chakra-ui/react";
import { BiExpandAlt, BiCollapseAlt } from "react-icons/bi";
  
export default class FullScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullScreen: false
    };
  }

  componentDidMount() {
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
  }

  componentWillUnmount() {
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
  }

  handleFullscreenChange = () => {
    this.setState({ fullScreen: document.fullscreenElement !== null });
  };

  handleClick = () =>{
    this.setState({fullScreen : !this.state.fullScreen}, () =>{
      if(this.state.fullScreen) this.goFullScreen();
      else this.exitFullScreen();
    });
  }

  goFullScreen = () =>{
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
  }

  exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  }

  render(){
    const{fullScreen} = this.state;
      return (
        <Box
      bg="white"
      p={2}
      borderBottomRightRadius="5px"
      borderTopRightRadius="5px"
      shadow={"rgba(0, 0, 0, 0.24) 3px 3px 3px"}
    >
        <Tooltip label = {fullScreen ? "Exit Fullscreen" : "Fullscreen"}>
        <span>
        <IconButton 
          icon = {fullScreen ? <BiCollapseAlt size={25}/> : <BiExpandAlt size={25}/>} 
          onClick = {this.handleClick}
          variant = "link"
        />
        </span>
        </Tooltip>
        </Box>
      );
  } 
}
