import React, { Component } from 'react';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import '../../App.css';
import { Responsive, WidthProvider } from "react-grid-layout";
import { withSize } from 'react-sizeme'
import {Skeleton} from '@chakra-ui/react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { DashboardContext } from './filters/DashboardContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import FinishedMap from '../../finishedMap';

const SizeAwareReactGridLayout = withSize()(Responsive);


ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const layout = [    
  { i: "a", x: 0, y: 0, w: 5, h: 2 },
  { i: "b", x: 5, y: 0, w: 5, h: 2 },
  { i: "c", x: 0, y: 2, w: 3, h: 2 },
  { i: "d", x: 3, y: 2, w: 3, h: 1 },
  { i: "e", x: 3, y: 3, w: 3, h: 1 },
  { i: "f", x: 6, y: 2, w: 4, h: 2 },
  { i: "g", x: 6, y: 2, w: 2, h: 2 },
];
const layouts = {
    lg : [    
        { i: "a", x: 0, y: 0, w: 5, h: 2 },
        { i: "b", x: 5, y: 0, w: 5, h: 2 },
        { i: "c", x: 0, y: 2, w: 3, h: 2 },
        { i: "d", x: 3, y: 2, w: 3, h: 1 },
        { i: "e", x: 3, y: 3, w: 3, h: 1 },
        { i: "f", x: 6, y: 2, w: 4, h: 2 },
        { i: "g", x: 6, y: 2, w: 2, h: 2 },
    ],
    sm : [      
        { i: "a", x: 0, y: 0, w: 9, h: 2 },
        { i: "b", x: 0, y: 2, w: 9, h: 2 },
        { i: "c", x: 0, y: 4, w: 9, h: 2 },
        { i: "d", x: 0, y: 6, w: 9, h: 1 },
        { i: "e", x: 0, y: 7, w: 9, h: 1 },
        { i: "f", x: 0, y: 8, w: 9, h: 2 },
        { i: "g", x: 0, y: 10, w: 9, h: 2 },
    ]
};
const cols = 9;
const RowHeight = (x) => {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    console.log(h);
    console.log((20 * h) / 100);
    return (19.5 * h) / 100;
  };
const width = 1500;

export default class BabyDashboard extends Component {
    constructor(props){
        super(props);
        //this.authenticate = this.authenticate.bind(this);
        this.state = {
            layout : layout,
            cols : cols,
            rowHeight : RowHeight,
            width : null,
            data : "test",
            isMounted : false,
            isAuthenticated : false,
            isLoggedin : false,
            reload : false,
        }
        this.authenticate = this.authenticate.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);

        this.gridRef = React.createRef();
        this.resizeObserver = null;
    }
    async authenticate(){
        var x = await fetch("/api/auth/authentication", {method: "POST"})
        .then((res) =>{
            if(res.status == 200) {
                return true;
            }
            return false;
        });
        return x;
    }
    async componentDidMount(){
        //var authenticated = await this.authenticate();
        if(true){
            this.updateDimensions();
            this.resizeObserver = new ResizeObserver(this.updateDimensions);
            this.resizeObserver.observe(this.gridRef.current);
            fetch('/church/load')
            .then((res) => res.json())
            .then((res) => {
                this.setState({
                    data: res,
                    isMounted : true
                });
            });
            // Set up a ResizeObserver to listen for changes in the parent div's size
        } else {
            this.setState({
                isMounted: true,
                isAuthenticated: false,
            });
        }
    }
    
  componentWillUnmount() {
    // Stop observing when the component is unmounted
    this.resizeObserver.unobserve(this.gridRef.current);
    this.resizeObserver.disconnect();
  }
    updateDimensions() {
      const {width} = this.gridRef.current.getBoundingClientRect();
      this.setState({width : width});
    }
    render(){
        const {filteredIndividualData, addressType, campuses} = this.props;

        return (
          <div 
            ref={this.gridRef} 
            style={{ 
                width: '90%', 
                margin: "0 auto",
                maxHeight : "calc(95vh",
                overflowY : "auto",
                scrollbarWidth: "thin"}}
          >
            {this.props.isMounted ? (
                <>
                <FinishedMap data = {filteredIndividualData} addressType = {addressType} campuses = {campuses}/>
                </>
            ): <h1> no go!</h1>}
          </div>
        );
    }
}