import React, { Component } from 'react';
import Counter from './counter'
import { Center, HStack, Button, Card, List, ListIcon, ListItem, Stack, Text, Heading, Spacer} from '@chakra-ui/react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Navigate } from 'react-router-dom';

class CcbActivate extends Component {
  constructor(props){
    super(props);
    this.state = {
      isMounted : false,
      paramObj : {},
      success : null
    };
  }

  async componentDidMount(){
    // auth

    const params = new URLSearchParams(window.location.search);
    const paramObj = {};
    
    for (const [key, value] of params) paramObj[key] = value;

    if(paramObj.hasOwnProperty('code')){
      try{
        let res = await fetch('/api/auth/pc-callback',
        {
          credentials: "include", 
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body : JSON.stringify({code : paramObj['code']})
        });
        if(!res.ok) {
          console.error("error");
          this.setState({isMounted : true, success : false})
        }
        this.setState({isMounted : true, success : true})
      } catch(err){
        console.error(err);
      }

    }
    this.setState({paramObj : paramObj})
  }

  render(){
    return(
      <>
        {this.state.isMounted ? (this.state.success ? <Navigate to='/registration?api-type=pc' /> : <Navigate to='/registration?api-type=pc' />) : <></>}
      </>
    );
  }
}

export default CcbActivate;