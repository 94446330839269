import { theme as proTheme } from '@chakra-ui/pro-theme'
import { extendTheme, theme as baseTheme } from '@chakra-ui/react'
import '@fontsource/inter/variable.css'

export const theme = extendTheme(
  {
    colors: { ...baseTheme.colors, 
      brand: {
      50: '#dfeeff',
      100: '#afcdff',
      200: '#7eacff',
      300: '#4b8bff',
      400: '#1a69ff',
      500: '#0050e6',
      600: '#003eb4',
      700: '#002c82',
      800: '#001b51',
      900: '#000921',
        }
      },
  },
  proTheme,
)