import React, {Component} from "react";
import { Flex, Container, Heading, Text, Input, Box, Center, Button, HStack, Stack, Divider, Alert, AlertIcon, AlertTitle, AlertDescription, useToast } from "@chakra-ui/react";
import NavBar from "../navBar";
import { Navigate } from "react-router-dom";
import ResetPassword from "./resetPassword";

const API_URL = process.env.REACT_APP_API_URL;

export default class AccountInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      firstName : "",
      lastName : "",
      email : "",
      loading : false,
      success : false,
      isMounted : false,
      isAuthenticated : false
    };
  }

  async componentDidMount(){
    var authenticated = await this.authenticate();
    
    if(authenticated){
      fetch(`/api/auth/account`, {
        method : "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
      }).then((res) => res.json())
      .then((res) => {
        
        let role = (res.role == "User") ? 3 : (res.role == "Admin") ? 6 : (res.role == "Owner") ? 9 : undefined;
        this.setState({
          firstName : res.first_name,
          lastName : res.last_name,
          email : res.email,
          role : role,
          roleName : res.role,
          isMounted : true,
          isAuthenticated : true
        })
      });
    } else {
      this.setState({
        isMounted: true,
        isAuthenticated: false,
      });
    }
  }

  async authenticate(){
    var x = await fetch(`/api/auth/authentication`, {method: "POST", credentials: "include",})
    .then((res) =>{
        if(res.status == 200) {
            return true;
        }
        return false;
    });
    return x;
  }

  handleSubmit = (e) =>{
    e.preventDefault();
    this.setState({
      loading : true,
      firstNameError : "",
      lastNameError : "",
      emailError : ""
    }, () =>{
      const formData = {};
      for (const element of e.target.elements) {
        if (element.name) {
          formData[element.name] = element.value;
        }
      }
      if(!formData.first_name){
        this.setState({firstNameError : "Required"})
      }
      if(!formData.last_name){
        this.setState({lastNameError : "Required"})
      }
      if(!formData.email){
        this.setState({emailError : "Required"})
      }

      if(formData.first_name && formData.last_name && formData.email){
        fetch(`/api/auth/update-user`, {
          method : "POST",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(formData)
        }).then((res) => {
          this.setState({loading : false})
        });
      }
    })
  }

  render(){
    const {isAuthenticated, isMounted, firstNameError, lastNameError, emailError, loading,  isOldPasswordValid, success, firstName, lastName, email, role} = this.state;
    if(!isAuthenticated && isMounted) return <Navigate to="/login" />;
    if(!isMounted) return <></>;
    return(
        <Stack>
        <form onSubmit={this.handleSubmit}>
          <HStack>
            <Box>
              
              <Text  fontSize = "lg">First Name</Text>
              <Input 
                defaultValue={firstName}
                isDisabled={role < 6}
                name = {"first_name"}
              />
              {firstNameError  ?
              (<Alert mt = "5px" status='error'>
                <AlertIcon />
                <AlertTitle>Invalid</AlertTitle>
                <AlertDescription>{firstNameError}</AlertDescription>
              </Alert>): <></>}
            </Box>

            <Box>
              <Text  fontSize = "lg">Last Name</Text>
              <Input 
                defaultValue={lastName}
                isDisabled={role < 6}
                name = {"last_name"}
              />
              {lastNameError  ?
              (<Alert mt = "5px" status='error'>
                <AlertIcon />
                <AlertTitle>Invalid</AlertTitle>
                <AlertDescription>{lastNameError}</AlertDescription>
              </Alert>): <></>}
            </Box>
          </HStack>

          <Box>
            <Text  fontSize = "lg">Email Address</Text>
            <Input 
              defaultValue={email}
              isDisabled={role < 6}
              name = {"email"}
            />
            {emailError  ?
              (<Alert mt = "5px" status='error'>
                <AlertIcon />
                <AlertTitle>Invalid</AlertTitle>
                <AlertDescription>{emailError}</AlertDescription>
              </Alert>): <></>}
          </Box>
          {(role >= 6) ? (
            <Center>
            <Button 
              type={"submit"}
              mt = {"20px"}
              width = "30%" 
              colorScheme={"brand"}
              isLoading = {loading}
            >
              Update
            </Button> 
            </Center>
          ) : (
            <></>
          )}
        </form>
        </Stack>
    );
  }
}