import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  FormHelperText,
  HStack,
  IconButton,
  Center
} from '@chakra-ui/react';
import data from './data'
import { useState } from 'react';
import { BiX } from 'react-icons/bi';

export default function Hero() {
  const [requestDemo, setRequestDemo] = useState(false);
  const [loadingDemo, setLoadingDemo] = useState(false);
  const [messageStatus, setMessageStatus] = useState('incomplete');
  const [errorMessage, setErrorMessage ] = useState('');

  const makeRequest = (e) =>{
    e.preventDefault();
    setLoadingDemo(true)
    const {email, churchname, firstname, lastname} = e.target;
    if(!email.value || !churchname.value || !firstname.value || !lastname.value){
      setErrorMessage('Please complete the form.')
      setLoadingDemo(false);
      return;
    }
    fetch(`/api/user/request-demo`, {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        "email": email.value,
        "firstname": firstname.value,
        "lastname" : lastname.value,
        "churchname" : churchname.value
      })
    })
    .then((res) => {
        if(res.status == 200) {
          setMessageStatus('complete');
          setLoadingDemo(false);
        } else {
          setErrorMessage('Something went wrong. Please try again later.');
          setLoadingDemo(false);
        }
    });
  }

  return (
    <Container maxW={'9xl'} position="relative">
        <Box position="absolute" top={0} left={0} width="100%" height="100%" zIndex={-1}>
          <ul className="circles">
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
            <li style = {{background : 'brand.200'}}></li>
          </ul>
      </Box >
      <Box zIndex={555}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        px = {{md : 25}}
        direction={{ base: 'column', md: 'row' }}
        h = {'100%'}>
        <Stack 
        flex={1} 
        spacing={{ base: 5, md: 10 }} 
        p = {5}
        style = {{   
          background: 'rgba(247, 250, 252, 0.5)',
          borderRadius: '16px',
          backdropFilter: 'blur(7px)',
          WebkitBackdropFilter: 'blur(7px)'
        }}>
          {! requestDemo ? (
            <>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              >
              {data.title.partOne}
            </Text>
            {' '}
            <Text as={'span'} color={'brand.400'}>
              {data.title.partTwo}
            </Text>
          </Heading>
          <Text color={'gray.500'} fontSize={{base : 'md', md: 'lg'}}>
          {data.description}
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}>
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              colorScheme={'red'}
              bg={'brand.400'}
              _hover={{ bg: 'brand.500' }}
              onClick={() => document.location.href = '/create-profile'}>
              Get started
            </Button>
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              color = {'black'}
              background = {'gray.200'}
              onClick={() => setRequestDemo(true)}
              >
              Request Demo
            </Button>
          </Stack>
          </>
          ) : (
            <>
            <IconButton 
              icon={<BiX size = {20}/>} 
              position="absolute" 
              top="8" 
              right="0" 
              variant = "link"
              onClick={() => {
                setRequestDemo(false);
                setMessageStatus('incomplete');
                setErrorMessage(false);
              }}
            />
            {messageStatus == 'incomplete' ? (
              <>
              <form onSubmit={(e) => makeRequest(e)}>  
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input type="text" />
              </FormControl>
              <FormControl id="churchname" >
                <FormLabel pt={"10px"}>Church Name</FormLabel>
                <Input type="text" />
              </FormControl>

                <Stack direction={{ base: 'column', sm: 'row' }} pt = {'10px'}>
                  <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input id="firstname"/>
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel id="lastname">Last Name</FormLabel>
                    <Input id='lastname'
                    />
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                </Stack>
              <Stack spacing={10} mt={"0px"} align={'center'}>
                <Button
                  colorScheme="brand"
                  type="submit"
                  isLoading = {loadingDemo}
                  w = {'50%'}>
                  Request Demo
                </Button>
              </Stack>
              </form>
              {errorMessage ? (
                <Alert status='error'>
                  <AlertIcon />
                  <AlertTitle>Request Failed</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              ) : 
                null 
              }
              </>
            ) : (
              <Center>
                <Text color={'gray.500'} fontSize={{base : 'md', md: 'lg'}}>
                  Thank you for your request! We will follow up with you for more details shortly.
                </Text>
              </Center>
            )}
          </>
          )}
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
            <Image
              alt={'Hero Image'}
              fit={'cover'}
              align={'center'}
              w={'100%'}
              h={'100%'}
              src={data.src}
            />
        </Flex>
      </Stack>
      </Box>
    </Container>
  );
}