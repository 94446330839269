import React, { Component } from 'react';
import { downloadCSV } from '../../util/util.js';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import GraphWrapper from '../graphWrapper.js';
import pattern from 'patternomaly';
import { compareObjects } from '../../util/util.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export const defaultOptions = {
    responsive: true,
    maintainAspectRatio : false,
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 13
              }
          }
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    layout: {
      padding: {
        bottom: 15,
        right : 10,
        left : 10
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  export const largeFontOptions = {
    responsive: true,
    maintainAspectRatio : false,
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 18
              }
          }
      }
    },
    layout: {
      padding: {
        bottom: 15,
        right : 10,
        left : 10
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          font : {
            size : 18
          }
        },
        stacked: true,
      },
      y: {
        ticks: {
          font : {
            size : 18
          }
        },
        stacked: true,
      },
    },
  };

const backgroundColor = [
  'rgb(255,177,194)',
  'rgb(155,209,245)',
  'rgb(255,231,171)',
  'rgb(165,224,224)',
  'rgb(192,192,192)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(128,128,128, 1)',
];
const colorBlindBackgroundColor = [
  pattern.draw('square','#e69f00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('diamond', '#009e73'),
  pattern.draw('zigzag', '#f0e442'),
  pattern.draw('plus','rgba(128,128,128, 0.3)')
];
const colorBlindBorderColor = [
  pattern.draw('square','#e69f00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('diamond', '#009e73'),
  pattern.draw('zigzag', '#f0e442'),
  pattern.draw('plus','rgba(128,128,128, 0.3)')
];
const borderWidth = 1;

export default class MarriedBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
    };
    this.calculateData = this.calculateData.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.downloadPng = this.downloadPng.bind(this);

    this.myRef = React.createRef();
  }

  calculateData(){
    var ageRanges = [[0,17], [18,24], [25,34], [35,44], [45,54], [54,65], [66,10000],];
    /*define data structure*/
    var data = {
      married : Array(ageRanges.length+1).fill(0),
      single : Array(ageRanges.length+1).fill(0),
      widowed : Array(ageRanges.length+1).fill(0),
      divorced : Array(ageRanges.length+1).fill(0),
      unknown : Array(ageRanges.length+1).fill(0)
    }
    /* Populate data */
    this.props.data.forEach(individual => {    
      for(var i = 0; i < ageRanges.length; i++){
        if(individual.age == null){
          if(individual.marital_status == null) data.unknown[ageRanges.length] += 1;                 // unknown age and unknown status
          else if(individual.marital_status == "Married") data.married[ageRanges.length] += 1;  // unknown age and married
          else if(individual.marital_status == "Single") data.single[ageRanges.length] += 1;    // unknown age and single
          else if(individual.marital_status == "Widowed") data.widowed[ageRanges.length] += 1;  // unknown age and widowed
          else if(individual.marital_status == "Divorced") data.divorced[ageRanges.length] +=1; // unknown age and divorced
          break;
        } else if(individual.age <= ageRanges[i][1]){
          if(individual.marital_status == null) data.unknown[i] += 1;             // known age and unknown status
          else if(individual.marital_status == "Married") data.married[i] += 1;   // known age and married
          else if(individual.marital_status == "Single") data.single[i] += 1;     // known age and single
          else if(individual.marital_status == "Widowed") data.widowed[i] += 1;   // known age and widowed
          else if(individual.marital_status == "Divorced") data.divorced[i] +=1;  // known age and divorced
          break;
        }
      }
    });

    var returnData = {
      labels: ['0-17','18-24', '25-34', '35-44', '45-54', '54-65', '66+', 'Unknown'],
      datasets: [
        {
          label: 'Married',
          data: data.married,
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[0] : backgroundColor[0],
          borderColor: this.props.showColorBlind ? colorBlindBorderColor[0] : borderColor[0],
          borderWidth: borderWidth,
          stack: 'Stack 0',
        },
        {
            label: 'Single',
            data: data.single,
            backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[1] : backgroundColor[1],
            borderColor: this.props.showColorBlind ? colorBlindBorderColor[1] : borderColor[1],
            borderWidth: borderWidth,
            stack: 'Stack 1',
          },
          {
            label: 'Widowed',
            data: data.widowed,
            backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[2] : backgroundColor[2],
            borderColor: this.props.showColorBlind ? colorBlindBorderColor[2] : borderColor[2],
            borderWidth: borderWidth,
            stack: 'Stack 2',
          },
          {
          label: 'Divorced',
          data: data.divorced,
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[3] : backgroundColor[3],
          borderColor: this.props.showColorBlind ? colorBlindBorderColor[3] : borderColor[3],
          borderWidth: borderWidth,
          stack: 'Stack 3',
        },
        {
          label: 'Unknown',
          data: data.unknown,
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[4] : backgroundColor[4],
          borderColor: this.props.showColorBlind ? colorBlindBorderColor[4] : borderColor[4],
          borderWidth: borderWidth,
          stack: 'Stack 4',
        },
      ],
    };
    if(!this.props.showUnknown) { 
      returnData.datasets.pop();
      returnData.labels.pop();
      returnData.datasets.forEach((element) => element.data.pop() );
    }
    return returnData;
  }

  downloadCSV(){
    var ageRanges = [[0,17], [18,24], [25,34], [35,44], [45,54], [54,65], [66,10000],];
    /*define data structure*/
    var data = {
      married : Array(ageRanges.length+1).fill(0),
      single : Array(ageRanges.length+1).fill(0),
      widowed : Array(ageRanges.length+1).fill(0),
      divorced : Array(ageRanges.length+1).fill(0),
      unknown : Array(ageRanges.length+1).fill(0)
    }
    /* Populate data */
    this.props.data.forEach(individual => {    
      for(var i = 0; i < ageRanges.length; i++){
        if(individual.age == null){
          if(individual.marital_status == null) data.unknown[ageRanges.length] += 1;            // unknown age and unknown status
          else if(individual.marital_status == "Married") data.married[ageRanges.length] += 1;  // unknown age and married
          else if(individual.marital_status == "Single") data.single[ageRanges.length] += 1;    // unknown age and single
          else if(individual.marital_status == "Widowed") data.widowed[ageRanges.length] += 1;  // unknown age and widowed
          else if(individual.marital_status == "Divorced") data.divorced[ageRanges.length] +=1; // unknown age and divorced
          break;
        } else if(individual.age <= ageRanges[i][1]){
          if(individual.marital_status == null) data.unknown[i] += 1;             // known age and unknown status
          else if(individual.marital_status == "Married") data.married[i] += 1;   // known age and married
          else if(individual.marital_status == "Single") data.single[i] += 1;     // known age and single
          else if(individual.marital_status == "Widowed") data.widowed[i] += 1;   // known age and widowed
          else if(individual.marital_status == "Divorced") data.divorced[i] +=1;  // known age and divorced
          break;
        }
      }
    });

    var returnData = {
      Age_Range: ['0-17','18-24', '25-34', '35-44', '45-54', '54-65', '66+', 'Unknown'],
      Married: data.married,
      Single: data.single,
      Widowed: data.widowed,
      Divorced: data.divorced,
      Unknown : data.unknown
    };

    if(!this.props.showUnknown){
      delete returnData.Unknown;
      Object.keys(returnData).forEach(key => returnData[key].pop());
    }

    downloadCSV(returnData);
  }
  componentDidMount(){
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }
  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }

  downloadPng = () => {
    const canvas = this.myRef.current.canvas;
    const ctx = canvas.getContext('2d');
  
    // Save the current canvas state
    ctx.save();
  
    // Set white background
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Restore the canvas state
    ctx.restore();  
  
    // Convert chart to base64 image with white background
    const chartBase64 = canvas.toDataURL('image/png');
  
    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = chartBase64;
    link.click();
  }

  render(){
    const{data, isMounted} = this.state;
    const {largeFont } = this.props;
    if(isMounted)
      return (
            <GraphWrapper 
              title = "Marital Status by Age" 
              downloadCSV = {this.downloadCSV}  
              isMounted = {this.props.isMounted}
              downloadPng = {this.downloadPng}
            >
              <Bar ref = {this.myRef} data={data} options = {largeFont ? largeFontOptions : defaultOptions} onClick={this.props.onClick}/>
            </GraphWrapper>
      );
    else 
      return <h1>filler</h1>;
  } 
}