import React, { Component } from 'react';
import { birthdayToAge, downloadCSV } from '../../util/util.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { BsFullscreen, BsFiletypeCsv, BsFiletypePng } from "react-icons/bs";
import GraphWrapper from '../graphWrapper.js';
import { BiDotsVerticalRounded } from "react-icons/bi";
import pattern from 'patternomaly';
import { compareObjects } from '../../util/util.js';

const plugin = {

  id: "increase-legend-spacing", 
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 10;
    }
  }
};

ChartJS.register(ArcElement, Tooltip, Legend, plugin);

export const largeFontOptions = {
  responsive: true,
  maintainAspectRatio : false,
  plugins: {
    legend: {
      labels: {
          // This more specific font property overrides the global property
          font: {
              size: 18
          }
      }
    },
  },
  
  layout: {
    padding: {
      bottom: 15,
      right : 10,
      left : 10
    },
  },
};
export const defaultOptions = {
  responsive: true,
  maintainAspectRatio : false,
  plugins: {
    legend: {
      labels: {
          // This more specific font property overrides the global property
          font: {
              size: 13
          }
      }
    }
  },

  layout: {
    padding: {
      bottom: 15,
      right : 10,
      left : 10
    },
  },
};


const backgroundColor = [
  'rgb(255,177,194)',
  'rgb(155,209,245)',
  'rgb(192,192,192)'
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(128,128,128, 1)'
];
const colorBlindBackgroundColor = [
  '#e69f00',
  '#56b4e9',
  'rgb(166,166,166)'
];
const colorBlindBorderColor = [
  '#e69f00',
  '#56b4e9',
  'rgb(166,166,166)'
];

const pointStyles = ['rect', 'circle', 'cross'];
const borderWidth = 1;

export default class AgeDonut extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      isModalOpen: false
    };
    this.calculateData = this.calculateData.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);

    this.myRef = React.createRef();
  }

  componentDidMount(){
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }

  handleOpen(){
    this.setState({
      isModalOpen: true
    })
  }

  handleClose(){
    this.setState({
      isModalOpen : false
    });
  }

  downloadPng = () => {
    const canvas = this.myRef.current.canvas;
    const ctx = canvas.getContext('2d');
  
    // Save the current canvas state
    ctx.save();
  
    // Set white background
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Restore the canvas state
    ctx.restore();  
  
    // Convert chart to base64 image with white background
    const chartBase64 = canvas.toDataURL('image/png');
  
    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = chartBase64;
    link.click();
  }

  downloadCSV(){
    var genders = ['Male', 'Female', 'Unknown'];

    var data = {};

    /*data  = {
      'M' : {
        '6-18-2023' : 45,
        '7-18-2023' : 34
      },
      'F' : {
        '6-18-2023' : 45,
        '7-18-2023' : 34
      },
    }*/

    genders.forEach(gender =>{
      data[gender] = {};
      this.props.dates.forEach(date => data[gender][date] = 0);
    });

    this.props.data.forEach(individual => {
      if(individual.gender == 'M') data['Male'][individual.snapshot_date]++; 
      else if(individual.gender == 'F') data['Female'][individual.snapshot_date]++;
      else data['Unknown'][individual.snapshot_date]++;
    });

    data.date = this.props.dates.sort().map(date =>{ 
      var x = new Date(date); 
      return (x.getMonth()+1)+"-"+x.getDate()+"-"+x.getFullYear()
    });

    Object.keys(data).map(gender => {
      data[gender] = Object.keys(data[gender]).sort().map(date => data[gender][date]);
    });

    if(!this.props.showUnknown) delete data.Unknown;

    downloadCSV(data);
  }
  calculateData(){
    var genders = ['Male', 'Female', 'Unknown'];

    var data = {};

    /*data  = {
      'M' : {
        '6-18-2023' : 45,
        '7-18-2023' : 34
      },
      'F' : {
        '6-18-2023' : 45,
        '7-18-2023' : 34
      },
    }*/

    genders.forEach(gender =>{
      data[gender] = {};
      this.props.dates.forEach(date => data[gender][date] = 0);
    });

    this.props.data.forEach(individual => {
      if(individual.gender == 'M') data['Male'][individual.snapshot_date]++; 
      else if(individual.gender == 'F') data['Female'][individual.snapshot_date]++;
      else data['Unknown'][individual.snapshot_date]++;
    });

    var i = 0;
    var returnData = {
      labels: this.props.dates.sort().map(date =>{ 
        var x = new Date(date); 
        return (x.getMonth()+1)+"-"+x.getDate()+"-"+x.getFullYear()
      }),
      datasets: Object.keys(data).map(gender => {
        var dataset = {
          label: gender,
          data : Object.keys(data[gender]).sort().map(date => data[gender][date]),
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor[i] : backgroundColor[i],
          borderColor: this.props.showColorBlind ? colorBlindBorderColor[i] : borderColor[i], 
          pointStyle : this.props.showColorBlind ? pointStyles[i] : 'circle',
          pointRadius : this.props.showColorBlind ? 8 : 3
        }
        i++;
        return dataset;
      })
    };
    
    if(!this.props.showUnknown) { 
      returnData.datasets.pop();
    }

    return returnData;
  }

  render(){
    const{data, isMounted} = this.state;
    const {largeFont, simpleTitle } = this.props;
    if(isMounted)
      return (
        <GraphWrapper 
          title = "Individuals by Gender" 
          downloadCSV = {this.downloadCSV} 
          downloadPng={this.downloadPng} 
          isMounted = {this.props.isMounted} 
          style={{paddingBottom : "25px"}}>
          <Line data={data} options = {largeFont ? largeFontOptions : defaultOptions} onClick={this.props.onClick} ref={this.myRef}/>
        </GraphWrapper>
      );
    else 
      return null;
  } 
}