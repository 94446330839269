import { createContext, useState } from "react";

export const DashboardContext = createContext();

const Provider = ({ children }) => {
  const [modalState, setModalState] = useState({
    mainWidth: 85, // %
    sidebarWidth: 15, // %
    sidebarIsOpen: false // on start
  });
  return (
    <DashboardContext.Provider value={[modalState, setModalState]}>
      {children}
    </DashboardContext.Provider>
  );
};

export default Provider;