const productFeaturesPath = './product-features'
export default [
  {
    name : 'Seamless Integration',
    description : `Connect effortlessly to popular church management software like Planning Center, Church Community Builder, Fellowship1, and Breeze. Our seamless integration ensures that you can start analyzing your church's data in minutes, without any technical hurdles.`,
    imageSrc : `${productFeaturesPath}/integrations.png`
  },
  {
    name : 'Instant Insights',
    description : `With Flock Analytics, you'll gain instant access to prebuilt dashboards meticulously designed around the pillars of church health. Our intuitive interface empowers you to unlock valuable insights with just a few clicks, giving you the clarity you need to make informed decisions.`,
    imageSrc : `${productFeaturesPath}/insights.png`
  },
  {
    name : 'Track Growth Over Time',
    description : `With Flock Analytics, you're not just analyzing data, you're tracking growth. Our platform takes monthly snapshots of your database, allowing you to monitor growth trends over time and make strategic decisions based on historical data.`,
    imageSrc : `${productFeaturesPath}/growth.png`,
  }
];