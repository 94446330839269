import React, { Component } from 'react';
import {
  Stack,
  Flex,
  Box,
  Heading,
  Image,
  Center
} from '@chakra-ui/react';
import '../demo.css'
import LineDemo from '../../lineDemo/lineDemo'

export class SalvationDashboard extends Component {

  render() {
    return (
      <Flex w="100%" h="100%" alignItems="center">
        <Box pos='absolute' height={['50px','100px', '150px','200px', '350px']} left='125px' top='150px'>
          <Image
            src="./screenshot-care.png"
            objectFit='cover'
            width='100%'
            height='100%'
            borderRadius={'15px'}
            boxShadow="-20px 30px 20px rgba(0, 0, 0, 0.24)"
          />
        </Box>
        <Box pos='absolute' left='350px' top='300px'>
          <LineDemo data={[{ age: 20 }]} />
        </Box>
        <Box pos='absolute' right='100px' height={'100%'} maxW='40%'>
          <Center pos='relative' h='100%'>
            <Heading color="white">Visualize your church like never before!</Heading>
          </Center>
        </Box>
      </Flex>
    );
  }
}