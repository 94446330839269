import { Box, Heading, List, ListItem, Flex } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useRef } from "react";
import { generateRandomString, lowCollisionHash } from "../../../util/util";
import AgeRangeFilter from "./sliderFilter";
import { DashboardContext } from "./DashboardContext";
import Filters from "./filters";

const tag = generateRandomString(25);

const FilterBar = (props) => {
  const sidebarWrapRef = useRef();
  const sidebarContentRef = useRef();
  const [modalState] = useContext(DashboardContext);

  const updateSidebarContentWidth = useCallback(() => {
    if (!sidebarWrapRef.current) return;

    // get percentage from 0 to 1
    const u = modalState.sidebarWidth / 100;

    // highest width of scrollbar among browsers
    const scrollbarWidth = 18;

    const width = 
      sidebarWrapRef.current.parentElement.clientWidth * u - scrollbarWidth;

    /*if (width > 0) {
      sidebarContentRef.current.style.width = width + "px";
    }*/
  }, [modalState.sidebarWidth]);

  useEffect(() => {
    setTimeout(updateSidebarContentWidth, modalState.duration);

    window.addEventListener("resize", updateSidebarContentWidth);

    return () => {
      window.removeEventListener("resize", updateSidebarContentWidth);
    };
  }, [modalState.duration, updateSidebarContentWidth]);

  return (
    //w={modalState.sidebarIsOpen ? `${modalState.sidebarWidth}%` : "0"}
    //<Box ref={sidebarContentRef} overflow="scroll" h="100%"  overflowX={"hidden"} >
    <Box
      ref={sidebarWrapRef}
      w={modalState.sidebarIsOpen ? `450px` : "0"}
      transition={`width ${modalState.duration}s ease`}
      style={{
        overflowY: "auto",
        height : "85vh",
        backgroundColor : "white",
        scrollbarWidth : "thin"}}
      shadow={"rgba(0, 0, 0, 0.24) 3px 3px 3px"}
    >
        <Filters 
          data = {props.data} 
          updateFilter = {props.updateFilter} 
          deleteFilter = {props.deleteFilter} 
          setUnknown = {props.setUnknown} 
          showUnknown = {props.showUnknown}
        />
    </Box>
  );
};

export default FilterBar;