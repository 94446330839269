import { ReactNode, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Slider, SliderTrack, SliderFilledTrack, SliderThumb, Tooltip,
  Popover,
  PopoverAnchor,
  IconButton,
  PopoverContent,
  PopoverBody,
  PopoverTrigger
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { BiInfoCircle } from 'react-icons/bi';

import data from './data'

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}
      maxWidth = {'550px'}
      w = {'100%'}>
      {children}
    </Box>
  );
}

export default function Pricing() {
  const pricing = (numOfIndividuals) => {
    let price = 0;
    if (numOfIndividuals >= 1000) {
      price += 100;
      numOfIndividuals -= 1000;
    } else {
      price += numOfIndividuals * 0.1;
      numOfIndividuals = 0;
    }
    if (numOfIndividuals >= 2000) {
      price += 100;
      numOfIndividuals -= 2000;
    } else {
      price += numOfIndividuals * 0.05;
      numOfIndividuals = 0;
    }

    price += numOfIndividuals * 0.025;

    return price < 50 ? 50 : price;
  };

  const [numOfIndividuals, setNumOfIndividuals] = useState(1000);
  const [price, setPrice] = useState(pricing(1000));

  const handleSliderChange = (value) => {
    setNumOfIndividuals(value);
    setPrice(pricing(value));
  };

  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          {data.hook}
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          {data.hook_description}
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}
              
            >
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('brand.200', 'red.700')}
                px={3}
                py={1}
                color={useColorModeValue('white', 'gray.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl"
              >
                1 Week Trial
              </Text>
            </Box>
            <Box w = "100%" display="flex" justifyContent="center">
            <Box w="80%" mt = {'75px'}>
            <Slider
              value={numOfIndividuals}
              min={1}
              max={10000}
              step={5}
              onChange={handleSliderChange}
            >
              <SliderTrack bg="brand.100">
                <SliderFilledTrack bg="brand.500" />
              </SliderTrack>
              <Tooltip
                hasArrow
                bg="brand.500"
                color="white"
                placement="top"
                isOpen={true} // Always show the tooltip
                label={
                  <Box fontSize="lg">
                    {`${numOfIndividuals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} active accounts`}
                  </Box>
                }
              >
                <SliderThumb boxSize={6} style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: 'black' }} />
              </Tooltip>
            </Slider>
            <Text color = {'gray.500'}>{data.slider_description}</Text>
            </Box>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}
            >
              <List spacing={3} textAlign="start" px={12}>
                {data.feature_bullets.map(feature => (
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color="brand.500" />
                    {feature}
                  </ListItem>
                ))}
              </List>
              <Box py={4} px={12}>
              <HStack justifyContent="center" h = {'30px'}>
                {price == 375 ? (
                  <>
                  <Text fontSize="3xl" color="gray.500">
                    Contact Sales Team
                  </Text> 
                </>
                ) : (
                  <>
                  <Text fontSize="3xl" fontWeight="600">
                    $
                  </Text>
                  <Text fontSize="5xl" fontWeight="900">
                    {price.toFixed(2)}
                  </Text>
                  <Text fontSize="3xl" color="gray.500">
                    /month
                  </Text>
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="Info"
                        color = 'gray.500'
                        icon={<BiInfoCircle size = "sm" color='gray.500'/>}
                        variant="ghost"
                        size="sm"
                        _hover={{ color: 'brand.500' }}
                      />
                    </PopoverTrigger>
                    <PopoverContent maxW="xl">  
                      <PopoverBody>
                        <b>Pricing</b>($50 monthly minimum)<br />
                        $.10 <b>x</b> per active accounts 1-1000<br/>
                        $.05 <b>x</b> per active accounts 1001-3000<br/>
                        $.02½ <b>x</b> per active accounts 3001+<br/>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </>
                )}
              </HStack>
            </Box>
              <Box w="80%" pt={7}>
                <Button 
                  w="full" 
                  colorScheme="brand"
                  onClick={() => document.location.href = '/create-profile'}>
                  Start Trial
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
