import React, { Component } from 'react';

export default class Terms extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }


  render(){
    return(
      <div style={{ height: '100vh' }}>
        <iframe title="External Page" src="/terms.html" width="100%" height="100%" />
      </div>
    );
  }
}