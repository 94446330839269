import React, { Component } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Stack,
  HStack,
  Spacer,
  Flex,
  Icon,
  Text,
  Portal
} from "@chakra-ui/react";
import {BiChevronDown} from "react-icons/bi"
import SelectButton from './selectButton';

export default class NestedDropdown extends Component{
  constructor(props){
    super(props);
    this.state = {
      selected : ""
    };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.createDivsFromObject = this.createDivsFromObject.bind(this);
  }

  handleItemClick(info, value, keyPath){
    console.log(info);
    console.log(value);
    console.log(keyPath);
    var newValue = "";
    for(var i = 0; i < info.length; i++) newValue += info[i] + " ";
    newValue += value;
    console.log("part 1");
    this.setState({
      selected : newValue,
      selectedKey : keyPath,
      isOpen : false,
    });
    console.log("part 2");
    this.props.onUpdate(newValue, keyPath);
    console.log("part 3");
  }
  createDivsFromObject(info, keyPath, obj){
    return Object.entries(obj).map(([key, value]) => {
      if(key == "_id") return null;
      if (typeof value === 'object') {
        return (
          <Menu offset = {[150, 0]}>
            <MenuButton 
              _hover={{ bg: "gray.100" }}
              width = {"100%"}
              display = {"flex"}
              textAlign ={"start"}
              py = {"6px"}
              px = {"12px"}
            >
              {value._id}
              <Icon as={BiChevronDown}/>
            </MenuButton>
            <MenuList rootProps = {{
              transform : "translateX(120px)"
            }}>
              <Stack alignItems="flex-start">, 
                {this.createDivsFromObject([...info, value._id], [...keyPath, key], value)}
              </Stack>
            </MenuList>
          </Menu>
        );
      } else {
        return (
          <MenuItem onClick={() => this.handleItemClick(info, value, [...keyPath, key])}>
            {value}
          </MenuItem>
        );
      }
    });
  }
  render(){

    const { selected } = this.state;
    const { data, title } = this.props;
    console.log(data);
    console.log(title);
    return (
      <>
      {(data != {}) ? (
        <>
        <Text mb = {"0px"}>Select {title}</Text>
        <Menu 
          isOpen = {this.state.isOpen} 
          onOpen={() => this.setState({isOpen: true})}
          onClose={() => this.setState({isOpen : false})}
          closeOnSelect = {false}
        >
          <MenuButton 
            fontSize={"md"} 
            px = {"5px"} 
            py = {"5px"} 
            backgroundColor = {"gray.200"}
            style = {{width : "100%",}}
            onClick = {() => this.setState({isOpen : (!this.state.isOpen)})}
          >
            {selected || "Select a field"}
          </MenuButton>
          <Portal>
          <MenuList>
                <Stack alignItems="flex-start">
                  {this.createDivsFromObject([], [], data)}
                </Stack>
            </MenuList>
          </Portal>
        </Menu>
        </>
    ) : (
      null
      )}
      </>
    );
  }
}
