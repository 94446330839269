import React from "react";
export function MediumLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height = {props.height}
      version="1.1"
      viewBox="0 0 670.752 141.815"
    >
      <defs>
        <linearGradient id="linearGradient924">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient960">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient954">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient948">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient942">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <path d="M-214.885 -335.691H512.088V-82.31899999999996H-214.885z"></path>
        <linearGradient id="linearGradient912">
          <stop offset="0" stopColor="#0055d4" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <path d="M-264.583 -45.357H56.696999999999946V11.338999999999999H-264.583z"></path>
        <linearGradient
          id="linearGradient898"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
        <linearGradient
          id="linearGradient900"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient960"
        ></linearGradient>
        <linearGradient
          id="linearGradient902"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient954"
        ></linearGradient>
        <linearGradient
          id="linearGradient904"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient948"
        ></linearGradient>
        <linearGradient
          id="linearGradient906"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient942"
        ></linearGradient>
        <linearGradient
          id="linearGradient911"
          x1="-148.322"
          x2="-76.649"
          y1="-173.46"
          y2="-280.864"
          gradientTransform="translate(-137.911 -368.832)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient912"
        ></linearGradient>
        <linearGradient
          id="linearGradient940"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient942"
        ></linearGradient>
        <linearGradient
          id="linearGradient943"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient948"
        ></linearGradient>
        <linearGradient
          id="linearGradient945"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient954"
        ></linearGradient>
        <linearGradient
          id="linearGradient947"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient960"
        ></linearGradient>
        <linearGradient
          id="linearGradient949"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
      </defs>
      <g
        fontFamily="Creamson Regular"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="normal"
        transform="translate(304.087 475.128)"
      >
        <path
          d="M-264.279-432.94v7.655q0 1.845 1.292 3.136 1.383 1.292 3.228 1.292h51.006q5.166-.185 4.52 1.844v7.287q-1.66 23.243-20.938 25.273h-34.588q-1.845 0-3.228 1.291-1.292 1.291-1.292 3.136v40.492q0 1.844-1.383 3.136-1.292 1.29-3.136 1.29H-297.3q-1.937 0-3.228-1.29-1.292-1.292-1.292-3.136v-125.35q0-1.844 1.292-3.135 1.29-1.291 3.228-1.291h96.11q1.937 0 3.228 1.29 1.291 1.292 1.291 3.137v25.088q0 1.845-1.291 3.136-1.291 1.291-3.228 1.291h-58.57q-1.845 0-3.228 1.292-1.292 1.291-1.292 3.136z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient898)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontSize="184.472"
        ></path>
        <path
          d="M-73.781-358.396v20.016q.553 2.398-4.52 2.029h-94.265q-2.121 0-3.32-1.291-1.2-1.292-1.2-3.136v-125.35q0-1.844 1.2-3.135 1.199-1.292 3.32-1.292h28.317q1.844 0 3.136 1.292 1.383 1.291 1.383 3.136v91.59q0 1.845 1.2 3.136 1.29 1.291 3.32 1.291h46.118q15.127 0 15.311 11.714z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient900)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontSize="184.472"
        ></path>
        <path
          d="M-73.416-404.163q0-14.296 5.626-26.933 5.719-12.728 15.311-22.136 9.685-9.408 22.69-14.85 13.006-5.534 27.764-5.534 14.757 0 27.763 5.534 13.097 5.442 22.782 14.85 9.777 9.408 15.403 22.136 5.627 12.637 5.627 26.933 0 14.205-5.627 26.933-5.626 12.637-15.403 22.045-9.685 9.408-22.782 14.942-13.006 5.442-27.763 5.442-14.758 0-27.763-5.442-13.006-5.534-22.69-14.942-9.593-9.408-15.312-22.045-5.626-12.728-5.626-26.933zm37.448 0q0 6.918 2.582 13.19 2.675 6.18 7.287 10.976 4.612 4.704 10.792 7.564 6.272 2.859 13.282 2.859t13.19-2.86q6.271-2.859 10.975-7.563 4.797-4.796 7.38-10.976 2.674-6.272 2.674-13.19 0-6.825-2.675-13.097-2.582-6.364-7.379-10.976-4.704-4.704-10.976-7.564-6.18-2.859-13.19-2.859t-13.281 2.86q-6.18 2.859-10.792 7.563-4.612 4.612-7.287 10.976-2.582 6.272-2.582 13.097z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient902)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontSize="184.472"
        ></path>
        <path
          d="M224.443-373.448q-7.564 9.592-14.112 16.51-6.457 6.826-14.666 11.806-8.209 4.889-18.263 7.656-9.961 2.675-22.136 2.675-15.035 0-28.317-5.442-13.19-5.534-23.15-14.85-9.87-9.316-15.589-21.952-5.626-12.729-5.626-27.025 0-14.205 5.626-26.841 5.719-12.729 15.588-22.044 9.962-9.316 23.151-14.758 13.282-5.534 28.317-5.534 12.544 0 22.505 2.767 10.054 2.675 18.17 7.655 8.118 4.889 14.574 11.99 6.549 7.01 12.083 15.497.922 1.475.277 3.136-.554 1.568-2.214 2.306l-26.84 10.33q-1.845.738-3.782 0-1.845-.738-2.767-2.214-5.535-8.485-13.282-12.913-7.748-4.52-18.724-4.52-7.102 0-13.467 2.952-6.364 2.952-11.068 7.84-4.704 4.797-7.379 11.253-2.583 6.364-2.583 13.098 0 7.01 2.583 13.374 2.675 6.272 7.379 11.068 4.704 4.704 11.068 7.656 6.365 2.951 13.467 2.951 10.422 0 18.17-4.427 7.748-4.52 15.035-9.685 7.286-5.165 20.292-.553l14.02 7.102q2.582 1.476 1.66 3.136z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient904)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontSize="184.472"
        ></path>
        <path
          d="M305.847-349.559l-28.132-30.622q-1.291-1.476-2.214-1.107-.83.369-.83 2.214v40.491q0 2.214-4.52 1.476h-28.316q-2.121 0-3.32-1.291-1.2-1.292-1.2-3.136v-125.35q0-1.844 1.2-3.135 1.199-1.291 3.32-1.291h28.316q1.937 0 3.229 1.29 1.291 1.292 1.291 3.137v42.152q0 1.844.738 2.121.83.277 1.937-1.199l34.035-43.812q1.107-1.476 3.136-2.49 2.121-1.015 4.15-1.015h36.157q2.03 0 2.583 1.753.553 1.752-.554 3.228l-49.715 60.322q-1.107 1.476-1.015 3.413.093 1.845 1.476 3.136 6.365 6.825 18.17 20.384 11.807 13.559 35.973 36.433 6.733 5.903-.738 5.35H331.58q-13.836.738-25.734-12.452z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient906)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontSize="184.472"
        ></path>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="-538.844"
          y="66.863"
          fill="#04a"
          strokeWidth="4.612"
          fontSize="184.472"
        >
          <tspan
            x="-538.844"
            y="66.863"
            style={{}}
            fill="#04a"
            strokeWidth="4.612"
            fontFamily="Creamson Regular"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            Flock
          </tspan>
        </text>
        <text
          x="-603.409"
          y="-722.591"
          style={{ lineHeight: "1.25" }}
          fill="#04a"
          fillOpacity="1"
          stroke="#04a"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="3"
          fontSize="60.795"
          xmlSpace="preserve"
        >
          <tspan
            style={{ InkscapeFontSpecification: "Gayaku" }}
            x="-603.409"
            y="-722.591"
            fill="#04a"
            fillOpacity="1"
            stroke="#04a"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="3"
            fontFamily="Gayaku"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="2.772"
            wordSpacing="0"
          >
            Analytics
          </tspan>
        </text>
        <path
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient911)"
          fillOpacity="1"
          strokeWidth="4.612"
          d="M-267.616-622.61v7.656q0 1.844 1.292 3.136 1.383 1.291 3.228 1.291h51.006q5.166-.184 4.52 1.845v7.286q-1.66 23.244-20.938 25.273h-34.588q-1.845 0-3.228 1.291-1.292 1.292-1.292 3.136v40.492q0 1.845-1.383 3.136-1.291 1.291-3.136 1.291h-28.501q-1.937 0-3.228-1.291-1.292-1.291-1.292-3.136v-125.349q0-1.844 1.292-3.136 1.29-1.291 3.228-1.291h96.11q1.937 0 3.228 1.291 1.291 1.291 1.291 3.136v25.088q0 1.845-1.291 3.136-1.291 1.292-3.228 1.292h-58.57q-1.845 0-3.228 1.291-1.292 1.291-1.292 3.136z"
          fontSize="184.472"
        ></path>
        <path
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient949)"
          fillOpacity="1"
          strokeWidth="4.612"
          d="M-257.994-1193.498v7.656q0 1.845 1.291 3.136 1.384 1.291 3.229 1.291h51.006q5.165-.184 4.52 1.845v7.287q-1.66 23.243-20.938 25.272h-34.588q-1.845 0-3.229 1.292-1.291 1.29-1.291 3.136v40.491q0 1.845-1.383 3.136-1.292 1.292-3.137 1.292h-28.5q-1.937 0-3.229-1.292-1.291-1.291-1.291-3.136v-125.348q0-1.845 1.291-3.136 1.292-1.292 3.229-1.292h96.11q1.936 0 3.228 1.292 1.291 1.29 1.291 3.136v25.088q0 1.844-1.291 3.136-1.292 1.291-3.229 1.291h-58.57q-1.844 0-3.228 1.291-1.291 1.292-1.291 3.136z"
          fontSize="184.472"
        ></path>
        <path
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient947)"
          fillOpacity="1"
          strokeWidth="4.612"
          d="M-67.496-1118.953v20.015q.553 2.399-4.52 2.03h-94.265q-2.121 0-3.32-1.292-1.2-1.291-1.2-3.136v-125.349q0-1.844 1.2-3.136 1.199-1.29 3.32-1.29h28.316q1.845 0 3.136 1.29 1.384 1.292 1.384 3.136v91.59q0 1.846 1.2 3.137 1.29 1.291 3.32 1.291h46.118q15.126 0 15.31 11.714z"
          fontSize="184.472"
        ></path>
        <path
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient945)"
          fillOpacity="1"
          strokeWidth="4.612"
          d="M-67.131-1164.72q0-14.297 5.626-26.933 5.719-12.729 15.311-22.137 9.685-9.408 22.69-14.85 13.006-5.534 27.763-5.534 14.758 0 27.763 5.534 13.098 5.442 22.783 14.85 9.777 9.408 15.403 22.137 5.626 12.636 5.626 26.933 0 14.204-5.626 26.933-5.626 12.636-15.403 22.044-9.685 9.408-22.783 14.942-13.005 5.442-27.763 5.442-14.757 0-27.763-5.442-13.005-5.534-22.69-14.942-9.592-9.408-15.311-22.044-5.626-12.729-5.626-26.933zm37.447 0q0 6.918 2.583 13.19 2.675 6.18 7.287 10.976 4.612 4.704 10.791 7.563 6.272 2.86 13.282 2.86t13.19-2.86q6.272-2.86 10.976-7.563 4.796-4.796 7.379-10.976 2.675-6.272 2.675-13.19 0-6.825-2.675-13.098-2.583-6.364-7.379-10.976-4.704-4.704-10.976-7.563-6.18-2.86-13.19-2.86t-13.282 2.86q-6.18 2.86-10.791 7.563-4.612 4.612-7.287 10.976-2.583 6.272-2.583 13.098z"
          fontSize="184.472"
        ></path>
        <path
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient943)"
          fillOpacity="1"
          strokeWidth="4.612"
          d="M230.728-1134.005q-7.564 9.592-14.113 16.51-6.456 6.825-14.665 11.806-8.21 4.888-18.263 7.656-9.961 2.674-22.136 2.674-15.035 0-28.317-5.442-13.19-5.534-23.151-14.85-9.87-9.315-15.588-21.952-5.626-12.728-5.626-27.025 0-14.204 5.626-26.84 5.719-12.729 15.588-22.045 9.961-9.316 23.151-14.758 13.282-5.534 28.317-5.534 12.544 0 22.505 2.767 10.054 2.675 18.17 7.656 8.117 4.888 14.574 11.99 6.549 7.01 12.083 15.496.922 1.476.277 3.136-.554 1.568-2.214 2.306l-26.84 10.33q-1.846.739-3.783 0-1.844-.737-2.767-2.213-5.534-8.486-13.282-12.913-7.747-4.52-18.723-4.52-7.103 0-13.467 2.952-6.364 2.951-11.068 7.84-4.704 4.796-7.38 11.253-2.582 6.364-2.582 13.097 0 7.01 2.583 13.374 2.675 6.273 7.379 11.069 4.704 4.704 11.068 7.655 6.364 2.952 13.467 2.952 10.422 0 18.17-4.427 7.748-4.52 15.034-9.685 7.287-5.165 20.292-.554l14.02 7.103q2.583 1.475 1.66 3.136z"
          fontSize="184.472"
        ></path>
        <path
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient940)"
          fillOpacity="1"
          strokeWidth="4.612"
          d="M312.132-1110.116L284-1140.74q-1.292-1.475-2.214-1.106-.83.369-.83 2.213v40.492q0 2.214-4.52 1.476H248.12q-2.122 0-3.32-1.292-1.2-1.291-1.2-3.136v-125.348q0-1.845 1.2-3.136 1.198-1.292 3.32-1.292h28.316q1.937 0 3.228 1.291 1.292 1.292 1.292 3.137v42.151q0 1.845.738 2.122.83.277 1.937-1.2l34.035-43.811q1.107-1.476 3.136-2.49 2.121-1.015 4.15-1.015h36.157q2.029 0 2.582 1.752.554 1.753-.553 3.228l-49.715 60.323q-1.107 1.476-1.015 3.413.092 1.844 1.476 3.136 6.364 6.825 18.17 20.384 11.807 13.558 35.973 36.433 6.733 5.903-.738 5.35h-29.424q-13.835.738-25.733-12.452z"
          fontSize="184.472"
        ></path>
        <text
          x="90.084"
          y="-1046.266"
          style={{ lineHeight: "1.25" }}
          fill="#04a"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="3"
          fontSize="60.795"
          xmlSpace="preserve"
        >
          <tspan
            style={{ InkscapeFontSpecification: "Gayaku" }}
            x="90.084"
            y="-1046.266"
            fill="#5fd"
            stroke="#87decd"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="3"
            fontFamily="Gayaku"
            fontStretch="normal"


            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="2.772"
            wordSpacing="0"
          >
            Analytics
          </tspan>
        </text>
      </g>
    </svg>
  );
}