import React, { Component } from 'react';
import ApiSelector from './apiSelector';
import CcbRegistration from './ccb_registration';
import ReviewRegistration from './reviewRegistration'
import ChurchName from './churchName';
import PcRegistration from './pc_registration';

export default class Registration extends Component {
  constructor(props){
    super(props);
    this.state = {
      churchName: "",
      api_type : "",
      ccb_data : {},
      pw_data : {},
      loading : false,
      individuals : 0,
      members : 0,
      complete : false,
      price : 0
    };
    this.ccbRegistration = this.ccbRegistration.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const paramObj = {};
    
    for (const [key, value] of params) paramObj[key] = value;

    if(paramObj.hasOwnProperty('api-type')){
      if(paramObj['api-type'] == 'pc') this.setState({api_type : 'pc'})
      else if(paramObj['api-type'] == 'ccb') this.setState({api_type : 'ccb'})
    }
  }

  async ccbRegistration(data) {
    data.api_type = this.state.api_type;
    this.setState({ccb_data : data, loading : true});
    try {
      const response = await fetch(`/api/church/test-n-set`, {
        credentials: "include", 
        method: "POST",   
        headers: {
          'Content-Type': 'application/json'
        }, 
        body: JSON.stringify(data)
      });
      if(response.ok){
        window.location.href = '/pricing';
      } else if(response.status == 401){
        this.setState({loading : false});
        return "Invalid Credentials please check your username, password, and URL";
      } else {
        this.setState({loading : false});
        return "Something went wrong please try again"
      }
    } catch(err) {
      console.log(err);
    }
  }

  render(){
    const { api_type, loading } = this.state;
    return(
      <>
        {!api_type ? (
          <ApiSelector selectApi = {(x) => this.setState({api_type: x})}/> 
        ) : (
          api_type == "ccb" ? (
            <CcbRegistration register = {this.ccbRegistration} isLoading = {loading} removeApi = {()=> this.setState({api_type : false})}/>
          ) : (
            <PcRegistration register = {this.ccbRegistration} isLoading = {loading} removeApi = {()=> this.setState({api_type : false})}/>
          )
        )}
      </>
    );
  }
}