import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,

} from '@chakra-ui/react';
import React, { Component } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

export default class GenerateSsoToken extends Component {
  constructor(props){
      super(props);
      this.state = {
        error : false,
        loading : false
      };
  }
  generateSso = async (e) =>{
    e.preventDefault();
    this.setState({loading : true, error : false}, async () =>{
      fetch(`/api/auth/generate-sso`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify({
          "email": e.target.email.value,
        })
      })
      .then((res) => {
          if(res.status == 200) {
            this.props.setSsoEmail(e.target.email.value);
          } else {
            this.setState({loading : false, error : true})
          }
      });
    });
  }

  render(){
    const {error, loading} = this.state;
    return (
      <>
        <form onSubmit={this.generateSso}>  
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input type="email" onChange={() => this.setState({error : false})}/>
        </FormControl>
        <Button
            mt={3}
            colorScheme="brand"
            isLoading = {loading}
            type="submit">
            Submit
          </Button>
        </form>
        {error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Reset Password Failed</AlertTitle>
          <AlertDescription>There is no account associated with this email address </AlertDescription>
        </Alert>
        ) : null }
      </>       
    );
    
  }
};
