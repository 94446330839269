import Auth from '../components/auth.js';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import '../App.css';

import {Component} from 'react';

import Footer from '../components/footer.js';
import Registration from '../registration.js';
import NavBar from '../navBar'

export default class RegistrationPage extends Component{
  constructor(props){
    super(props);
    this.state = {
      isLoggedIn : false,
      isMounted : false
    };
  }


  render(){
    return (
      <Auth allowedStatus = {[406, 407]} blockedStatus = {{'/login' : [0]}} defaultRedirect = {'/home'}>
        <>
          <div className="App" >
            <NavBar />
            <Registration />
          </div>
          <Footer />
        </>
      </Auth>
    );
  }
};


