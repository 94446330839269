import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,

} from '@chakra-ui/react';
import React, { Component } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

export default class LoginPage extends Component {
  constructor(props){
      super(props);
      this.state = {
        isLoggedIn : false,
        error : false,
        loading : false
      };
  }
  
  login = (e)=>{
    e.preventDefault();
    this.setState({loading : true, error : false}, () => {
      //console.log(`${API_URL}/api/auth/signin`);
      fetch(`/api/auth/updatepassword`, {
        method : "POST",
        headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            "email" : this.props.email,
            "code": this.props.code,
            "newPassword": e.target.password.value
          })
      })
      .then((res) => {
          if(res.status == 200) {
            window.location.replace("/login")
          } else {
            this.setState({error : true, loading : false})
          }
      })
    });
  }

  ssoLogin = async(e) =>{
    e.preventDefault();
    alert({
      "code" : this.props.code,
      "email" : this.props.email,
      "password" : e.target.password.value,
      "confirm-password" : e.target.confirm_password.value
    })
    this.setState({loading : true, error : false}, () => {
      //console.log(`${API_URL}/api/auth/signin`);
      fetch(`/api/auth/reset-password`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify({
          "code" : this.props.code,
          "email" : this.props.email,
          "password" : e.target.password.value,
          "confirm-password" : e.target.confirm_password.value
        })
      })
      .then((res) => {
          if(res.status == 200) {
            this.props.setIsLoggedIn(true);
          } else {
            this.setState({error : true, loading : false})
          }
      });
    });
  }

  clearError = () => {
    this.setState({error : false});
  }

  render(){
    const {loading, error} = this.state;
    return (
      <>
        <form onSubmit={this.login}>  
          <FormControl id="password">
            <FormLabel>New Password</FormLabel>
            <Input type="password" onChange={this.clearError}/>
          </FormControl>
          <FormControl id="confirm_password">
            <FormLabel>Confirm Password</FormLabel>
            <Input type="password" onChange={this.clearError}/>
          </FormControl>
          <Button
            mt={"15px"}
            colorScheme="brand"
            type="submit"
            isLoading = {loading}>
            Confirm Password
          </Button>
        </form>
        {error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Reset Password Failed</AlertTitle>
          <AlertDescription>Your passwords do not match</AlertDescription>
        </Alert>
        ) : null }
    </>
    );
  }
};