import '../App.css';
import Footer from '../components/footer.js';
import React from 'react';
import NavBar from '../navBar.js';
import Banner from '../components/landingPage/test/test.js';
import Demo from '../components/landingPage/demo/demo.js'
import SeenIn from '../components/landingPage/seenIn/featured.js'
import Hero from '../components/landingPage/hero/hero.js'
import ProductFeatures from '../components/landingPage/productFeatures/productFeatures.js';
import Integrations from '../components/landingPage/integrations/integrations.js';
import Pricing from '../components/landingPage/pricing/pricing.js';

function App() {
  return (
    < >
      <NavBar />
      <Hero />
      <ProductFeatures />
      <SeenIn />
      <Pricing />
      <Footer/>
    </>
  );
}

export default App;
