import React, { Component } from 'react';
import {Heading, Text, Container, HStack } from '@chakra-ui/react'
import { compareObjects } from '../../util/util';

const backgroundColor = [
  'rgba(255, 99, 132, 0.3)',
  'rgba(54, 162, 235, 0.3)',
  'rgba(255, 206, 86, 0.3)',
  'rgba(75, 192, 192, 0.3)',
  'rgba(153, 102, 255, 0.3)',
  'rgba(255, 159, 64, 0.3)',
  'rgba(128, 128, 0, 0.3)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(128, 128, 0, 1)',
];
const borderWidth = 1;

export default class MarriedStat extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      hash : null,
      loaded : false
    };
    this.calculateData = this.calculateData.bind(this);
  }
  componentDidMount(){
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }
  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }
  calculateData(){
    var data = 0
    var nullCnt = 0;
    this.props.data.forEach(individual => {
        if(individual.baptized == true) data++;
        else if(individual.baptized == null) nullCnt++;
    });
    var unknownMin = (data / this.props.data.length)*100;
    unknownMin = unknownMin.toFixed(2);

    var unknownMax = ((data + nullCnt) / this.props.data.length)*100;
    unknownMax = unknownMax.toFixed(2);
    
    var knownVal = (data / (this.props.data.length - nullCnt))*100;
    knownVal = knownVal.toFixed(2);

    return {unknownMin : unknownMin, unknownMax : unknownMax, knownVal : knownVal};
  }

  render(){
    const{data, isMounted} = this.state;
    const {showUnknown} = this.props;
      if(isMounted)
      return (
        <Container px = {"10px"}>
          <Text fontSize="3xl" mb="0" mt="15px" color = "#666">Baptized</Text>
          <HStack>
            <Heading fontSize = {{ base: '2xl', md: '2xl', lg: '4xl' }} color = "#666">
              {showUnknown ? (
                <>{data.unknownMin == data.unknownMax ? data.unknownMin : `${data.unknownMin}-${data.unknownMax}` }</>
                ) : data.knownVal}%
            </Heading>
          </HStack>
        </Container>
      );
    else 
      return null;
  } 
}