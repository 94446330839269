import React, { Component } from 'react';
import { Text, List, ListItem, Heading, Flex, Menu, MenuItem, MenuButton, MenuList, MenuItemOption, MenuDivider, MenuOptionGroup, Button, Divider } from '@chakra-ui/react';
import { generateRandomString } from '../../../util/util';
import NewFilterConstructor from './newFilterConstructor';
import NewFilterButton from './newFilterButton';
import DropDown from './dropDown';
import SliderFilter from './sliderFilter';
import SelectFilter from './selectorFilter';
import CheckboxFilter from './checkboxFilter';
import UnknownSwitch from './unknownSwitch';

const filterMapping = [
  SliderFilter,
  SelectFilter,
  CheckboxFilter
];

const step = 1;

/*filters =
  [
    {
      component : ComponentName,
      tag : "asdfadsf",
      name : "age"
    },
  ];*/

export default class Filters extends Component {
  constructor(props){
    super(props);
      this.state = {
      data : {},
      filters: [],
      newFilterConstructor : false
    }
    this.addFilter = this.addFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.generateTag = this.generateTag.bind(this);
    this.deleteFilterConstructor = this.deleteFilterConstructor.bind(this);
  }
  generateTag(){
    var tag = generateRandomString(25);
    const {filters} = this.state;
    var loop = true;

    while(loop){
      loop = false;
      for(const filter in filters){
        if(filters.tag == tag) {
          tag = generateRandomString(25);
          loop = true;
          break;
        }
      }
    }
    return tag;
  }
  addFilter(filter, fieldName, fieldKeyPath){
    const tag = this.generateTag();
    var newFilters = this.state.filters;
    newFilters.push({
      component : filter,
      name : fieldName,
      keyPath : fieldKeyPath,
      tag : tag
    })
    this.setState({
      filters : newFilters,
      newFilterConstructor : false
    });
  }

  deleteFilterConstructor(){
    this.setState({newFilterConstructor : false})
  }

  deleteFilter(tag){
    var newFilters = this.state.filters;
    for(var i = 0; i < newFilters.length; i++) 
      if(newFilters[i].tag == tag) {
        newFilters.splice(i, 1);
        this.setState({filters : newFilters})
        break;
      }
    this.props.deleteFilter(tag);
  }

  render(){
    const {filters, newFilterConstructor} = this.state;
    const {updateFilter, data} = this.props;
    return(
      <>
        <Heading 
          fontSize = "5xl" 
          textAlign="center"
          color = "#666"
        >
          Filters
        </Heading>
        <UnknownSwitch isChecked = {this.props.showUnknown} setUnknown = {this.props.setUnknown}/>
        <Divider orientation='horizontal' />
        <List spacing={3} p={5}>
          {filters.map((element, i) => {
            const Component= filterMapping[element.component];
            return (
              <>
              <ListItem>
                <Component 
                  updateFilter = {updateFilter} 
                  tag = {element.tag} 
                  name = {element.name} 
                  keyPath = {element.keyPath}
                  dataPath = {element.dataPath}
                  showUnknown = {this.props.showUnknown}
                  deleteFilter = {this.deleteFilter}
                  data = {data}/>
              </ListItem>
              <Divider orientation='horizontal' />
              </>
            );
          })}

          {newFilterConstructor  ? (
            <NewFilterConstructor data = {data} onUpdate = {this.addFilter} deleteFilterConstructor = {this.deleteFilterConstructor}/>
          ) : (      
            <Flex justifyContent="center">
              <NewFilterButton onClick = {() => this.setState({newFilterConstructor : true})}/>
            </Flex>
          )}
        </List>
      </>
    );
  }
};