import React from "react";

export function LargeLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={props.height}
      version="1.1"
      viewBox="0 0 670.902 211.537"
    >
      <defs>
        <linearGradient id="linearGradient924">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient960">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient954">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient948">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient id="linearGradient942">
          <stop offset="0" stopColor="#0055d4" stopOpacity="0.99"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <path d="M-214.885 -335.691H512.088V-82.31899999999996H-214.885z"></path>
        <linearGradient id="linearGradient912">
          <stop offset="0" stopColor="#0055d4" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#87decd" stopOpacity="1"></stop>
        </linearGradient>
        <path d="M-264.583 -45.357H56.696999999999946V11.338999999999999H-264.583z"></path>
        <linearGradient
          id="linearGradient898"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
        <linearGradient
          id="linearGradient900"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient960"
        ></linearGradient>
        <linearGradient
          id="linearGradient902"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient954"
        ></linearGradient>
        <linearGradient
          id="linearGradient904"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient948"
        ></linearGradient>
        <linearGradient
          id="linearGradient906"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-134.574 -179.162)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient942"
        ></linearGradient>
        <linearGradient
          id="linearGradient911"
          x1="-148.322"
          x2="-76.649"
          y1="-173.46"
          y2="-280.864"
          gradientTransform="translate(-137.911 -368.832)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient912"
        ></linearGradient>
        <linearGradient
          id="linearGradient940"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient942"
        ></linearGradient>
        <linearGradient
          id="linearGradient943"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient948"
        ></linearGradient>
        <linearGradient
          id="linearGradient945"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient954"
        ></linearGradient>
        <linearGradient
          id="linearGradient947"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient960"
        ></linearGradient>
        <linearGradient
          id="linearGradient949"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(-128.29 -939.72)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
        <linearGradient
          id="linearGradient949-2"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(703.4 -754.75)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
        <linearGradient
          id="linearGradient2633"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(703.4 -754.75)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
        <linearGradient
          id="linearGradient2635"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(703.4 -754.75)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
        <linearGradient
          id="linearGradient2637"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(703.4 -754.75)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
        <linearGradient
          id="linearGradient2639"
          x1="-101.283"
          x2="360.604"
          y1="-229.052"
          y2="-225.272"
          gradientTransform="translate(703.4 -754.75)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient924"
        ></linearGradient>
      </defs>
      <g
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        transform="translate(-534.247 1048.403)"
      >
        <g
          transform="translate(1.909 -.802)"
          style={{ lineHeight: "1.25" }}
          fill="#5fd"
          stroke="#87decd"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="3"
          ariaLabel="Analytics"
          fontFamily="Gayaku"
          fontSize="60.795"
          fontWeight="bold"
          letterSpacing="2.772"
          wordSpacing="0"
        >
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M955.248-860.495h1.641l-17.266-42.922h-.486l-17.266 42.922h1.642l4.68-11.612h22.373zm-26.507-13.071l10.64-26.385 10.638 26.385z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M968.816-886.273v-8.328h-1.52v34.106h1.52v-19.941c0-8.268 6.748-12.646 13.07-12.646 5.776 0 11.248 3.587 11.248 11.187v21.4h1.52v-21.4c0-8.633-6.14-12.706-12.585-12.706-5.35 0-10.943 2.857-13.253 8.328z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M1031.068-867.73v-17.63c0-6.506-5.107-9.363-10.822-9.363-5.046 0-10.517 2.25-13.496 6.14l1.094.79c2.614-3.465 7.66-5.47 12.28-5.47 4.986 0 9.424 2.37 9.424 7.902v6.627c-1.885-.304-4.195-.486-6.688-.486-8.085 0-17.63 2.128-17.63 10.335 0 5.41 4.985 8.45 10.578 8.45 5.29 0 11.187-2.735 13.74-8.693v1.398c0 4.499.79 7.235 5.958 7.235v-1.52c-4.256 0-4.438-2.128-4.438-5.715zm-1.52-5.593c-1.155 7.903-7.721 11.368-13.557 11.368-4.925 0-9.241-2.492-9.241-6.93 0-7.052 8.693-8.815 16.05-8.815 2.553 0 4.924.182 6.748.486z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M1047.978-860.495v-50.947h-1.52v50.947z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M1090.448-894.601l-15.441 34.227c-.122-1.641-.73-3.344-1.703-5.532l-13.253-28.695h-1.641l13.496 29.303c2.006 4.316 2.371 6.444.365 10.943l-7.235 15.989h1.702l25.352-56.235z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M1115.9-864.082c-1.885 1.276-4.073 2.067-5.897 2.067-2.675 0-4.742-1.581-4.742-5.776v-25.473h8.268v-1.337h-8.268v-8.45h-1.46v6.626c0 1.398-.242 1.824-1.702 1.824h-2.249v1.337h3.952v25.473c0 5.168 2.735 7.296 6.14 7.296 2.189 0 4.62-.852 6.809-2.31z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M1126.096-903.477c-2.37 0-2.37 3.526 0 3.526 2.432 0 2.31-3.526 0-3.526zm.79 42.982v-34.106h-1.52v34.106z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M1154.085-861.833c-7.417 0-13.8-5.228-15.32-12.463-1.46-7.295 2.31-14.59 9.119-17.509a15.07 15.07 0 016.262-1.337c4.924 0 9.788 2.431 12.767 6.687l1.216-.851c-3.283-4.681-8.512-7.295-14.044-7.295-9.362 0-17.144 7.599-17.144 17.144 0 9.545 7.782 17.083 17.144 17.083 5.532 0 10.76-2.614 14.044-7.295l-1.216-.851a15.63 15.63 0 01-12.828 6.687z"
          ></path>
          <path
            style={{ InkscapeFontSpecification: "Gayaku" }}
            d="M1179.493-886.698c0-4.317 4.499-6.566 9.119-6.566 3.83 0 7.72 1.58 8.937 4.803l1.459-.547c-1.46-3.891-5.958-5.776-10.396-5.776-5.35 0-10.64 2.736-10.64 8.086 0 12.463 21.158 6.323 21.158 17.083 0 5.107-5.35 7.6-10.761 7.6-4.56 0-9.18-1.763-10.518-5.229l-1.398.547c1.58 4.074 6.748 6.202 11.916 6.202 6.14 0 12.28-2.98 12.28-9.12 0-12.341-21.156-6.2-21.156-17.083z"
          ></path>
        </g>
        
        
        <path
          d="M573.696-1008.529v7.656q0 1.845 1.291 3.136 1.384 1.291 3.228 1.291h51.007q5.165-.184 4.52 1.845v7.287q-1.66 23.243-20.938 25.272h-34.589q-1.844 0-3.228 1.292-1.291 1.291-1.291 3.136v40.491q0 1.845-1.384 3.136-1.291 1.292-3.136 1.292h-28.5q-1.938 0-3.229-1.292-1.291-1.29-1.291-3.136v-125.348q0-1.845 1.291-3.136 1.291-1.292 3.228-1.292h96.11q1.937 0 3.228 1.292 1.292 1.29 1.292 3.136v25.088q0 1.844-1.292 3.136-1.29 1.291-3.228 1.291h-58.57q-1.844 0-3.228 1.291-1.291 1.292-1.291 3.136z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient949-2)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontFamily="Creamson Regular"
          fontSize="184.472"
          fontWeight="normal"
        ></path>
        <path
          d="M764.193-933.984v20.015q.554 2.399-4.52 2.03H665.41q-2.122 0-3.32-1.292-1.2-1.291-1.2-3.136v-125.348q0-1.845 1.2-3.137 1.198-1.29 3.32-1.29h28.316q1.845 0 3.136 1.29 1.384 1.292 1.384 3.137v91.59q0 1.845 1.199 3.136 1.291 1.291 3.32 1.291h46.118q15.127 0 15.311 11.714z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient2639)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontFamily="Creamson Regular"
          fontSize="184.472"
          fontWeight="normal"
        ></path>
        <path
          d="M764.558-979.75q0-14.298 5.627-26.934 5.718-12.728 15.31-22.136 9.686-9.408 22.691-14.85 13.005-5.535 27.763-5.535 14.758 0 27.763 5.535 13.098 5.442 22.782 14.85 9.777 9.408 15.404 22.136 5.626 12.636 5.626 26.933 0 14.204-5.626 26.933-5.627 12.636-15.404 22.044-9.684 9.408-22.782 14.943-13.005 5.442-27.763 5.442-14.758 0-27.763-5.442-13.005-5.535-22.69-14.943-9.593-9.408-15.311-22.044-5.627-12.729-5.627-26.933zm37.448 0q0 6.917 2.583 13.189 2.675 6.18 7.286 10.976 4.612 4.704 10.792 7.563 6.272 2.86 13.282 2.86t13.19-2.86q6.272-2.859 10.976-7.563 4.796-4.796 7.379-10.976 2.675-6.272 2.675-13.19 0-6.825-2.675-13.097-2.583-6.365-7.38-10.976-4.703-4.704-10.975-7.564-6.18-2.86-13.19-2.86t-13.282 2.86q-6.18 2.86-10.792 7.564-4.611 4.611-7.286 10.976-2.583 6.272-2.583 13.097z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient2637)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontFamily="Creamson Regular"
          fontSize="184.472"
          fontWeight="normal"
        ></path>
        <path
          d="M1062.417-949.036q-7.563 9.592-14.112 16.51-6.457 6.825-14.666 11.806-8.208 4.889-18.262 7.656-9.962 2.675-22.137 2.675-15.034 0-28.316-5.442-13.19-5.535-23.151-14.85-9.87-9.316-15.588-21.953-5.627-12.728-5.627-27.025 0-14.204 5.627-26.84 5.718-12.729 15.588-22.045 9.961-9.316 23.15-14.758 13.283-5.534 28.317-5.534 12.544 0 22.506 2.767 10.053 2.675 18.17 7.656 8.117 4.888 14.573 11.99 6.55 7.01 12.083 15.496.923 1.476.277 3.136-.553 1.568-2.214 2.306l-26.84 10.33q-1.845.739-3.782 0-1.845-.737-2.767-2.213-5.534-8.486-13.282-12.913-7.748-4.52-18.724-4.52-7.102 0-13.466 2.952-6.365 2.951-11.069 7.84-4.704 4.796-7.378 11.253-2.583 6.364-2.583 13.097 0 7.01 2.583 13.374 2.674 6.273 7.378 11.069 4.704 4.704 11.069 7.655 6.364 2.952 13.466 2.952 10.423 0 18.17-4.427 7.749-4.52 15.035-9.685 7.287-5.165 20.292-.554l14.02 7.103q2.582 1.475 1.66 3.136z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient2635)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontFamily="Creamson Regular"
          fontSize="184.472"
          fontWeight="normal"
        ></path>
        <path
          d="M1143.821-925.147l-28.132-30.623q-1.291-1.475-2.214-1.106-.83.369-.83 2.213v40.492q0 2.214-4.52 1.476h-28.316q-2.121 0-3.32-1.292-1.2-1.29-1.2-3.136v-125.348q0-1.845 1.2-3.136 1.199-1.292 3.32-1.292h28.317q1.937 0 3.228 1.292 1.291 1.29 1.291 3.136v42.152q0 1.844.738 2.12.83.278 1.937-1.198l34.035-43.812q1.107-1.476 3.136-2.49 2.122-1.015 4.151-1.015h36.157q2.029 0 2.582 1.752.553 1.753-.553 3.229l-49.715 60.322q-1.107 1.476-1.015 3.413.092 1.844 1.476 3.136 6.364 6.825 18.17 20.384 11.806 13.558 35.972 36.433 6.733 5.903-.738 5.35h-29.423q-13.835.738-25.734-12.452z"
          style={{ lineHeight: "1.25" }}
          fill="url(#linearGradient2633)"
          fillOpacity="1"
          strokeWidth="4.612"
          fontFamily="Creamson Regular"
          fontSize="184.472"
          fontWeight="normal"
        ></path>
      </g>
    </svg>
  );
}