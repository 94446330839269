import React, {Component} from "react";
import { Box, IconButton, Heading } from "@chakra-ui/react";
import { BiX } from "react-icons/bi";
import Fuse from 'fuse.js';
import { CUIAutoComplete } from "chakra-ui-autocomplete";

const countries = [
  { value: "ghana", label: "Ghana" },
  { value: "nigeria", label: "Nigeria" },
  { value: "kenya", label: "Kenya" },
  { value: "southAfrica", label: "South Africa" },
  { value: "unitedStates", label: "United States" },
  { value: "canada", label: "Canada" },
  { value: "germany", label: "Germany" },
];

function generateArray(num) {
  let arr = [];
  for(let i = 1; i <= num; i++) {
    arr.push({
      value: 'key_' + i,
      label: 'value_' + i
    });
  }
  return arr;
}

export default class SelectFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickerItems: countries,
      selectedItems: [],
      filter : null
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectedItemsChange = this.handleSelectedItemsChange.bind(this);
  }
  componentDidMount(){
    const {data, keyPath} = this.props;
    var values = this.props.data.data;

    var pickerItems;
      for(var i = 0; i < keyPath.length; i++) values = values[keyPath[i]];
      if(values.length >= 1 && typeof values[0] === 'object' && values[0] !== null) {
        pickerItems = values.map(value =>{
          return {value : value.ccb_id, label : value.name, key : value.ccb_id};
        });
      }else {
        pickerItems = values.map(value =>{
          return {value : value, label : value, key : value};
        });
      }
      var filter = new Fuse(pickerItems, {keys : ['value']});
      //var pickerItems = generateArray(2600);
     // console.log("hellll: " + pickerItems.length)
      this.setState({pickerItems, filter});
  }

  handleSelectedItemsChange(selectedItems) {
    const path = JSON.stringify(this.props.keyPath)
    const items = JSON.stringify(selectedItems);
    console.log("this is it:" + selectedItems.length);
    for(var i = 0; i < selectedItems.length; i++){
      for (const key in selectedItems[i]) {
        console.log(`${key}: ${selectedItems[i][key]}`);
      }
    }
    if (selectedItems) {
      this.setState({ selectedItems });
    }
    const filter = eval(`( () => {
      return function(x, showUnknown) {
        var keyPath = ${path};
        var selectedItems = ${items};
        var value = x;
        for(var i = 0; i < keyPath.length; i++) value = value[keyPath[i]];
        var isArray = Array.isArray(value);
        for(var i = 0; i < selectedItems.length; i++){
          if(isArray){
            console.log("I NEED THIS");
            for(var j = 0; j < value.length; j++) if(selectedItems[i].value == value[j] || (showUnknown && value[j] == null)) return true;
          }else{
            console.log("I NEED THIS");
            if(selectedItems[i].value == value || (showUnknown && value == null)) return true;
          }
        }
        return false;
      };
    })()`);
    console.log("here we go: " + this.props.showUnknown);
    this.props.updateFilter({
      tag : this.props.tag,
      filterCond: filter
    });
  }

  handleClose(){
    this.props.deleteFilter(this.props.tag);
  } 

  render() {
    const { pickerItems, selectedItems } = this.state;

    return (
      <Box pos = {"relative"} borderColor={"black"}>
        <IconButton 
          icon={<BiX size = {20}/>} 
          position="absolute" 
          top="0" 
          right="0" 
          onClick={this.handleClose}
          variant = "link"
        />
        <Heading 
          fontSize={"2xl"} 
          color = "#666" 
          pb = {"0"}
          pt = {"20px"}
          pl = {"5px"}
          mb = {"-10px"}
          lineHeight = {"1.25"}
        >
          {this.props.name}
        </Heading>
          <CUIAutoComplete
            label=""
            placeholder={`Enter ${this.props.name}`}
            items={pickerItems}
            disableCreateItem = {true}
            selectedItems={selectedItems}
            hideToggleButton = {true}
            listStyleProps = {{pl : "0"}}
            onSelectedItemsChange={(changes) =>
              this.handleSelectedItemsChange(changes.selectedItems)
            }
          />
        </Box>
    );
  }
}
