import React, { Component } from 'react';
//import { birthdayToAge, downloadCSV } from '../../util/util.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import GraphWrapper from '../../graphWrapper.js';
import { BiDotsVerticalRounded } from "react-icons/bi";
import pattern from 'patternomaly';

const plugin = {

  id: "increase-legend-spacing", 
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 10;
    }
  }
};

ChartJS.register(ArcElement, Tooltip, Legend, plugin);

export const largeFontOptions = {
  responsive: true,
  maintainAspectRatio : false,
  plugins: {
    legend: {
        labels: {
            // This more specific font property overrides the global property
            font: {
                size: 18
            }
        }
    },
  },
  layout: {
    padding: {
      bottom: 15,
      right : 10,
      left : 10
    },
  },
};
export const defaultOptions = {
  responsive: true,
  maintainAspectRatio : false,
  plugins: {
    legend: {
        labels: {
            // This more specific font property overrides the global property
            font: {
                size: 13
            }
        }
    }
  },
  layout: {
    padding: {
      bottom: 15,
      right : 10,
      left : 10
    },
  },
};


const backgroundColor = [
  'rgb(255,177,194)',
  'rgb(155,209,245)',
  'rgb(255,231,171)',
  'rgb(165,224,224)',
  'rgb(204,179,255)',
  'rgb(255,207,160)',
  'rgb(192,192,128)',
  'rgb(192,192,192)'
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(128, 128, 0, 1)',
  'rgba(128,128,128, 1)'
];
const colorBlindBackgroundColor = [
  pattern.draw('square','#e69f00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('diamond', '#009e73'),
  pattern.draw('zigzag', '#f0e442'),
  pattern.draw('dash', '#0072b2'),
  pattern.draw('cross', '#d55e00'),
  pattern.draw('box','#cc79a7'),
  pattern.draw('plus','rgb(166,166,166)')
];
const colorBlindBorderColor = [
  pattern.draw('square','#e69f00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('diamond', '#009e73'),
  pattern.draw('zigzag', '#f0e442'),
  pattern.draw('dash', '#0072b2'),
  pattern.draw('cross', '#d55e00'),
  pattern.draw('box','#cc79a7'),
  pattern.draw('plus','rgb(166,166,166)')
];
const borderWidth = 1;

export default class AgeDonut extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      isModalOpen: false
    };
    this.calculateData = this.calculateData.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);

    this.myRef = React.createRef();
  }

  componentDidMount(){
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }

  handleOpen(){
    this.setState({
      isModalOpen: true
    })
  }

  handleClose(){
    this.setState({
      isModalOpen : false
    });
  }

  downloadCSV(){
  }

  calculateData(){
    var ageRanges = [[0,17], [18,24], [25,34], [35,44], [45,54], [54,65], [66,100000],];
    var data = new Array(ageRanges.length+1).fill(0);

    this.props.data.forEach(individual => {
      if(individual.age == null){ 
        data[data.length-1]++;
      } else {
        for(var i = 0; i < ageRanges.length; i++){
          if(individual.age <= ageRanges[i][1]){ data[i]++; break; }
        }
      }
    });

    var returnData = {
      labels: ['0-17','18-24', '25-34', '35-44', '45-54', '54-65', '66+', 'Unknown'],
      datasets: [
        {
          label: 'Age',
          data: [24, 38, 30, 32, 20, 29, 33],
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor.slice(0, data.length) : backgroundColor.slice(0, data.length),
          borderColor: this.props.showColorBlind ? colorBlindBorderColor.slice(0, data.length) : borderColor.slice(0, data.length),
          borderWidth: borderWidth
        },

      ],
    };
    if(!this.props.showUnknown) { 
      returnData.datasets[0].data.pop();
      returnData.datasets[0].backgroundColor.pop();
      returnData.datasets[0].borderColor.pop();
      returnData.labels.pop();
    }
    return returnData;
  }

  downloadPng = () => {
    const canvas = this.myRef.current.canvas;
    const ctx = canvas.getContext('2d');
  
    // Save the current canvas state
    ctx.save();
  
    // Set white background
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Restore the canvas state
    ctx.restore();  
  
    // Convert chart to base64 image with white background
    const chartBase64 = canvas.toDataURL('image/png');
  
    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = chartBase64;
    link.click();
  }
  

  render(){
    const{data, isMounted} = this.state;
    const {largeFont, simpleTitle } = this.props;
    if(isMounted)
      return (
        <div style = {{
          display :"flex",
          flexDirection :"column" ,
          backgroundColor : 'white',
          boxShadow : '-20px 30px 20px rgba(0, 0, 0, 0.24)',
          borderRadius: '15px',
          width : '100%',
          height : '100%',
          padding : '25px',
          zIndex : 5
      }}
      className='react-container' key="d">
        <GraphWrapper 
          title = "Individuals by Age" 
          downloadCSV = {this.downloadCSV} 
          downloadPng={this.downloadPng}
          isMounted = {isMounted} 
          style={{paddingBottom : "25px"}}
        >
          <Doughnut data={data} options = {largeFont ? largeFontOptions : defaultOptions} onClick={this.props.onClick} ref={this.myRef}/>
        </GraphWrapper>
        </div>
      );
    else 
      return null;
  } 
}