import React, {Component} from "react";
import { Center, HStack, Text, Link, Box } from '@chakra-ui/react'
import { LargeLogo } from '../assets/largeLogo.jsx'
import { Stack } from "react-bootstrap";

export default class Footer extends Component {
  
  render(){
    return(
        <Box backgroundColor={"white"} w={"100%"}  align="left" pt={"25px"} pl= {"25"} pb = {"15"} mb = {"0"}>
          <HStack alignItems="flex-start">
            <Box>
              <Stack align="center">
              <LargeLogo height = {50} />
              <Text fontSize="sm" color="subtle" mb = {"0"}>
                &copy; {new Date().getFullYear()} Flock Analytics LLC. All rights reserved.
              </Text>
              </Stack>
              </Box>
            <Box>
              <Link href="/privacy-policy" isExternal>
                <Text fontSize="sm" mb={"0"} textDecoration="underline">
                  Privacy Policy
                </Text>
              </Link>
            </Box>
            <Box>
              <Link href="/terms-of-service" isExternal>
                <Text fontSize="sm" mb={"0"} textDecoration="underline">
                  Terms of Service
                </Text>
              </Link>
            </Box>
          </HStack>
        </Box>
    );
  }
}
