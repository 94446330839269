
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import '../App.css';
import Home from '../home'
import React from 'react';
import NavBar from '../navBar.js';
import Footer from '../components/footer.js';
import Auth from '../components/auth.js';

function App() {

  return (
    <Auth blockedStatus = {{'/login' : [0], '/registration' : [406], '/review-registration': [407]}}>
      <div className="App" >
        <NavBar />
        <Home />
        <Footer />
      </div>
    </Auth>
  );
}

export default App;
