
const birthdayToAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    //if(Math.abs(ageDate.getUTCFullYear() - 1970) < 18) console.log(birthday);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const download = function (data) {

	// Creating a Blob for having a csv file format
	// and passing the data with type
	const blob = new Blob([data], { type: 'text/csv' });

	// Creating an object for downloading url
	const url = window.URL.createObjectURL(blob)

	// Creating an anchor(a) tag of HTML
	const a = document.createElement('a')

	// Passing the blob downloading url
	a.setAttribute('href', url)

	// Setting the anchor tag attribute for downloading
	// and passing the download file name
	a.setAttribute('download', 'download.csv');

	// Performing a download with click
	a.click()
}

const csvmaker = function (data) {

	// Empty array for storing the values
	var csvRows = [];

	// Headers is basically a keys of an
	// object which is id, name, and
	// profession
	const headers = Object.keys(data);

	// As for making csv format, headers
	// must be separated by comma and
	// pushing it into array
	csvRows.push(headers.join(','));

	// Pushing Object values into array
	// with comma separation
    var tmp = [];
    for(var i = 0; i < data[headers[0]].length; i++){
        tmp = [];
        for(var y = 0; y < headers.length; y++){
            tmp.push(data[headers[y]][i]);
        }
        csvRows.push(tmp.join(','));
    }
	//const values = Object.values(data).join(',');
	//csvRows.push(values)

	// Returning the array joining with new line
	return csvRows.join('\n')
}

const downloadCSV =  (data) => {

	const csvdata = csvmaker(data);
	download(csvdata);
};

function lowCollisionHash(input) {
	let hash = 0;
	if (input.length === 0) {
	  return hash.toString();
	}
	for (let i = 0; i < input.length; i++) {
	  const char = input.charCodeAt(i);
	  hash = ((hash << 5) - hash) + char;
	  hash &= hash; // Convert to 32-bit integer
	}
	return Math.abs(hash).toString();
  }
  
function generateRandomString(length) {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
}

function compareObjects(obj1, obj2) {
  for (let prop in obj1) {
    if (obj1.hasOwnProperty(prop) !== obj2.hasOwnProperty(prop)) {
      return false;
    }
    if (typeof obj1[prop] !== typeof obj2[prop]) {
      return false;
    }
    if (typeof obj1[prop] === 'object') {
      if (!compareObjects(obj1[prop], obj2[prop])) {
        return false;
      }
    } else {
      if (obj1[prop] !== obj2[prop]) {
        return false;
      }
    }
  }
  for (let prop in obj2) {
    if (obj2.hasOwnProperty(prop) !== obj1.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
}


export { birthdayToAge, downloadCSV, lowCollisionHash, generateRandomString, compareObjects};