import React, { Component } from 'react';
import DashboardHome from './dashboardSelector'
import { Image, Box, Center, Divider, HStack, Card, CardHeader, CardBody, CardFooter, Text, Heading, Button } from '@chakra-ui/react';

export default class ApiSelector extends Component {

  render(){
    return(
      <>
      <Box textAlign={"center"} pb = {"100"}>
        <Heading>Select your current church management software</Heading>
      <Center height = "80vh">
        <HStack height = "100%" spacing = "10">
        <Card minW = "25vw" align='center'>
          <CardHeader>
            <Heading size='md'>Planning Center</Heading>
          </CardHeader>
          <CardBody >
            <Center>
              <Image height = "250px" src = "planning_center.png"/>
            </Center>
          </CardBody>
          <CardFooter justify='space-between'>
            <Button colorScheme={"brand"} onClick = {() => {
              this.props.selectApi("pc")
              window.scrollTo({
                top: 0,
                behavior: "smooth" // Optional: Use smooth scrolling for a more visually appealing effect
            });
            }}>Select Planning Center</Button>
          </CardFooter>
        </Card>
        <Divider orientation='vertical' borderWidth={"1px"}/>
        <Card minW = "25vw" align='center'>
          <CardHeader>
            <Heading size='md'>Pushpay/CCB</Heading>
          </CardHeader>
          <CardBody >
            <Center>
              <Image height = "250px" src = "church_community_builder.jpg"/>
            </Center>
          </CardBody>
          <CardFooter>
          <Button colorScheme={"brand"} onClick = {() => {
              this.props.selectApi("ccb")
              window.scrollTo({
                top: 0,
                behavior: "smooth" // Optional: Use smooth scrolling for a more visually appealing effect
            });
            }}>Select Church Community Builder</Button>
          </CardFooter>
        </Card>
        </HStack>
      </Center>
      </Box>
      </>
    );
  }
}