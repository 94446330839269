import React, {Component} from "react";
import { Heading, 
  Text, 
  IconButton, 
  Spacer, 
  HStack, 
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Center
} from "@chakra-ui/react";
import NavBar from "../navBar";
import { Navigate } from "react-router-dom";
import { BiEditAlt, BiTrash, BiUserPlus } from "react-icons/bi";
import { keys } from "localforage";


const API_URL = process.env.REACT_APP_API_URL;

export default class ManageAccounts extends Component {
  constructor(props){
    super(props);
    this.state = {
      accounts : [],
      firstName : "",
      lastName : "",
      email : "",
      role : "User",
      key : 0,
      editMenuOpen : false
    };
  }

  componentDidMount(){
    fetch(`/api/church/all-users`, 
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json()
    })
    .then(res => {
      console.log(res.data);
      this.setState({accounts : res.data})
    })
    .catch((error) => {
      // Handle errors, including HTTP errors and other exceptions
      console.error('Fetch error:', error);
    });

  }

  update_or_create_account = () => {
    const {firstName, lastName, email, role, password1, password2, key, accounts} = this.state;
    let updatedAccount = {
      first_name : firstName,
      last_name : lastName,
      email : email,
      role : role
    }
    let updatedAccounts = [...accounts]; // Create a new array
    if(key == "push"){
      updatedAccount.password = password1;
      fetch(`/api/auth/create-user`, 
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedAccount)
        }
      )
      .then(res =>{
        if(res.ok) {
          updatedAccounts.push(updatedAccount);
          this.setState({editMenuOpen : false, accounts : updatedAccounts});

        }
      })
      .catch(err =>{
        console.error(err);
      });
    } else{
      fetch(`/api/auth/update-user`, 
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedAccount)
        }
      )
      .then(res =>{
        if(res.ok){
          updatedAccounts[key] = updatedAccount;
          this.setState({editMenuOpen : false, accounts : updatedAccounts})
        }
      })
      .catch(err =>{
        console.error(err);
      });
    }
  }

  render(){
    const {accounts, password1, password2, editMenuOpen, firstName, lastName, role, key, email} = this.state;
    return(
      <>
        <Stack  spacing={5} maxW = "500px">
          <Heading fontSize={"4xl"} color = "#666">Accounts</Heading>
          {(accounts.length > 0) ? (
            accounts.map((account, key) =>
              <HStack 
                alignItems="center"
                background={"white"}
                borderRadius= {'10px'}
                borderColor = {'lightgray'}
                borderWidth = {'1px'}
              >
                <Text  
                  fontSize = "lg"
                  my = {"10px"}
                  ml = {"5px"}
                >
                  {account.first_name} {account.last_name}
                </Text>
                <Spacer />
                <IconButton 
                  aria-label='Search database' 
                  icon={<BiEditAlt />} 
                  variant='link'
                  onClick={() =>{
                    console.log(account)
                    this.setState({
                      editMenuOpen : true,
                      firstName : account.first_name,
                      lastName  : account.last_name,
                      email : account.email,
                      role : account.role,
                      key : key
                    })
                  }}
                />
                  <Popover>
                    <PopoverTrigger>
                      <IconButton 
                        aria-label='Search database' 
                        icon={<BiTrash />} 
                        variant='link'
                      />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody >
                          <Center>
                            Are you sure you want to delete?
                            <Button 
                              colorScheme='red'
                              onClick={() => {
                                const updatedAccounts = [...accounts]; // Create a new array
                                updatedAccounts.splice(key, 1); // Remove the element from the new array
                                this.setState({ accounts: updatedAccounts }); // Update the state with the new array
                              }}
                            >
                              Yes
                            </Button>
                          </Center>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                </Popover>
              </HStack>
            )
          ) : (
            <HStack 
              alignItems="center"
              background={"white"}
              borderRadius= {'10px'}
              borderColor = {'lightgray'}
              borderWidth = {'1px'}
            />
          )}
          <Modal isOpen={editMenuOpen} onClose={() => this.setState({editMenuOpen : false})}>
            <ModalOverlay />
            <ModalContent>
            <ModalCloseButton />
              <ModalHeader>Edit Account</ModalHeader>
              <ModalBody>
                <HStack>
                  <Box mb = {'10px'}>
                    <Text fontSize = "lg">First Name</Text>
                    <Input 
                      value={firstName}
                      onChange={(e) => this.setState({ firstName: e.target.value })}
                    />
                  </Box>

                  <Box>
                    <Text fontSize = "lg">Last Name</Text>
                    <Input 
                      value={lastName}
                      onChange={(e) => this.setState({ lastName: e.target.value })}
                    />
                  </Box>
                </HStack>

                <Box mb = {'10px'}>
                  <Text fontSize = "lg">Email Address</Text>
                  <Input 
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Box>

                <Box>
                  <Text fontSize = "lg">Priveledge Level</Text>
                  <HStack pb = "10px">
                    <Checkbox
                     colorScheme={'brand'}
                     onChange= {() => this.setState({role : 'Admin'})}
                     isChecked = {(role == 'Admin')}
                    >
                      Admin
                    </Checkbox>

                    <Checkbox 
                      colorScheme={'brand'}
                      onChange={() => this.setState({ role: 'User' }) }
                      isChecked = {(role == 'User')}
                    >
                      User
                    </Checkbox>
                  </HStack>
                  {key == "push" ? (
                    <>
                      <Box mb = {'10px'}>
                      <Text fontSize = "lg" mb = "0">Enter Password</Text>
                      <Text fontSize={"sm"} color={"#666"}>Must be at least 6 characters</Text>
                      <Input 
                        type = "password"
                        value={password1}
                        onChange={(e) => this.setState({ password1: e.target.value })}
                      />
                    </Box>
                    <Box mb = {'10px'}>
                      <Text fontSize = "lg">Re-enter Password</Text>
                      <Input 
                        type = "password"
                        value={password2}
                        onChange={(e) => this.setState({ password2: e.target.value })}
                      />
                    </Box>
                  </>
                  ) : (
                    <></>
                  )}
                </Box>

              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3}  
                  onClick={this.update_or_create_account}
                  isDisabled={!((firstName && lastName && email && role) && (key != "push" || (key == "push" && password1 == password2 && password1.length > 5)))}
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Button
          onClick={() =>{
            this.setState({
              editMenuOpen : true,
              firstName : "",
              lastName  : "",
              email : "",
              role : "",
              key : "push",
              password1 : "",
              password2 : ""
            })
          }}
          leftIcon = {<BiUserPlus />}
          mt = {"20px"}
          colorScheme={"brand"}
          alignSelf={"center"} 
          width = "30%" 
        >
          New User
        </Button>
        </Stack>
      </>
    );
  }
}