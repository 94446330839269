import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import SSOLogin from './ssoLogin';
import GenerateSsoToken from './generateSsoToken';
import { IconButton } from '@chakra-ui/react';
import { BiArrowBack } from "react-icons/bi";
import ResetPassword from './resetPassword';

const API_URL = process.env.REACT_APP_API_URL;

export default class SSO extends Component {
  constructor(props){
      super(props);
      this.state = {
        isLoggedIn : false,
        ssoEmail : "",
        code : ""
      };
  }

  setIsLoggedIn = (value)=>{
    this.setState({isLoggedIn : value})
  }

  setSsoEmail = (email)=>{
    this.setState({ssoEmail : email})
  }

  setCode = (code) =>{
    this.setState({code : code})
  }
  
  
  render(){
    const {ssoEmail, isLoggedIn, code } = this.state;
    if(isLoggedIn == true){
       return <Navigate to="/profile-settings" />;
    } else {
      return (
        <>
          <IconButton
            icon={<BiArrowBack />}
            variant={"link"}
            ml={-5}
            onClick={() => this.props.setPage("login")}
          />
          {ssoEmail ? (code ? <ResetPassword code = {code} email = {ssoEmail} setIsLoggedIn = {this.setIsLoggedIn}  /> : <SSOLogin setCode = {this.setCode} email = {ssoEmail} />) : <GenerateSsoToken setSsoEmail = {this.setSsoEmail} />}
        </>
      );
    }
  }
};