import React, { Component } from 'react';
import { Text, RangeSlider, RangeSliderFilledTrack, RangeSliderTrack, RangeSliderThumb, HStack, Spacer } from '@chakra-ui/react';
import { IconButton, Box, Heading } from '@chakra-ui/react'
import { BiX } from "react-icons/bi";

const step = 1;
const key = "age"

export default class SliderFilter extends Component {
    constructor(props){
        super(props);
        this.state = {
            sliderLeft: 0,
            sliderRight: 100,
            sliderMin: 0,
            sliderMax: 100,
            defaultSliderValue: [0, 100],
            isMounted : false,
            includeUnknown : true
        }
        /*
        this.props = {
            key : Number,
            data : [Object object]
            onChange : function()
        }
        */
        this.onChangeEnd = this.onChangeEnd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount(){
        const {data} = this.props;
        this.setState({
            sliderLeft: data.data.minAge,
            sliderRight: data.data.maxAge,
            sliderMin: data.data.minAge,
            sliderMax: data.data.maxAge,
            defaultSliderValue: [data.data.minAge, data.data.maxAge],
            isMounted : true
        })
    }
    onChange(filters){
        this.setState({
            sliderLeft: filters[0],
            sliderRight: filters[1]
        })
    }
    onChangeEnd(filters){
        console.log("keyPath");
        console.log(this.props.keyPath);
        const path = JSON.stringify(this.props.keyPath)
        /*if(!${this.state.includeUnknown} && value || ${this.state.includeUnknown}){
            return true;
        } else {
            return false;
        }*/
        const filter = eval(`( () => {
            return function(x, showUnknown) {
                var keyPath = ${path};
                var value = x;
                for(var i = 0; i < keyPath.length; i++) value = value[keyPath[i]];
                if(${filters[0]} <= value && ${filters[1]} >= value || showUnknown && !value){
                    return true;
                } else {
                    return false;
                }
            }
          })()`);
        this.props.updateFilter({
            key : key,
            tag : this.props.tag,
            filterCond: filter
        });
    }
    handleClick(){
        this.props.deleteFilter(this.props.tag);
    }
    render(){
        const {name, tag, keyPath, data} = this.props;
        const {isMounted, sliderMin, sliderMax, defaultSliderValue, sliderLeft, sliderRight} = this.state;
        if(!isMounted) return null;
        return(
            <Box pos = {"relative"} borderColor={"black"}>
            <IconButton 
                isRound = {true} 
                icon={<BiX size={20}/>} 
                position="absolute" 
                top="1.5" 
                right="0" 
                onClick={this.handleClick}
                variant = "link"
                
            />
            <Heading 
                fontSize={"2xl"} 
                color = "#666" 
                pb = {"0"}
                pl = {"5px"}
                mb = {"-10px"}
            >
                {name}
            </Heading>
            <HStack>
                <Text fontSize={"xl"} mb = {"0"} pl = {"5px"} >{sliderLeft}</Text>
                <Spacer />
                <Text fontSize={"xl"} pr = {"5px"}>{sliderRight}</Text>
            </HStack>
            <Box px = {"10px"}>
            <RangeSlider 
                defaultValue={defaultSliderValue}
                min={sliderMin} 
                max={sliderMax} 
                step={step}
                onChange = {this.onChange}
                onChangeEnd = {this.onChangeEnd}
            >
            
            <RangeSliderTrack bg='brand.100'>
                <RangeSliderFilledTrack bg='brand.500' />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} style ={{borderWidth: "2px", borderStyle: "solid", borderColor: "black"}}>
            </RangeSliderThumb>
            <RangeSliderThumb boxSize={6} index={1} style ={{borderWidth: "2px", borderStyle: "solid", borderColor: "black"}}>
            </RangeSliderThumb>
            </RangeSlider>
            </Box>
            </Box>
        );
    }
};