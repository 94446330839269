import React, { Component } from 'react';
import {Heading, Text, Container, HStack } from '@chakra-ui/react'
import { birthdayToAge } from '../../util/util.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { compareObjects } from '../../util/util.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Total Families',
      },
    },
  };


const backgroundColor = [
  'rgba(255, 99, 132, 0.3)',
  'rgba(54, 162, 235, 0.3)',
  'rgba(255, 206, 86, 0.3)',
  'rgba(75, 192, 192, 0.3)',
  'rgba(153, 102, 255, 0.3)',
  'rgba(255, 159, 64, 0.3)',
  'rgba(128, 128, 0, 0.3)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(128, 128, 0, 1)',
];
const borderWidth = 1;

export default class IndividualTotal extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      days: ""
    };
    this.calculateData = this.calculateData.bind(this);
  }
  componentDidMount(){
    var data = this.calculateData();
    const startDate = new Date(this.props.dates.sort()[this.props.dates.length-2]);
    const endDate = new Date(this.props.dates.sort()[this.props.dates.length-1]);
    // Calculate the difference in milliseconds
    const timeDiff = endDate.getTime() - startDate.getTime();
    let daysDiff = (timeDiff > 0) ? Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) : 0

    // Convert milliseconds to day
        this.setState({
          data : data,
          days : daysDiff,
          isMounted : true
        });
  }
  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }
  calculateData(){
    var count = 0
    var latest_snapshot_date = this.props.dates.sort().reverse()[1];
    var latest_snapshot = this.props.data.filter(individual => individual.snapshot_date == latest_snapshot_date);
    console.log(latest_snapshot.length);
    var current = this.props.data.filter(individual => individual.snapshot_status == 'active' || individual.snapshot_status == 'temporary');
    console.log("number")
    var ls_ids = latest_snapshot.map(individual => individual.ccb_id);
    var c_ids = current.map(individual => individual.ccb_id);

    const set1 = new Set(c_ids);

    // Use filter to count the numbers in array2 that are not in array1
    return count = ls_ids.filter(id => !set1.has(id)).length;
  }

  render(){
    const{data, days, isMounted} = this.state;
    if(isMounted)
      return (
        <Container px = {"10px"}>
        <Text 
          fontSize="2xl" 
          mb="0" 
          mt="15px" 
          color = "#666"
          whiteSpace="nowrap" // Prevent text from wrapping
          overflow="hidden"  // Hide overflow text
          textOverflow="ellipsis" // Add ellipsis for overflow text
            >Lost(Last {this.state.days} Days) </Text>
        <Heading  fontSize = {{ base: '2xl', md: '3xl', lg: '4xl' }} color = {(data == 0 ? "#666" : "red")}>
          {data > 0 ? "-" : ""}{data}
        </Heading>
      </Container>

      );
    else
      return null;
  } 
}