import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import LoginPage from './pages/loginPage2';
import ProfileSettings from './pages/profileSettings'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme/theme.js'
import AppMap from './pages/AppMap';
import Registration from './pages/registrationPage';
import HomePage from './pages/homePage'
import GrowthDashboard from './pages/dashboardGrowth'
import SalvationDashboard from './pages/dashboardSalvation'
import NotFound from './404page';
import ErrorBoundary from './myErrorBoundary';
import CreateAccount from './pages/createAccount.js';
import CcbActivate from './ccb_activate';
import Terms from './termsOfService.js';
import Privacy from './privacyPolicy.js';
import ReviewRegistration from './pages/reviewRegistration';
import LandingPage from './pages/landingPage.js'
//import ExplorerAiPage from './pages/explorerAiPage.js'

let routes = [
  {
    path: "/care-dashboard",
    element: <App />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/create-profile",
    element: <CreateAccount />,
  },
  {
    path: "/profile-settings",
    element: <ProfileSettings />
  },
  {
    path: "/map-dashboard",
    element: <AppMap />
  },
  {
    path: "/registration",
    element: <Registration />
  },
  {
    path: "/review-registration",
    element: <ReviewRegistration />
  },
  {
    path: "/activate",
    element: <CcbActivate />
  },
  {
    path: "/home",
    element: <HomePage />
  },
  {
    path: "/growth-dashboard",
    element: <GrowthDashboard />
  },
  {
    path: "/salvation-dashboard",
    element: <SalvationDashboard />
  },
  {
    path : '*',
    element : <NotFound />
  },
  {
    path : "/terms-of-service",
    element : <Terms/>
  },
  {
    path : "/privacy-policy",
    element : <Privacy/>
  },
  {
    path : "/",
    element : <LandingPage/>
  },
  // {
  //   path : "/explorer-ai",
  //   element : <ExplorerAiPage/>
  // }
];

const wrappedRoutes = routes.map(route => {
    return {
      ...route,
      element: (
        <ErrorBoundary>
          {route.element}
        </ErrorBoundary>
      )
    };
});

const router = createBrowserRouter(wrappedRoutes);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider theme = {theme}>
      <ErrorBoundary>
        <RouterProvider router = {router}/>
      </ErrorBoundary>
    </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
