import React, { Component } from 'react';
import {Heading, Text, Container, Flex, Box } from '@chakra-ui/react'
import { compareObjects } from '../../util/util';
import  ScaleText from 'react-scale-text';

const backgroundColor = [
  'rgba(255, 99, 132, 0.3)',
  'rgba(54, 162, 235, 0.3)',
  'rgba(255, 206, 86, 0.3)',
  'rgba(75, 192, 192, 0.3)',
  'rgba(153, 102, 255, 0.3)',
  'rgba(255, 159, 64, 0.3)',
  'rgba(128, 128, 0, 0.3)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(128, 128, 0, 1)',
];
const borderWidth = 1;

export default class MarriedStat extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      hash : null,
      loaded : false
    };
    this.ageData = this.ageData.bind(this);
    this.calculateData = this.calculateData.bind(this);
  }
  componentDidMount(){
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }
  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }
  calculateData(){
    var data = 0
    var nullCnt = 0;
    this.props.data.forEach(individual => {
        if(individual.marital_status == "Married") data++;
        else if(individual.marital_status == null) nullCnt++;
    });
    var unknownMin = (data / this.props.data.length)*100;
    unknownMin = unknownMin.toFixed(0);

    var unknownMax = ((data + nullCnt) / this.props.data.length)*100;
    unknownMax = unknownMax.toFixed(0);
    
    var knownVal = (data / (this.props.data.length - nullCnt))*100;
    knownVal = knownVal.toFixed(0);

    return {unknownMin : unknownMin, unknownMax : unknownMax, knownVal : knownVal};
  }
  ageData(data){
    //console.log("here are the ranges: " +ageRanges);
    //console.log(data);
    // ageRanges = [[x-y], [a-b]]
    // data = [40, 50, 18]
    var rv = 0
    data.forEach(person => {
        if(person == "Married") rv++;
    });
    console.log(rv);
    const val = (rv / data.length)*100;
    return val.toFixed(2);
  }

  render(){
    const{data, isMounted} = this.state;
    const {showUnknown} = this.props;
    const text = showUnknown ? ( `${data.unknownMin}-${data.unknownMax}%`) : `${data.knownVal}%`
      if(isMounted)
      return (
        <Flex direction="column" px={"10px"} h={"100%"}>
          <Text fontSize="3xl" mb="0" mt="15px" color = "#666">Married</Text>
          <Box flex="1" maxH="100%">
            <ScaleText maxFontSize={50}>
              <p style={{color : "#666", whiteSpace: 'nowrap'}}>
                {text}
              </p>
            </ScaleText>
          </Box>
          </Flex>
      );
    else 
      return null;
  } 
}