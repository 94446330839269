import React, { Component } from 'react';
import { Box, Select, Stack, Button, Text, Heading, IconButton} from '@chakra-ui/react';
import { generateRandomString } from '../../../util/util';
import DropDown from "./dropDown"
import FilterSelector from "./FilterSelector"
import { BiX } from "react-icons/bi";

/*data = [
  {
    dataName : "name",
    formName : "name",
    validFilters : [{
      componentName : "ComponentName",
      formName : "Form name"
    }]
  }
]*/

export default class NewFilterConstructor extends Component {
  constructor(props){
    super(props);
    this.state = {
    filters: [],
    isFieldSelected : false,
    isFilterSelected : false,
    fieldName : "",
    fieldKeyPath : "",
    filter : "",
    filters : []
    }
    this.onChangeField = this.onChangeField.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  onChangeField(field, keyPath){
    var filters = this.props.data.filters;

    for(var i = 0; i < keyPath.length; i++) filters = filters[keyPath[i]];

    this.setState({
      fieldName : field,
      fieldKeyPath : keyPath,
      filters : filters,
      isFieldSelected: true,
      isFilterSelected : false
    });
  }
  onChangeFilter(filter){
    console.log("hey");
    if(filter === false) this.setState({isFilterSelected : false});
    this.setState({
      filter,
      isFilterSelected : true,
    });
  }
  handleClick(e){
    const {filter, fieldName, fieldKeyPath } = this.state;

    if(!(fieldName)) return;
    this.props.onUpdate(filter, fieldName, fieldKeyPath);
  }
  render(){
    const { data } = this.props;
    const { isFieldSelected, isFilterSelected, filters, filter, fieldName, fieldKeyPath} = this.state;
    //console.log("filters length: " + filters.length);
    return(
      <Box pos = "relative">
        <Heading 
          fontSize = "3xl" 
          color = {"#666"}
          pt = {"25px"}
          lineHeight = {"1.25"}
          textAlign = {"center"}
        > 
          New Filter
        </Heading>
        <IconButton 
          isRound = {true} 
          icon={<BiX size={20}/>} 
          position="absolute" 
          top="0" 
          right="0" 
          onClick={() => {this.props.deleteFilterConstructor()}}
          variant = "link"
        />
        <Stack>
            <DropDown title = {"Field"} data = {data.fields} onUpdate = {this.onChangeField}></DropDown>
            {(filters.length == 1) ? (!isFilterSelected ? this.onChangeFilter(filters[0].id) : null
            ) : (
            <FilterSelector 
              title = {"Filter"} 
              data = {filters} 
              onUpdate = {this.onChangeFilter} 
              isFilterSelected ={isFilterSelected}
            />
            )}
          <Button 
            isDisabled = {!isFilterSelected}
            onClick = {this.handleClick}
            colorScheme = {"brand"}
          > Add </Button>
        </Stack>
      </Box>
    );
  }
};