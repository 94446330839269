import React, { Component } from 'react';
import '../../App.css';
import { Select, Text, HStack } from '@chakra-ui/react';

class MyDropdown extends Component {

  handleChange = (event) => { 
    this.props.setAddressType(event.target.value)
  };

  render() {
    const { addressType, addressTypeMetaData } = this.props;

    return (
      <HStack 
      ml = {"5vw"} 
      align={"center"} 
       
      w = "425px" 
      
        borderRadius = {'5px'} 
        px = {"10px"} 
        py = {"10px"}>
      <Text m = {0}>Select an address type: </Text>
      <Select value={addressType} onChange={this.handleChange} 
      w = {"200px"} 
      background={'white'} 
      borderWidth= '2px' 
      borderColor = {"gray.400"}
      sx={{_hover : {borderColor: 'brand.500'}}}>
        {addressTypeMetaData.map((addressType) => (
          <option key={addressType.value} value={addressType.value}>
            {addressType.name}
          </option>
        ))}
      </Select>
      </HStack>
    );
  }
}

export default MyDropdown;
