import React, { useEffect, useState } from 'react';
import { Text, Heading, Stack } from '@chakra-ui/react';

//styling

const Counter = props => {
  // label of counter
  // number to increment to
  // duration of count in seconds
  const {label, number, duration } = props

  // number displayed by component
  const [count, setCount] = useState("0")

  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = parseInt(number.substring(0,3))
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter 
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3))
      if (start === end) clearInterval(timer)       
    }, incrementTime);

    // dependency array
  }, [number, duration]);

  return (
    <>
    <Stack px = {"50px"} align={"center"}>
      <Text fontSize="3xl" mb="0" mt="15px" color = "#666">{label}</Text>
      <Heading  fontSize = {{ base: '4xl', md: '4xl', lg: '4xl' }} color = "#666">
        {count}
      </Heading>
      </Stack>
    </>
  );
}

export default Counter;
