import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    FormHelperText,
    HStack,
    
  } from '@chakra-ui/react';
  import React, { Component } from 'react';
  import { Navigate } from "react-router-dom";
  import { LargeLogo } from '../assets/largeLogo.jsx'
  import Footer from "../components/footer.js"
  import Auth from '../components/auth.js';

const API_URL = process.env.REACT_APP_API_URL;
  
export default class CreateAccount extends Component {
    constructor(props){
        super(props);
        this.state = {
          isLoggedIn : false,
          loginfailed : false,
          email : "",
          password : "",
          password2 : "",
          churchName : "",
          firstName : "",
          lastName : "",

          emailValid : true,
          passwordValid : true,
          password2Valid : true,
          churchNameValid : true,
          firstNameValid : true,
          lastNameValid : true,

          error : false,
          errorMessage : "",

        };
    }
    async componentDidMount(){
      fetch(`/api/church/meta`, {method: "POST", credentials: "include",})
      .then(res =>{ 
        if(res.ok) return res.json();
        throw new Error("Bad Response")
      })
      .then(res => this.setState({isLoggedIn : true, status : res.status}))
      .catch(err => this.setState({isLoggedIn : false}))

      const params = new URLSearchParams(window.location.search);
      const paramObj = {};
      
      for (const [key, value] of params) paramObj[key] = value;
      this.setState({exemption : paramObj.hasOwnProperty('exemption') ? paramObj['exemption'] : undefined})
    }

  async ccbRegistration(data) {
    data.api_type = this.state.api_type;
    // include exemption in request if include in url params
    if(this.state.exemption !== null) data.exemption = this.state.exemption;
    this.setState({ccb_data : data, loading : true});
    }

    register = async (e) =>{
      e.preventDefault();
      this.setState({error: false})
      const {email, password, password2, churchName, firstName, lastName} = this.state;
      let emailValid = true, passwordValid = true, password2Valid = true, churchNameValid = true, firstNameValid = true, lastNameValid = true, error = false, errorMessage;

      if(!email || !(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email))){
        emailValid = false;
        error = true;
      }
      if(!password || password.length < 6 || password != password2){
        passwordValid = false;
        error = true;
      }
      if(!password2 || password != password2){
        password2Valid = false;
        error = true;
      }
      if(!churchName){
        churchNameValid = false;
        error = true;
      }
      if(!firstName){
        firstNameValid = false;
        error = true;
      }
      if(!lastName){
        lastNameValid = false;
        error = true;
      }
      if(error) {
        if(!emailValid) errorMessage = "Please enter a valid email address";
        else if(!password2Valid) errorMessage = "Passwords do not match"
        else if(!passwordValid) errorMessage = "Password must be at least 6 characters"
        else errorMessage = "Please enter all the required information";
        this.setState({
          error : error,
          errorMessage : errorMessage,
          emailValid : emailValid,
          passwordValid : passwordValid,
          password2Valid : password2Valid,
          churchNameValid : churchNameValid,
          firstNameValid : firstNameValid,
          lastNameValid : lastNameValid
        });
      } else{
        //console.log(`${API_URL}/api/auth/signin`);
        fetch(`/api/church/create-account`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "email": email,
                "password": password,
                "church_name" : churchName,
                "first_name" : firstName,
                "last_name" : lastName,
                ...(this.state.exemption && { exemption: this.state.exemption })
            })
        })
        .then((res) => {
            if(res.status == 200) {
                window.location.href = '/registration';
            } else {
              this.setState({error : true, errorMessage : "This email is already taken"})
            }
        });
      }
    }
    clearError = () => {
      this.setState({loginfailed : false});
    }
    render(){
      const {email, password, password2, churchName, firstName, lastName, emailValid, passwordValid, password2Valid, churchNameValid, firstNameValid, lastNameValid, error, errorMessage} = this.state;
      return (
      <Auth allowedStatus = {[0]} blockedStatus = {{'/registration' : [406], '/review-registration': [407]}} defaultRedirect = {'/home'}>
        <>
        <Flex
          minH={'100vh'}
          align={'center'}
          justify={'center'}
          bg={'gray.50'}
          direction = {'column'}
        >
          <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} align = {'center'}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center" w = {{ base: '300px', sm: "425px", md : "600px" }}
          >
            <LargeLogo height = {150}/>
            </Box>
              <Heading fontSize={{ base: '3xl', sm: "4xl" }}>Create an account</Heading>
              <Text fontSize={'lg'} color={'gray.600'}>
                Already have an account? <Link color={'blue.400'} href={"/login"}>Sign in</Link>
              </Text>
              <Box
                rounded={'lg'}
                bg={'white'}
                boxShadow={'lg'}
                width = {{ base: '250px', sm: "300px", md : "500px" }}
                p={8}
              >  
                <Stack spacing={4}>
                  <form onSubmit={this.register}>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input 
                      value = {email} 
                      onChange = {(e) => this.setState({email : e.target.value, emailValid : true})}
                      isInvalid = {!emailValid}
                      errorBorderColor='crimson'
                    />
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input 
                      value = {password} 
                      onChange = {(e) => this.setState({password : e.target.value, passwordValid : true})}
                      isInvalid = {!passwordValid}
                    />
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Re-enter Password</FormLabel>
                    <Input 
                      value = {password2} 
                      onChange = {(e) => this.setState({password2 : e.target.value, password2Valid : true})}
                      isInvalid = {!password2Valid}
                    />
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Church Name</FormLabel>
                    <Input 
                      value = {churchName} 
                      onChange = {(e) => this.setState({churchName : e.target.value, churchNameValid : true})}
                      isInvalid = {!churchNameValid}
                    />
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                  <HStack>
                  <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input 
                      value = {firstName} 
                      onChange = {(e) => this.setState({firstName : e.target.value, firstNameValid : true})}
                      isInvalid = {!firstNameValid}
                    />
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input 
                      value = {lastName} 
                      onChange = {(e) => this.setState({lastName : e.target.value, lastNameValid : true})}
                      isInvalid = {!lastNameValid}
                    />
                    <FormHelperText mb = {"25"}></FormHelperText>
                  </FormControl>
                  </HStack>
                  {error ? (
                    <Alert status='error' mb = {"10px"}>
                      <AlertIcon />
                      <AlertTitle>Error</AlertTitle>
                      <AlertDescription>{errorMessage}</AlertDescription>
                    </Alert>
                  ) : (
                    <></>
                  )}
                  <Button type = {"submit"} colorScheme={"brand"}>submit</Button>
                </form>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      <Footer />
      </>
    </Auth>
    );
    }
};
  