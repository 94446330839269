import * as React from 'react';
import { Container, Text, SimpleGrid, Box, Image, Stack } from '@chakra-ui/react';

const logoData = [
  {
    alt: 'test',
    name: 'Total post reactions',
    src: 'kec.png'
  },
  {
    alt: 'test',
    name: 'Total post reactions',
    src: 'utk.png'
  },
  {
    alt: 'test',
    name: 'Total post reactions',
    src: 'tekno.svg'
  }
];

const Logos = () => {
  return (
    <Container maxW="100%" bg={'brand.800'} p={{ base: 5, md: 10 }} align={'center'} mt={'-10px'}>
      <Stack
        bg={'brand.800'}
        align={'center'}
        maxW="7xl"
      >
        <Text
          color={'white'}
          fontSize="md"
          fontWeight="600"
          textTransform={'uppercase'}
          m={0}
        >
          Featured in:
        </Text>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={5} mt={12} mb={4} pt={'0px'}>
          {logoData.map((l, index) => (
            <Box
              key={index}
              p={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%" // Ensure the box takes the full width of its container
              height="150px" // Set a fixed height for the Box
            >
              <Image
                src={`./featured/${l.src}`}
                alt={l.alt}
                height="100%" // Set the image height to 100% of the Box height
                objectFit="contain"
              />
            </Box>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  );
};

export default Logos;
