import React, {Component} from "react";
import { Flex, Container, Heading, Text, Input, Box, Spacer, Button, HStack, Stack, Divider, Alert, AlertIcon, AlertTitle, AlertDescription, useToast } from "@chakra-ui/react";
import NavBar from "../navBar";
import { Navigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

export default class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      oldPassword : "",
      isOldPasswordValid : true,
      oldPasswordErrorMsg : "",
      newPasswordOne : "",
      isNewPasswordOneValid : true,
      newPasswordOneErrorMsg : "",
      newPasswordTwo : "",
      isNewPasswordTwoValid : true,
      loading : false,
      success : false,
    };
  }


  handleSubmit = () => {
    this.setState({loading : true, success : false});
    const {newPasswordOne, newPasswordTwo} = this.state;
    if(newPasswordOne !== newPasswordTwo) {
      this.setState({
        newPasswordOneErrorMsg : "passwords do not match",
        isNewPasswordOneValid : false,
        isNewPasswordTwoValid : false
      });
    } else if(newPasswordOne.length < 8){
      this.setState({
        newPasswordOneErrorMsg : "password must be at least 8 characters",
        isNewPasswordOneValid : false,
        isNewPasswordTwoValid : true,
      });
    } else { // success
      fetch(`/api/auth/updatepassword`, {
        method : "POST",
        headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            "oldPassword": this.state.oldPassword,
            "newPassword": this.state.newPasswordOne
          })
      }).then((res) => {
        if(res.status == 401)
          this.setState({
            success : false,
            isOldPasswordValid : false,
            oldPasswordErrorMsg : "Incorrect Password",
            isNewPasswordOneValid : true,
            isNewPasswordTwoValid : true,
            loading : false
          })
        else
        this.setState({
          success : true,
          isOldPasswordValid : true,
          isNewPasswordOneValid : true,
          isNewPasswordTwoValid : true,
        });
      }).catch((err) =>{
        console.log(err);
        this.setState({
          isOldPasswordValid : false,
          oldPasswordErrorMsg : "Incorrect Password",
          isNewPasswordOneValid : true,
          isNewPasswordTwoValid : true,
        });
      });
    }
    this.setState({loading : false})
  }

  oldPasswordUpdate = (e) => { this.setState({oldPassword : e.target.value}); }
  newPasswordOneUpdate = (e) => { this.setState({newPasswordOne : e.target.value}); }
  newPasswordTwoUpdate = (e) => { this.setState({newPasswordTwo : e.target.value}); }

  render(){
    const {isAuthenticated, isMounted, isNewPasswordOneValid, isNewPasswordTwoValid, newPasswordOneErrorMsg, loading, oldPasswordErrorMsg, isOldPasswordValid, success} = this.state;
    return(
      <>
          <Box>
            <Text  fontSize = "lg">Old Password</Text>
            <Input 

              placeholder="Enter old password"
              type="password"
              onChange = {this.oldPasswordUpdate}/>
              {!isOldPasswordValid  ?
              (<Alert mt = "5px" status='error'>
                <AlertIcon />
                <AlertTitle>Incorrect Password</AlertTitle>
                <AlertDescription>{oldPasswordErrorMsg}</AlertDescription>
              </Alert>): null}
          </Box>

          <Box>
            <Text  fontSize = "lg">New Password</Text>
            <Input 
              isInvalid = {!isNewPasswordOneValid}
              placeholder="Enter new password"
              type="password"
              onChange = {this.newPasswordOneUpdate}/>
            {!isNewPasswordOneValid  ?
              (<Alert mt = "5px" status='error'>
                <AlertIcon />
                <AlertTitle>Invalid Password</AlertTitle>
                <AlertDescription>{newPasswordOneErrorMsg}</AlertDescription>
              </Alert>): null}
          </Box>
          
          <Box>
            <Text  fontSize = "lg">Re-enter New Password</Text>
            <Input 
              isInvalid = {!isNewPasswordTwoValid}
              placeholder="Re-enter new password"
              type="password"
              onChange = {this.newPasswordTwoUpdate}/>
            
          </Box>

          <Button onClick = {this.handleSubmit}
            alignSelf={"center"} 
            width = "30%" 
            colorScheme={"brand"}
            isLoading = {loading}
          >
            Submit
          </Button>
          {success ? (
            <Alert status='success'>
              <AlertIcon />
              Password updated!
            </Alert>) : null
          }
      </>
    );
  }
}