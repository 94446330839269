import Auth from '../components/auth.js';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import '../App.css';
import Footer from '../components/footer.js';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import NavBar from '../navBar.js';
import BabyDash from '../components/churchCommunityBuilder/babyDashSalvation.jsx';
import Dashboard from '../components/churchCommunityBuilder/dashboard copy.js';
import DashboardContext from '../components/churchCommunityBuilder/filters/DashboardContext';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function App() {

  return (
    <Auth blockedStatus = {{'/login' : [0], '/registration' : [406], '/review-registration': [407]}}>
      <div className="App" >
        <NavBar />
          <DashboardContext >
            <Dashboard bodyComponent = {BabyDash} title = "Salvation Dashboard" includeArchiveData = {true} includeSettings = {true}/>
          </DashboardContext >
          <Footer/>
      </div>
    </Auth>
  );
}

export default App;
