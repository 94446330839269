import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Spacer,
  useBreakpointValue,
  Menu, MenuButton, MenuList, MenuItem, Switch, Tooltip 
} from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import { MediumLogo } from './assets/mediumLogo.jsx'
import { BiUser } from "react-icons/bi";
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from "react-router-dom";

const NavBar = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [demo, setDemo ] = useState(true);
  const [status, setStatus] = useState(0);
  const Navigate = useNavigate();

  useEffect(() => {
    let componentDidMount = async () => {
      try{
        let res = await fetch('/api/church/meta',{
          credentials: "include", 
          headers: {
            "Content-Type": "application/json",
          },
        })
        if(res.status != 200){
          setStatus(0)
        }else{
          res = await res.json();
          setStatus(res.status)
          setDemo(res.demo);
        }
        
      } catch(err) {
        console.log(err);
      }
    }
    componentDidMount();
  }, []);


  const signout = () => {
    fetch('/api/auth/signout', {method: "POST"});
    Navigate('/');
  }

  const signin = () => {
    Navigate('/login')
  }

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex
      as="nav"
      bg="bg-surface" 
      boxShadow="sm"
      py = "15px"
      px= "20px"
      align="center"
      justify="space-between"
      mb = {"15px"}
    >
      <div onClick={() => status == 0 ? Navigate('/') : Navigate('/home')} style={{ cursor: 'pointer' }}>
        <MediumLogo height = {35} />
      </div>
      <Menu 
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      >
        <Tooltip label = {isMenuOpen ? "" : "Profile"}>
          <span>
          <MenuButton 
            as={IconButton} 
            variant='link' 
            icon={<BiUser size={30} />} 
          />
          </span>
          </Tooltip>
          <MenuList >
            {(!demo && (status != 406 && status != 407)) ? (
              <MenuItem onClick={ () => Navigate('/profile-settings')} >
                Profile Settings
              </MenuItem>
            ) : (
              <></>
            )}
            {status == 0 ? (
              <MenuItem onClick={signin}>Sign In</MenuItem>
            ) : (
              <MenuItem onClick={signout}>Sign Out</MenuItem>
            )}
          </MenuList>
        </Menu>
  </Flex>
  );
}
export default NavBar;