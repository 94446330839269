import { Box, Stack } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from './filters/DashboardContext';
import FilterButton from "./filters/filterButton";
import BabyDashboard from './babyDash';
import DashboardSettings from './dashboardSettings'
import FullScreen from './fullScreen';

const DashboardBodyWrapper = (props) => {
  const [modalState] = useContext(DashboardContext);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const BodyComponent = props.bodyComponent;
  let bodyProps = {
    filterOpen : modalState.sidebarIsOpen, 
    filteredIndividualData : props.filteredIndividualData, 
    campuses : props.campuses,
    filteredIndividualDataHash : props.filteredIndividualDataHash, 
    isMounted : props.isMounted, 
    showUnknown : props.showUnknown,
    showColorBlind : props.showColorBlind,
    largeFont : props.largeFont,
    snapshotDates : props.snapshotDates
  };
  if(props.additionalComponent == "addressSelector") bodyProps.addressType = props.addressType;
  return (
    //w={modalState.sidebarIsOpen ? `${modalState.mainWidth}%` : "100%"}
    <Box
      w={modalState.sidebarIsOpen ? "calc(100%)" : "100%"}
      transition={`width ${modalState.duration}s ease`}
      pos="relative"
      paddingLeft={"0px"}
      display = {"flex"}
    >
      <Stack>
      <FilterButton />
      {props.includeSettings ? (<DashboardSettings 
        setColorBlind = {props.setColorBlind}
        setLargeFont = {props.setLargeFont}
      />) : <></>}
      <FullScreen />
      </Stack>
      <Box flex = {1} >
        <BodyComponent 
          {...bodyProps}
        />
      </Box>
    </Box>
  );
};

export default DashboardBodyWrapper;
