import React, {Component} from "react";

export default class Auth extends Component {
  constructor(props){
    super(props);
    this.state = {
      isMounted : false
    }
  }

  componentDidMount(){
    const allowedStatus = this.props.allowedStatus;
    const blockedStatus = this.props.blockedStatus;
    const defaultRedirect = this.props.defaultRedirect;

    fetch('/api/church/meta',{
      credentials: "include", 
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(res =>{
      if(res.status != 200){
        return {status : 0}
      } else {
        return res.json();
      }
    }).then(res =>{
      if (Array.isArray(allowedStatus)) {
        if (allowedStatus.includes(res.status)) {
          return this.setState({isMounted : true});
        } 
      }
      if (typeof blockedStatus === 'object' && blockedStatus !== null){
        for (const redirect in blockedStatus) {
          if(blockedStatus[redirect].includes(res.status)){
            return window.location.href = redirect;
          }
        } 
      }
      if(defaultRedirect){
        return window.location.href = defaultRedirect;
      }
      this.setState({isMounted : true});
    })
    .catch(err => window.location.href='/login')
  }

  render(){
    return(
      <>
        {this.state.isMounted ? 
          this.props.children
        : 
          <></>
        }
      </>
    );
  }
}