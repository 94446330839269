import React, { Component } from 'react';
import {Heading, Text, Flex, Box } from '@chakra-ui/react'
import { birthdayToAge } from '../../util/util.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import  ScaleText from 'react-scale-text';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Total Families',
      },
    },
  };


const backgroundColor = [
  'rgba(255, 99, 132, 0.3)',
  'rgba(54, 162, 235, 0.3)',
  'rgba(255, 206, 86, 0.3)',
  'rgba(75, 192, 192, 0.3)',
  'rgba(153, 102, 255, 0.3)',
  'rgba(255, 159, 64, 0.3)',
  'rgba(128, 128, 0, 0.3)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(128, 128, 0, 1)',
];
const borderWidth = 1;

export default class IndividualTotal extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      wide : true
    };
    this.calculateData = this.calculateData.bind(this);
  }
  componentDidMount(){
    window.addEventListener("resize", () => {
      if(document.getElementById("individuals").offsetWidth > 250){
        if(!this.state.wide) this.setState({wide : true});
      } else{
        if(this.state.wide) this.setState({wide : false});
      }
    });
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }
  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }
  calculateData(){
    return this.props.data.length;
  }

  render(){
    const{data, isMounted} = this.state;
    if(isMounted)
      return (
        <Flex direction="column" px={"10px"} h={"100%"}>
          <Text id = "individuals" fontSize={{ base: "md", md: "lg", lg: "2xl", xl : "3xl"}} mb="0" mt="15px" color = "#666" lineHeight={1}>Total Individuals</Text>
          <Box flex="1" maxH="100%">
            <ScaleText maxFontSize={50}>
              <p style={{color : "#666"}}>
                {this.state.data}
              </p>
            </ScaleText>
          </Box>
        </Flex>
      );
    else
      return null;
  } 
}