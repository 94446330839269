import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  useBreakpointValue
} from '@chakra-ui/react';
import Feature from './feature'
import Fade from './fade'
import data from './data'

export default function CallToActionWithVideo() {
    const clipPathValue = useBreakpointValue({
    base: 'polygon(0% 0%, 100% 2%, 100% 100%, 0 100%)',
    md: 'polygon(0% 0%, 100% 10%, 100% 100%, 0 100%)',
  });

  return (
    <Container maxW={'9xl'} position="relative"  py={{ base: 25, md: 30 }} mt = {'-90px'}>
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={-1}
      background="linear-gradient(to left, blue, #3399ff)"
      overflow="hidden"
      style={{
        clipPath: clipPathValue,
        WebkitClipPath: clipPathValue,
      }}
        >
        </Box>
      <Stack>
        <Fade>
          {data.map(f =>
            <Feature name = {f.name} description = {f.description} imageSrc = {f.imageSrc} />
          )}
        </Fade>
      </Stack>
    </Container>
  );
}
