import React, { Component } from "react";
import { Switch, Text, HStack, Spacer } from "@chakra-ui/react";

class UnknownSwitch extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (event) => {
    this.props.setUnknown(event.target.checked);
  };

  render() {

    return (
      <HStack px = {"10px"} verticalAlign = {"baseline"}>
        <Text fontSize={"2xl"} color = {"#666"}>Show unknown data</Text>
        <Spacer />
        <Switch colorScheme={"brand"} size="lg" onChange={this.handleChange} defaultChecked/>
      </HStack>
    );
  }
}

export default UnknownSwitch;
