import React, { Component } from 'react';
import {
  Stack,
  Flex,
  Box,
  Button,
  Fade,
  Center,
  Show,
  IconButton
} from '@chakra-ui/react';
import { TbArrowBigRightFilled, TbArrowBigLeftFilled } from "react-icons/tb";
import { BiInfoCircle } from 'react-icons/bi';


export default class Banner extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentElementIndex : 0,
      display : true
    };
  }

  componentDidMount(){
    //setInterval(() => this.swap(1), 15000)
  }

  swap = (increment) =>{
    const arrayChildren = this.props.children;
    this.setState({
      display : false,
      loading : true,
    }, () =>{
      setTimeout(() => {
        this.setState(prevState => {
          let newIndex = (prevState.currentElementIndex + increment) % arrayChildren.length;
          if (newIndex < 0) {
            newIndex += arrayChildren.length;
          }
          return {
            currentElementIndex: newIndex,
            display: true,
          };
        }, () => {
          setTimeout(() => {
            this.setState({
              loading : false
            })
            }, 300)
        })
      }, 300);
    })
  }


  render(){
    const {display, currentElementIndex : i} = this.state;
    const arrayChildren = this.props.children; 
    const ComponentToRender = arrayChildren[i];
    return (
      <>
        <Show above={'md'}>
          <Box position="relative" pt="10">
            <Stack 
              direction={'row'} 
              spacing={10} 
              justifyContent={'center'} 
              position="absolute" 
              bottom={{md : '5%', lg : '5%', xl : '10%'}} 
              w = {'100%'}
              zIndex={999} pt = {'100px'}>

              <IconButton
                  aria-label="Previous"
                  color="white"
                  icon={<TbArrowBigLeftFilled size={40}/>}
                  variant="link"
                  onClick={() => this.swap(-1)}
                  _hover={{ color: 'gray.300' }}
                />
                <Box width="20px" />
                <IconButton
                  aria-label="Next"
                  color="white"
                  icon={<TbArrowBigRightFilled size={40}/>}
                  variant="link"
                  onClick={() => this.swap(1)}
                  _hover={{ color: 'gray.300' }}
                />

            </Stack>
            <Fade in={display}>{ComponentToRender}</Fade>
            <Box h = {{sm : '0px', md : '50px', lg : '50px', xl : '25px'}} />
          </Box>
        </Show>
        <Show below = {'md'}>
          <Stack>
            {this.props.children}
          </Stack>
        </Show>
      </>
    );
  } 
}