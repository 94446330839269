import React, { Component } from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import './App.css';
import { Heading } from '@chakra-ui/react';


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: '/marker-icon.png',
    iconUrl: '/marker-icon.png',
    shadowUrl: '/marker-shadow.png',
    iconSize: [11, 18],
    iconAnchor: [12, 17],
    shadowSize: [22, 36],
    shadowAnchor: [12, 34]
  });
const carIcon = new L.Icon({
    iconUrl: '/churchpin.png',
    iconRetinaUrl: '/churchpin.png',
    iconSize: [45, 45],
    iconAnchor: [24, 40],
    popupAnchor: [0, -24],
    shadowUrl: '/marker-shadow.png',
    shadowRetinaUrl: null,
    shadowSize: [41, 41],
    shadowAnchor: [12, 41]
})



export default class FinishedMap extends Component {
    constructor(props){
        super(props);

        this.state = {
            isMounted : false
        };
    }
   async componentDidMount() {
        this.setState({isMounted : true})
            /*users.y = parseFloat(users.x.toFixed(3));
            users.x = parseFloat(tmp.toFixed(3));
            this.setState({ data: Object.values(users) });*/
    }
    render(){
        //console.log("final", this.props.data);
        
        let center = false;
        if(this.props.campuses.length != 0) {
            for (const campus of this.props.campuses){
                if(campus.coordinates != null && campus.coordinates[0] != null)
                    center = campus;
                    break;
            }
        }
        if(!center && this.props.data != null && this.props.data.length != 0){
            for (const data of this.props.data){
                if((data[this.props.addressType].coordinates != null && data[this.props.addressType].coordinates[0] != null)){
                    center = data[this.props.addressType];
                    break;
                }
            }
        }
        return (
            <>
                {this.state.isMounted ? 
                    center ? (
                        <MapContainer className = "markercluster-map" center = {center.coordinates} zoom={10} scrollWheelZoom={false} maxZoom={25}>
                            {this.props.campuses != null ? (
                                this.props.campuses.map(campus => {
                                    return (campus.coordinates != null && campus.coordinates[0] != null ? <Marker position={campus.coordinates} icon={carIcon}/> : null) 
                                })
                            ) : (
                                null
                            )}
                            <MarkerClusterGroup>
                            <TileLayer
                                url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            { this.props.data != null ? this.props.data.map(individual => (individual[this.props.addressType].coordinates != null && individual[this.props.addressType].coordinates[0] != null) ? <Marker  position={individual[this.props.addressType].coordinates} /> : null) : null}
                            </MarkerClusterGroup>
                        </MapContainer>
                    ) : (
                        <Heading>You currently have no address data. To utilize the map feature, please create address data.</Heading>
                ) : (
                     <></>
                    )
                }
            </>
        );
    }
}
//<TestDiv key = "a" style = {{"border-color": "blue", "border-style": "solid"}}><FinishedDonut /></TestDiv>