import React, { Component } from 'react';
import GraphWrapper from '../graphWrapper.js';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import pattern from 'patternomaly';
import { birthdayToAge, downloadCSV } from '../../util/util.js';
ChartJS.register(ArcElement, Tooltip, Legend);

export const largeFontOptions = {
    responsive: true,
    maintainAspectRatio : false,
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 18
              }
          }
      }
    },
    layout: {
      padding: {
        bottom: 15,
        right : 10,
        left : 10
      },
    },
  };
  export const defaultOptions = {
    responsive: true,
    maintainAspectRatio : false,
    plugins: {
      legend: {
          labels: {
              // This more specific font property overrides the global property
              font: {
                  size: 13
              }
          }
      }
    },
    layout: {
      padding: {
        bottom: 15,
        right : 10,
        left : 10
      },
    },
  };



const backgroundColor = [
  'rgb(255,177,194)',
  'rgb(155,209,245)',
  'rgb(192,192,192)',
];
const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(128,128,128, 1)',
];
const colorBlindBackgroundColor = [
  pattern.draw('cross', '#d55e00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('plus','rgba(128,128,128, 0.3)')
];
const colorBlindBorderColor = [
  pattern.draw('cross', '#d55e00'),
  pattern.draw('diagonal-right-left', '#56b4e9'),
  pattern.draw('plus','rgba(128,128,128, 0.3)')
];
const borderWidth = 1;

export default class GenderDonut extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : {},
      isMounted : false,
    };
    this.calculateData = this.calculateData.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);

    this.myRef = React.createRef();
  }
  
  componentDidMount(){
    var data = this.calculateData();
    this.setState({
      data : data,
      isMounted : true
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.hash != prevProps.hash ||
      this.props.showUnknown != prevProps.showUnknown ||
      this.props.showColorBlind != prevProps.showColorBlind){
      var data = this.calculateData();
      this.setState({
        data : data,
      });
    }
  }

  downloadPng = () => {
    const canvas = this.myRef.current.canvas;
    const ctx = canvas.getContext('2d');
  
    // Save the current canvas state
    ctx.save();
  
    // Set white background
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Restore the canvas state
    ctx.restore();  
  
    // Convert chart to base64 image with white background
    const chartBase64 = canvas.toDataURL('image/png');
  
    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = chartBase64;
    link.click();
  }

  calculateData(){
    var genders = {
        male: 0,
        female : 0,
        unknown : 0
    };

    this.props.data.forEach(individual =>{
      if(individual.gender == null) genders.unknown += 1;
      else if(individual.gender == "F") genders.female += 1;
      else if(individual.gender == "M")genders.male += 1;
    })

    var data = {
      labels: ["Women", "Men", "Unknown"],
      datasets: [
        {
          label: 'Genders',
          data: [genders.female, genders.male, genders.unknown],
          backgroundColor: this.props.showColorBlind ? colorBlindBackgroundColor.slice(0, genders.length) : backgroundColor.slice(0, genders.length),
          borderColor: this.props.showColorBlind ? colorBlindBorderColor.slice(0, genders.length) : borderColor.slice(0, genders.length),
          borderWidth: borderWidth
        },
      ],
    };
    if(!this.props.showUnknown) { 
      data.datasets[0].data.pop();
      data.datasets[0].backgroundColor.pop();
      data.datasets[0].borderColor.pop();
      data.labels.pop();
    }
    return data;
  }

  downloadCSV(){
    var genders = {
      male: 0,
      female : 0,
      unknown : 0
    };

    this.props.data.forEach(individual => {
      if(individual.gender == null) genders.unknown += 1;
      else if(individual.gender == "F") genders.female += 1;
      else if(individual.gender == "M")genders.male += 1;
    });

    var data = {
      Gender: ["Women", "Men", "Unknown"],
      Count: [genders.female, genders.male, genders.unknown]
    }

    if(!this.props.showUnknown){
      data.Gender.pop();
      data.Count.pop();
    }

    downloadCSV(data);
  }

  render(){
    const{data, isMounted} = this.state;
    const {largeFont} = this.props;
    if(isMounted)
      return (
        <GraphWrapper 
          title = "Individuals by Gender" 
          downloadCSV = {this.downloadCSV} 
          downloadPng = {this.downloadPng} 
          isMounted = {this.props.isMounted}
        >
          <Doughnut ref = {this.myRef} data={data} options = {largeFont ? largeFontOptions : defaultOptions} onClick={this.props.onClick}/>
        </GraphWrapper>
      );
    else 
      return null;
  }
}