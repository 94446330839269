import React, { Component } from 'react';
import {
  Stack,
  Flex,
  Box,
  Button,
  Image,
  Heading,
  Grid,
  Card,
  CardBody
} from '@chakra-ui/react';

export default class Integrations extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentElementIndex : 0,
      display : true
    };
  }


  render(){
    //const offsetY = ["-150px", "150px", "-50px", "125px"];
    //const offsetX = ["75px", "-50px", "-125px", "-175px"];
    return (
      <>
        <Stack px = {"50px"}  my = '100px' direction={{ base: 'column', md: 'row' }}>
        <Heading textAlign="center" size = {'lg'} maxWidth = '40%'>Easily Integrate with your Existing Church Management Software</Heading>
        <Grid
        w="100%"
        templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
      >
            {Array(4).fill().map((_, index) => (
              <Card   >
                <CardBody>
                  <Box
                  key={index}
                  >
                    <Image src="./pushpay.png"/>
                    <Heading size='md'>Living room Sofa</Heading>
                  </Box>
                </CardBody>
              </Card>
            ))}
          </Grid>
        </Stack>
      </>
    );
  } 
}