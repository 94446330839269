import '../App.css';
import Footer from '../components/footer.js';
import Auth from '../components/auth.js';
import React from 'react';
import NavBar from '../navBar.js';
import 'leaflet/dist/leaflet.css';
import Dashboard from '../components/churchCommunityBuilder/dashboard copy';
import DashboardContext from '../components/churchCommunityBuilder/filters/DashboardContext';
import BabyDashboard from '../components/churchCommunityBuilder/babyDashGrowth';

function App() {

  return (
    <Auth blockedStatus = {{'/login' : [0], '/registration' : [406], '/review-registration': [407]}}>
    <div className="App" >
      <NavBar />
        <DashboardContext >
          <Dashboard bodyComponent = {BabyDashboard} title = "Growth Dashboard" includeArchiveData = {true} includeSettings = {true}/>
        </DashboardContext >
        <Footer/>
    </div>
    </Auth>
  );
}

export default App;
