import React, { Component } from 'react';
import {Image, 
        IconButton, 
        Box, 
        Heading, 
        Button,
        Stack,
        Card,
        Spinner,
        HStack,
        Text,
        OrderedList,
        ListItem,
        Icon,
        Skeleton} from '@chakra-ui/react';
import { MdOutlineRadioButtonUnchecked, MdOutlineCheckCircleOutline } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";

export default class PcRegistration extends Component {
  constructor(props){
    super(props);
    this.state = {
      people : false,
      peopleLoading : false,
      groups : false,
      isMounted : false,
      isLoading : false,
      peopleLoading : false,
      groupsLoading : false,
    };
  }
  componentDidMount(){
    // check for token completion
    fetch(`/api/auth/pc-oauth-status`, {
      method: "GET",
      headers: {'Content-Type': 'application/json'},
      credentials: "include",
    }).then(res=> {
      if(!res.ok) throw new Error();
      return res.json()
    }).then(res => {
      this.setState({people : res.people, groups : res.groups, isMounted : true})
    }).catch(err => this.setState({isMounted : true}));
  }

  people = () =>{
    this.setState({peopleLoading : true}, () => {
      fetch(`/api/auth/pc-authorize`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body : JSON.stringify({scope : 'people'})
      })
      .then(res =>{
        if(!res.ok) throw new Error();
        return res.json()
      })
      .then(res => window.location.href = res.url)
      .catch(err => console.error(err))
      .finally(() => this.setState({peopleLoading : false}));
    });
  }

  groups = () =>{
    this.setState({groupsLoading : true}, () => {
      fetch(`/api/auth/pc-authorize`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body : JSON.stringify({scope : 'groups'})
      })
      .then(res =>{
        if(!res.ok) throw new Error();
        return res.json()
      })
      .then(res => window.location.href = res.url)
      .catch(err => console.error(err))
      .finally(() => this.setState({groupsLoading : false}));
    });
  }

  submit = () => window.location.href = '/review-registration'
  /*submit = () => {
    this.setState({isLoading : true}, async () =>{
      fetch(`/api/payment/checkout`, {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
      })
      .then(res =>{
        if(!res.ok) throw new Error();
        return res.json()
      })
      .then(res => window.location.href = res.url)
      .catch(err =>{
        this.setState({isLoading : false})
      })
    })
  }*/

  render(){
    const {people, groups, isMounted, isLoading, peopleLoading, groupsLoading} = this.state;
    return(
      <>
      <Box textAlign={"center"} minH={"100vh"}>
        <Heading>Connect to Planning Center</Heading> 
      <HStack pr = "50" pt = "100" align="flex-start">
        <Stack maxW = "65vw" pr = {"100"} pl = {"25"} textAlign={"left"} pos={'relative'}>
        <IconButton
          size="xl" // Increase the size to make it bigger
          icon={<BiArrowBack />}
          pos="absolute"
          top="-50px" // Adjust the top position as needed
          left="25px" // Adjust the left position as needed
          onClick={this.props.removeApi}
          bg="white" // Set background color to white
          boxShadow="xl" // Add shadow for depth
        />
          <Heading fontSize = {"5xl"}  color = {"#666"}>Authenticating with Planning Center</Heading>
          <Text fontSize={"xl"}>To use Flock Analytics you must first authenticate with Planning Center.</Text>

          <OrderedList fontSize={"xl"} pb={"50px"}>
          <ListItem>Please click the <b>People</b> button on this page to go to Planning Center. Once there, log in to your Planning Center account. You'll then see a message asking if Flock Analytics can access your People data. Click "Allow" to continue. After that, you'll come directed back to this page automatically.</ListItem>
          <ListItem>To access Groups, simply click the <b>Groups</b> button on this page. You might need to log in again. After logging in, you'll be asked to authorize Flock Analytics to access People. Just click "Allow" to grant permission, and you'll be redirected back to this page.</ListItem>
          <ListItem>After completing steps 1 and 2, proceed by clicking the "Submit".</ListItem>
          </OrderedList>
        </Stack>
        {isLoading ? (
          <Card p = {"25"}
            as="div"
            css={{
              position: "-webkit-sticky", /* Safari */
              position: "sticky",
              top: 200,
            }}
            alignItems = {"center"}
            justifyContent = {'center'}
          > 
            <Text fontSize={'xl'}>Hold on while we connect!</Text>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='brand.500'
              size='xl'
            />
          </Card>
        ) : (
          <Card p = {"25"}
            as="div"
            css={{
              position: "-webkit-sticky", /* Safari */
              position: "sticky",
              top: 100,
              minWidth: "350px",
            }}
          >
            <Text mb={"3px"}>Click Here to connect to People</Text>
            <Skeleton isLoaded={isMounted}>
                <HStack mb = {"15px"}>
                  <Icon as={MdOutlineCheckCircleOutline} boxSize={6} color={people ? 'green' : 'rgba(0,0,0,0)'}/>
                  {peopleLoading ? <Spinner /> : (
                  <img
                    src="/people.png"
                    alt="People"
                    style={{
                      height: '80px',
                      border: '1px solid black',
                      padding: '8px',
                      borderRadius: '15px',
                      transition: 'transform 0.3s ease, border-color 0.3s ease, cursor 0.3s ease',
                      transform: this.state.isHovered1 ? 'translateY(-5px)' : 'translateY(0)',
                      cursor: this.state.isHovered1 ? 'pointer' : 'default'
                    }}
                    onMouseOver={() => { this.setState({ isHovered1: true }); }}
                    onMouseOut={() => { this.setState({ isHovered1: false }); }}
                    onClick={this.people}
                  /> )
                  }
                </HStack>
              </Skeleton>
                <Text mb = {"3px"}>Click Here to connect to Groups</Text>
                <Skeleton isLoaded={isMounted}>
                <HStack mb = {"15px"}>
                  <Icon as={groups ? MdOutlineCheckCircleOutline : MdOutlineRadioButtonUnchecked} color={groups ? 'green' : 'rgba(0,0,0,0)'} boxSize={6}/>
                  {groupsLoading ? <Spinner /> : (
                  <img
                    src="/groups.png"
                    alt="groups"
                    style={{
                      height: '75px',
                      border: '1px solid black',
                      padding: '8px',
                      borderRadius: '15px',
                      transition: 'transform 0.3s ease, border-color 0.3s ease, cursor 0.3s ease',
                      transform: this.state.isHovered2 ? 'translateY(-5px)' : 'translateY(0)',
                      cursor: this.state.isHovered2 ? 'pointer' : 'default'
                    }}
                    onMouseOver={() => { this.setState({ isHovered2: true }); }}
                    onMouseOut={() => { this.setState({ isHovered2: false }); }}
                    onClick={this.groups}
                  /> )
                  }
                </HStack>
              </Skeleton>
              <Button onClick={this.submit} colorScheme={"brand"} isDisabled={!groups || !people}>Submit</Button>
          </Card>
        )}
      </HStack>
      </Box>
      </>
    );
  }
}