import React, { Component } from 'react';
import MapSelector from './mapSelector'
import CcbRegistration from './ccb_registration';
import DashboardSelector from './dashboardSelector'
import { Center, Heading } from '@chakra-ui/react';
import { Navigate } from "react-router-dom";

export default class Registration extends Component {
  constructor(props){
    super(props);
    this.state = {
      api_type : "",
      ccb_data : {},
      pw_data : {},
      loading : false,
      isMounted : false,
      isAuthenticated : false
    };
  }

  async componentDidMount(){
    var authenticated = await this.authenticate();
    
    if(authenticated){
      this.setState({isAuthenticated : true, isMounted : true});
    } else {
      this.setState({isAuthenticated : false, isMounted : true})
    }
  }

  authenticate = async ()=>{
  var x = await fetch(`/api/auth/authentication`, {method: "POST", credentials: "include",})
  .then((res) =>{
      if(res.status == 200) {
          return true;
      }
      return false;
  });
  return x;
  }

  render(){
    const { api_type, isAuthenticated, isMounted } = this.state;

    if(!isMounted) return <></>;
    if(!isAuthenticated && isMounted) return <Navigate to="/login" />;
    return(
      <>
        <Center pb = "50px">
        <Heading fontSize = {{sm: "4xl", md : "5xl", lg : "6xl" }} px = {"25px"} color = "#666">Select a Ministry Insight Dashboard</Heading>
        <DashboardSelector />
        </Center>
        <MapSelector />
      </>
    );
  }
}