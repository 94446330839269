import React, { Component } from 'react';
import Counter from './counter'
import { Center, HStack, Button, Card, List, ListIcon, ListItem, Stack, Text, Heading, Spacer, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody} from '@chakra-ui/react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BiInfoCircle } from "react-icons/bi";
import { Navigate } from 'react-router-dom';

export default class Registration extends Component {
  constructor(props){
    super(props);
    this.state = {
      isMounted : false,
      isAuthenticated : false,

      monthly : true,
      price : 0,
      individuals : 0,
    };
  }

  async componentDidMount(){
    let res = await fetch('/api/church/pricing',{
      credentials: "include", 
      headers: {
        "Content-Type": "application/json",
      },
    })
    if(!res.ok){
      this.setState({
        isMounted : true,
        isAuthenticated : true
      });
    } else {
      res = await res.json();
      this.setState({
        isMounted : true,
        isAuthenticated : true,
        individuals : res.individuals,
        price : res.price
      });
    }
  }

  createCheckout = async (e) =>{
    e.preventDefault();
    try{
    let res = await fetch('/api/church/checkout',
      {
        method : "POST",
        credentials: "include", 
        headers: {
          "Content-Type": "application/json",
        },
        body : JSON.stringify({
          monthly : this.state.monthly
        })
      }
    );
    if(res.ok){
      res = await res.json();
      window.location.href = res.url;
    }
    } catch(err){

    }
  }

  render(){
    const { monthly, isAuthenticated, isMounted, individuals, price } = this.state;
    return(
      <>
      {isMounted ? (
        isAuthenticated ? (
        <Center px = {"8vw"}>
          <Center>
            <Stack align={"center"}>
            <Heading>Your Church has...</Heading>
              <Counter label = "Individuals" number={individuals.toString()} duration ={.5}/>
            </Stack>
          </Center>
          <Spacer />
          <Stack align={"center"} >
          <HStack spacing={0}>
            <Button 
              isActive={monthly} 
              colorScheme={"brand"}
              borderRadius="9999px 0 0 9999px"
              onClick = {() => this.setState({monthly : !monthly})}
            >
              Monthly
            </Button>
            <Button 
              isActive={!monthly}
              colorScheme={"brand"} 
              borderRadius="0 9999px 9999px 0"
              onClick = {() => this.setState({monthly : !monthly})}
            >
              Yearly
            </Button>
          </HStack>
            <Card width = "35vw" align={"center"} pb = {"15px"}>
            <HStack flexWrap={"wrap"} justify="center">
              {monthly ? (
                <>
                  <Heading>${parseFloat(price).toFixed(2)}</Heading>
                  <Text>Monthly</Text>
                </>
              ) : (
                <>
                <Stack>
                  <Text>Free 1-week trial then...</Text>
                  <Heading size = {"md"} color = "red"><s>${(price*12).toFixed(2)}</s></Heading>
                  <HStack>
                    <Heading>${(price*12*.9).toFixed(2)}</Heading>
                    <Text>Yearly</Text>
                  </HStack>
                  </Stack>
                </>
              )
              
              }
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label="Info"
                    icon={<BiInfoCircle />}
                    variant="ghost"
                    size="md"
                    _hover={{ color: 'brand.500' }}
                  />
                </PopoverTrigger>
                <PopoverContent maxW="xl">  
                  <PopoverBody>
                    <b>Pricing</b><br />
                    $.10 <b>x</b> per active accounts 1-1000<br/>
                    $.05 <b>x</b> per active accounts 1001-3000<br/>
                    $.02½ <b>x</b> per active accounts 3001+<br/>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
            <List spacing={3} px = {"15px"}>
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Unlimited user accounts
              </ListItem>
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Cloud servers for faster rendering
              </ListItem>
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Full access to prebuilt Ministry Insight Dashboards
              </ListItem>
              {/* You can also use custom icons from react-icons */}
              <ListItem>
                <ListIcon as={BsCheckCircleFill} color='brand.500' />
                Track historical data to see growth over time
              </ListItem>
            </List>
            <Button p = "30" onClick={this.createCheckout}><Heading fontSize={"2xl"} >Subscribe</Heading></Button>
            </Card>
            </Stack>
         </Center>
        ) : (
        <Navigate to = '/register'/>
        )
      ) : (
        <h1>loading</h1>
      )}
      </>
    );
  }
}