import {
  HStack,
  PinInput, 
  PinInputField,
  Center
} from '@chakra-ui/react';
import React, { Component } from 'react';


const API_URL = process.env.REACT_APP_API_URL;

export default class SSOLogin extends Component {
  constructor(props){
      super(props);
      this.state = {
        error: false,
        loading : false
      };
  }

  ssoLogin = async(code) =>{
    this.setState({loading : true}, () => {
      //console.log(`${API_URL}/api/auth/signin`);
      fetch(`/api/auth/sso-check`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify({
          "code" : code,
          "email" : this.props.email
        })
      })
      .then((res) => {
          if(res.status == 200) {
            this.props.setCode(code)
          } else {
            alert("help");
          }
      });
    });
  }
  
  render(){
    return (       
      <>
        <Center>
          <HStack justify={'center'}>
            <PinInput otp size={'lg'} onComplete={this.ssoLogin} autoFocus={true}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        </Center>
      </>
    );
  }
};
