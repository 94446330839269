import React, {Component} from "react";
import { Flex, Container, Heading, Text, Input, Box, Spacer, Button, HStack, Stack } from "@chakra-ui/react";
import NavBar from "../navBar";
import { Navigate } from "react-router-dom";
import AccountDetails from "../components/accountDetails";
import ManageAccounts from "../components/manageAccounts";
import Auth from "../components/auth";


export default class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      accountDetails : true,
      manageAccounts : false,
      paymentDetails : false,
      isMounted : false,
      isAuthenticated : false
    };
  }
  async componentDidMount(){
    var authenticated = await this.authenticate();
    
    if(authenticated){
      fetch(`/api/auth/account`, {
        method : "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
      }).then((res) => res.json())
      .then((res) => {
        this.setState({
          firstName : res.first_name,
          lastName : res.last_name,
          email : res.email,
          isMounted : true,
          isAuthenticated : true
        })
      });
    } else {
      this.setState({
        isMounted: true,
        isAuthenticated: false,
      });
    }
  }

  render(){
    const {isAuthenticated, isMounted, accountDetails, manageAccounts} = this.state;

    if(!isAuthenticated && isMounted) return <Navigate to="/login" />;
    if(!isMounted) return <></>;
    return(
      <Auth blockedStatus = {{'/login' : [0], '/registration' : [406], '/review-registration': [407]}}>
        <>
          <NavBar />
          
          <HStack align= "top" width = "100%">
            {}
            <Box 
            bg = "brand.800"
            width = {"250px"}>
              <Stack spacing={5} pt = "10px" align={"center"} minH = {"100vh"}>
                <Button 
                  variant = "outline" 
                  color = {"brand.800"} 
                  width = {"90%"} 
                  onClick = {() => this.setState({accountDetails : true, manageAccounts : false, paymentDetails: false})}
                >
                  Account Details
                </Button>
                <Button 
                  variant = "outline" 
                  color = {"brand.800"}  
                  width = {"90%"}
                  onClick = {() => this.setState({accountDetails : false, manageAccounts : true, paymentDetails: false})}
                >
                  Manage Accounts
                </Button>
              </Stack>
            </Box>

            <Box  w = "100%" pl = "100px" pb = "200px">
              {(accountDetails) ? <AccountDetails /> : ""}
              {(manageAccounts) ? <ManageAccounts /> : ""}
            </Box>
          </HStack>
        </>
      </Auth>
    );
  }
}