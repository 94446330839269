import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Image

} from '@chakra-ui/react';
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import SSO from './sso';

const API_URL = process.env.REACT_APP_API_URL;

export default class LoginPage extends Component {
  constructor(props){
      super(props);
      this.state = {
        isLoggedIn : false,
        error : false,
        loading : false
      };
  }
  
  login = (e)=>{
    e.preventDefault();
    this.setState({loading : true, error : false}, () => {
      //console.log(`${API_URL}/api/auth/signin`);
      fetch(`/api/auth/signin`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify({
          "email": e.target.email.value,
          "password": e.target.password.value
        })
      })
      .then((res) => {
          if(res.status == 200) {
            this.setState({isLoggedIn : true});
          } else {
            this.setState({error : true, loading : false})
          }
      })
    });
  }

  clearError = () => {
    this.setState({error : false});
  }

  render(){
    const {loading, error, isLoggedIn} = this.state;
    if(this.state.isLoggedIn == true) return <Navigate to="/home" />;
    return (
      <>
        <form onSubmit={this.login}>  
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input type="email" onChange={this.clearError}/>
        </FormControl>
        <FormControl id="password">
          <FormLabel pt={"10px"}>Password</FormLabel>
          <Input type="password" onChange={this.clearError}/>
        </FormControl>
        <Stack spacing={10} mt={"20px"}>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            align={'start'}
            justify={'space-between'}>
            <Checkbox>Remember me</Checkbox>
            <Link color={'blue.400'} href = "/create-profile">Create Account</Link>
            <Link color={'blue.400'} onClick={() => this.props.setPage("sso")} >Forgot password?</Link>
          </Stack>
          <Button
            colorScheme="brand"
            type="submit"
            isLoading = {loading}>
            Sign in
          </Button>
        </Stack>
        </form>
        {error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Login Failed</AlertTitle>
          <AlertDescription>You entered an incorrect email or password</AlertDescription>
        </Alert>
        ) : null }
    </>
    );
  }
};