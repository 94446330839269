export const messages = {
  200: "",
  100 : "Dashboard is currently refreshing",
  500 : "Refresh failed: Please try again later",
  401 : "Refresh failed: Unauthorized please check the API settings are configure correctly"

};
export const messagesBlocked = {
  100 : "Dashboard is currently refreshing, page will automatically update upon completion",
  500 : "Dashboard Unavailable: Please try again later",
  401 : "Dashboard Unavailable: Unauthorized please check the API settings are configure correctly",
  402 : "Dashboard Unavailable: Subscription overdue",
  405 : "Dashboard Unavailable: Account is inactive",
  406 : "Dashboard Unavailable: Please Complete registration",  // Registration Part 1. User is created but credentials are not stored
  407 : "Dashboard Unavailable: Please Complete registration",  // Registration Part 2. Credentials are stored but purchase has not been completed
}